import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import React, { useContext } from 'react';

import { ContentWithHeader } from 'components';

import Menu from './navigation';
import Invoice from './invoices';
import Dashboard from './dashboard';
import History from './history';
import CancelSubscription from './cancel';

export default function Home() {
  const theme = useContext(ThemeContext);
  const { path } = useRouteMatch();
  return (
    <ContentWithHeader navigation={<Menu />}>
      <Switch>
        <Route
          path={`${path}/cancel-subscription`}
          render={(props) => (
            <CancelSubscription
              {...props}
              theme={theme}
            />
          )}
        />
        <Route
          path={`${path}/invoices`}
          render={(props) => (
            <Invoice
              {...props}
              theme={theme}
            />
          )}
        />
        <Route
          path={`${path}/history`}
          render={(props) => (
            <History
              {...props}
              theme={theme}
            />
          )}
        />
        <Route
          path={path}
          render={(props) => (
            <Dashboard
              {...props}
              theme={theme}
            />
          )}
        />
      </Switch>
      <div id="actions-portal" />
    </ContentWithHeader>
  );
}
