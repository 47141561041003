import React from 'react';

import BarcodeImage from 'assets/images/barcode.png';
import { ThemePropType } from 'assets/styles/theme';
import {
  Text, TextBold, ActionPortal,
  DefaultActionButtons,
} from 'components';

import { InvoiceWrapper } from './style';

export default function PaymentInvoice({
  theme, isValid, handleSubmit, onCancel, onSubmit,
}) {
  return (
    <InvoiceWrapper>
      <Text
        display="block"
        color={theme.colors.text.default}
        fontSize="1.5em"
        fontWeight="400"
        whiteSpace="normal"
        margin="0 auto 2em"
        style={{
          maxWidth: '340px',
        }}
      >
        Clique em
        <TextBold
          display="inline-block"
          style={{ margin: '0 7px' }}
        >
          &quot;Finalizar Contrata&ccedil;&atilde;o&quot;
        </TextBold>
        para gerar seu Boleto.
      </Text>

      <img
        src={BarcodeImage}
        alt="Boleto"
      />

      <ActionPortal>
        <DefaultActionButtons
          theme={theme}
          isValid={isValid}
          backButtonStyle={{
            iconPosition: 'left',
            border: 'none',
            customBackgroundColor: 'transparent',
            customIconColor: theme.colors.text.label,
            customTextColor: theme.colors.text.label,
            title: 'Passo Anterior',
          }}
          nextButtonStyle={{
            customBackgroundColor: isValid ? theme.colors.status.success : theme.colors.primary,
            customIconColor: theme.colors.text.white,
            customTextColor: theme.colors.text.white,
            title: 'Finalizar contratação',
          }}
          onCancel={onCancel}
          onSubmit={() => handleSubmit(onSubmit)()}
        />
      </ActionPortal>
    </InvoiceWrapper>
  );
}

PaymentInvoice.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: ThemePropType.isRequired,
};
