import React from 'react';
import PropTypes from 'prop-types';

import { DefaultFooter } from './style';

export default function Footer({ children, ...props }) {
  return <DefaultFooter {...props}>{children}</DefaultFooter>;
}

Footer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};
