import React, { useState } from 'react';
import { node } from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  ActionPortal,
  Button,
  ButtonsHolder,
  ButtonWithIcon,
  Icon,
  Modal,
  Paragraph,
  Step,
  Text,
  TextBold,
} from 'components';

import { ThemePropType } from 'assets/styles/theme';

import { StepPropTypes } from '../../../constants/propTypes';

import { ChecklistItem } from './style';

const ConfirmationChecklistItem = ({ theme, children }) => (
  <ChecklistItem>
    <Icon
      icon="timesCircle"
      fontSize="12px"
      divProps={{ color: theme.colors.status.danger, marginRight: '10px' }}
    />
    <Text fontSize="14px">{children}</Text>
  </ChecklistItem>
);

export default function App(props) {
  const { theme, onSubmit, onCancel } = props;
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
  const history = useHistory();

  return (
    <Step
      title="Você está prestes a cancelar sua assinatura"
      subtitle="Assim que sua assinatura expirar na próxima data da fatura, você perderá o acesso ao Contele Rastreador."
    >
      <ul style={{ margin: '32px 0' }}>
        <ConfirmationChecklistItem theme={theme}>
          Seus Motoristas não poderão mais usar o aplicativo Contele Driver.
        </ConfirmationChecklistItem>
        <ConfirmationChecklistItem theme={theme}>
          Você não terá mais acesso aos relatórios gerados durante sua assinatura.
        </ConfirmationChecklistItem>
        <ConfirmationChecklistItem theme={theme}>
          Você perderá todas as suas configurações dentro do Contele Rastreador.
        </ConfirmationChecklistItem>
        <ConfirmationChecklistItem theme={theme}>
          Você não terá mais os
          {' '}
          <TextBold
            display="inline"
            onClick={() => setShowMoreInfoModal(true)}
            style={{ cursor: 'pointer' }}
            color={theme.colors.primary}
          >
            30 Benefícios do Contele Rastreador.
          </TextBold>
        </ConfirmationChecklistItem>
      </ul>
      <div style={{ display: 'flex' }}>
        <Button
          borderColor={theme.colors.status.danger}
          textConfig={{
            color: theme.colors.status.danger,
            fontSize: '13px',
            textAlign: 'center',
          }}
          backgroundColor="transparent"
          width="auto"
          padding="10px"
          title="Cancelar Meu Contrato"
          style={{ marginRight: '24px' }}
          onClick={() => onSubmit()}
        />
        <Button
          textConfig={{
            color: 'white',
            fontSize: '13px',
            textAlign: 'center',
          }}
          backgroundColor={theme.colors.primary}
          width="auto"
          padding="10px"
          title="Desistir do Cancelamento"
          onClick={() => history.push('/member')}
        />
      </div>

      <Modal
        centered
        isOpen={showMoreInfoModal}
        setOpen={setShowMoreInfoModal}
        style={{ maxWidth: '550px' }}
        header={<TextBold fontSize="18px">Item 2.2 do Contrato</TextBold>}
        headerProps={{
          close: (
            <button
              type="button"
              className="close"
              style={{ opacity: 1, outline: 'none', color: theme.colors.status.danger }}
              onClick={() => setShowMoreInfoModal(false)}
            >
              &times;
            </button>
          ),
        }}
      >
        <Paragraph
          fontSize="14px"
          margin="0 auto 16px"
          color={theme.colors.text.paragraph}
        >
          *Efetivado o cancelamento do contrato, a CONTRATANTE se responsabilizará em desinstalar os
          equipamentos e devolvê-los a CONTRATADA num prazo máximo de 15 dias. Caso o equipamento
          não seja devolvido neste prazo, será cobrado um valor de R$ 500,00 por equipamento locado.
        </Paragraph>
        <Paragraph
          margin="0 auto"
          fontSize="14px"
          color={theme.colors.text.paragraph}
        >
          *2.2. Na existência de quaisquer danos materiais, defeito no equipamento locado decorrente
          de mau uso ou má conservação ou não devolução de algum item do Kit locado pela CONTRATADA,
          a CONTRATANTE pagará a quantia de, R$ 400,00 (quatrocentos reais) do MÓDULO, R$ 70,00
          (setenta reais) da ANTENA GPS, R$ 30,00 (trinta reais) da ANTENA GSM, R$ 20,00 (vinte
          reais) da CHAVE IBUTTON e R$ 140,00 (cento e quarenta reais) do LEITOR IBUTTON .
        </Paragraph>
      </Modal>
      <ActionPortal>
        <ButtonsHolder justifyContent="flex-start">
          <ButtonWithIcon
            icon="arrowLeft"
            iconPosition="left"
            border="none"
            customBackgroundColor="transparent"
            customIconColor={theme.colors.text.label}
            customTextColor={theme.colors.text.label}
            title="Passo Anterior"
            onClick={() => onCancel()}
          />
        </ButtonsHolder>
      </ActionPortal>
    </Step>
  );
}

App.propTypes = StepPropTypes;
ConfirmationChecklistItem.propTypes = {
  theme: ThemePropType.isRequired,
  children: node.isRequired,
};
