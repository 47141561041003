import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  ActionPortal,
  Col,
  DefaultActionButtons,
  Divider,
  Form,
  FormGroup,
  FormLabel,
  Input,
  Row,
  Step,
  Text,
  TextBold,
} from 'components';

import { trackers } from 'test/fixtures/trackers';

import { StepPropTypes } from '../../constants/propTypes';

import TrackerTable from './trackerTable';

const formValidationSchema = yup
  .object()
  .shape({
    vehicleCount: yup.number().integer().moreThan(-1).lessThan(100),
    lightweightVehiclesCount: yup.number().integer().moreThan(-1),
    motorbikeCount: yup.number().integer().moreThan(-1),
    heavyVehiclesCount: yup.number().integer().moreThan(-1),
    machineCount: yup.number().integer().moreThan(-1),
  })
  .test('sum', (value) => {
    if (!value) return true;
    const {
      vehicleCount,
      lightweightVehiclesCount,
      motorbikeCount,
      heavyVehiclesCount,
      machineCount,
    } = value;

    const vehicleCountByType = [
      lightweightVehiclesCount,
      motorbikeCount,
      heavyVehiclesCount,
      machineCount,
    ].reduce((sum, cur) => sum + parseInt(cur || 0, 10), 0);

    return parseFloat(vehicleCount || 0) === vehicleCountByType;
  });

export default function TrackerManager(props) {
  const {
    theme, onSubmit, onCancel, initialValues: defaultValues,
  } = props;

  const {
    errors, formState, handleSubmit, watch,
  } = useForm({
    mode: 'all',
    defaultValues: defaultValues || {
      vehicleCount: 0,
      lightweightVehiclesCount: 0,
      motorbikeCount: 0,
      heavyVehiclesCount: 0,
      machineCount: 0,
    },
    resolver: yupResolver(formValidationSchema),
  });

  const vehicleCount = watch('vehicleCount');
  const { isValid } = formState;

  return (
    <Step title="Rastreadores">
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingBottom: '0' }}
      >
        <Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="vehicleCount">
                Você contratou
                {' '}
                {vehicleCount}
                , digite abaixo quantos deseja reduzir:
              </FormLabel>
              <Input
                type="text"
                name="vehicleCount"
                id="vehicleCount"
                placeholder="Digite a quantidade de veículos"
                maxLength="3"
                pattern="[0-9]*"
                errors={errors}
                style={{ width: '50%' }}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <Divider margin="16px 0" />
      <Text
        fontSize="18px"
        margin="0 0 16px 0"
      >
        Selecione abaixo
        {' '}
        <TextBold display="inline">2 veículos</TextBold>
        {' '}
        que deseja desvincular da
        sua conta.
      </Text>
      <Text
        fontSize="12px"
        margin="0 0 16px 0"
      >
        <TextBold
          display="inline"
          color={theme.colors.status.danger}
        >
          Atenção:
        </TextBold>
        {' '}
        Você não terá mais acesso aos dados desse veículo.
      </Text>
      <TrackerTable
        theme={theme}
        trackers={trackers}
        onChange={(values) => {
          // eslint-disable-next-line no-console
          console.log(
            'values',
            Object.keys(values).map((index) => trackers[index]),
          );
        }}
      />
      <ActionPortal>
        <DefaultActionButtons
          theme={theme}
          isValid={isValid}
          backButtonStyle={{
            iconPosition: 'left',
            border: 'none',
            customBackgroundColor: 'transparent',
            customIconColor: theme.colors.text.label,
            customTextColor: theme.colors.text.label,
            title: 'Passo Anterior',
          }}
          nextButtonStyle={{
            customBackgroundColor: theme.colors.primary,
            customIconColor: theme.colors.text.white,
            customTextColor: theme.colors.text.white,
            title: 'Próximo Passo',
          }}
          onCancel={onCancel}
          onSubmit={() => handleSubmit(onSubmit)()}
        />
      </ActionPortal>
    </Step>
  );
}

TrackerManager.propTypes = StepPropTypes;
