import styled from 'styled-components';

import { StepContentWrapper } from 'components';

export const ContractWrapper = styled(StepContentWrapper)((props) => ({
  padding: '40px',

  [`@media${props.theme.devices.laptop}`]: {
    padding: '40px 16px',
  },
}));

export const ChecklistItem = styled.li(() => ({
  alignItems: 'center',
  display: 'flex',
  margin: '24px 0',
}));
