import {
  any, bool, object, shape, string,
} from 'prop-types';

export const StylingPropType = shape({
  fontFamily: string,
  margin: string,
  color: string,
  fontSize: string,
  lineHeight: string,
  fontWeight: string,
  cursor: string,
  whiteSpace: string,
  letterSpacing: string,
  display: string,
});

export const ControlledInputPropType = shape({
  control: object.isRequired,
  defaultValue: any,
  errors: object.isRequired,
  format: string,
  name: string.isRequired,
  options: object.isRequired,
  placeholder: string,
  rules: object,
});

export const ControlledSelectInputPropType = shape({
  control: object.isRequired,
  defaultValue: any,
  errors: object.isRequired,
  format: string,
  name: string.isRequired,
  options: object.isRequired,
  placeholder: string,
  rules: object,
  isClearable: bool,
  blurInputOnSelect: bool,
});
