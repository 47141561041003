/* eslint-disable sort-keys */
import styled from 'styled-components';
import { Alert as AlertComponent, FormText as FormHelpText, Label } from 'reactstrap';

const getLabelTextColor = ({
  theme: { colors: { text: { label: defaultLabelColor } = {} } = {} } = {},
  color: specificColor,
}) => specificColor || defaultLabelColor || 'black';

export const FormLabel = styled(Label)((props) => ({
  color: getLabelTextColor(props),
  marginBottom: '16px',
  fontSize: '16px',
}));

export const FormText = styled(FormHelpText)((props) => ({
  color: getLabelTextColor(props),
  fontSize: '10px',
}));

export const TableHeader = styled.th((props) => ({
  fontSize: '16px',
  color: '#444',
  fontWeight: 'bold',
  padding: '0 5px 10px !important',
  textAlign: props.align,
}));

export const TableColumn = styled.td((props) => ({
  fontSize: '16px',
  color: '#444',
  padding: '12px 5px 6px !important',
  verticalAlign: 'middle !important',
  textAlign: props.align,
}));

export const TableColumnChildren = styled.td((props) => ({
  fontSize: '12px',
  color: '#888',
  padding: '4px 5px !important',
  textAlign: props.align,
}));

export const Divider = styled.hr((props) => ({
  borderColor: props.color,
  borderTopWidth: props.width || '2px',
  margin: props.margin || '0 0 23px',
  width: '100%',

  [`@media${props.theme.devices.laptop}`]: {
    display: 'none',
  },
}));

export const Alert = styled(AlertComponent)((props) => ({
  backgroundColor: props.warning ? '#f2f2f2' : undefined,
  border: 0,
  borderRadius: '10px',
  display: 'flex',
  lineHeight: '1.4',
  marginTop: '24px',
  padding: '20px',
}));

export const PlayerWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  position: relative;

  &:before {
    display: block;
    padding-bottom: 56%;
    width: 100%;
    content: '';
  }
`;
