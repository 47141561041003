export const trackers = [
  {
    description: 'Ducato',
    licensePlate: 'AJA-8376',
    trackerId: '1234',
  },
  {
    description: 'Veículo Pesado',
    licensePlate: 'AJA-8377',
    trackerId: '4123',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8378',
    trackerId: '3412',
  },
  {
    description: 'Moto',
    licensePlate: 'AJA-8222',
    trackerId: '2341',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8376',
    trackerId: '1234',
  },
  {
    description: 'Veículo Pesado',
    licensePlate: 'AJA-8377',
    trackerId: '4123',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8378',
    trackerId: '3412',
  },
  {
    description: 'Moto',
    licensePlate: 'AJA-8222',
    trackerId: '2341',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8376',
    trackerId: '1234',
  },
  {
    description: 'Veículo Pesado',
    licensePlate: 'AJA-8377',
    trackerId: '4123',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8378',
    trackerId: '3412',
  },
  {
    description: 'Moto',
    licensePlate: 'AJA-8222',
    trackerId: '2341',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8376',
    trackerId: '1234',
  },
  {
    description: 'Veículo Pesado',
    licensePlate: 'AJA-8377',
    trackerId: '4123',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8378',
    trackerId: '3412',
  },
  {
    description: 'Moto',
    licensePlate: 'AJA-8222',
    trackerId: '2341',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8376',
    trackerId: '1234',
  },
  {
    description: 'Veículo Pesado',
    licensePlate: 'AJA-8377',
    trackerId: '4123',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8378',
    trackerId: '3412',
  },
  {
    description: 'Moto',
    licensePlate: 'AJA-8222',
    trackerId: '2341',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8376',
    trackerId: '1234',
  },
  {
    description: 'Veículo Pesado',
    licensePlate: 'AJA-8377',
    trackerId: '4123',
  },
  {
    description: 'Ducato',
    licensePlate: 'AJA-8378',
    trackerId: '3412',
  },
  {
    description: 'Moto',
    licensePlate: 'AJA-8222',
    trackerId: '2341',
  },
];
