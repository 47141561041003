import React, { useMemo } from 'react';
import { any, bool, string } from 'prop-types';
import StylePropType from 'react-style-proptype';
import { UncontrolledDropdown } from 'reactstrap';
import { useExpanded, useTable } from 'react-table';

import { ThemePropType } from 'assets/styles/theme';

import { Text, TextBold } from '../text';
import { IconButton } from '../buttons';

import {
  ContractTable,
  DashboardTableFooter,
  Divider,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  DropdownWrapper,
  TableColumn,
  TableColumnChildren,
  TableHeader,
  TablePricingSummary,
} from './style';

export default function Contract(props) {
  const {
    theme, withDiscount, contract, style, pricingFooterText,
  } = props;
  const { details } = contract;

  const {
    headerGroups, rows, getTableProps, getTableBodyProps, prepareRow,
  } = useTable(
    {
      columns: useMemo(
        () => [
          {
            Header: 'Quantidade',
            accessor: 'qty',
          },
          {
            Header: 'Descrição',
            accessor: 'group',
            id: 'group',
            /* eslint-disable react/prop-types */
            Cell: ({ cell, row }) => (
              <>
                <span {...row.getToggleRowExpandedProps()}>{cell.value}</span>
                {row.canExpand ? (
                  <IconButton
                    {...row.getToggleRowExpandedProps()}
                    icon={row.isExpanded ? 'chevronUp' : 'chevronDown'}
                    customBackgroundColor="transparent"
                    customIconColor={theme.colors.primary}
                    border="none"
                    outline="none"
                    display="inline-flex"
                    style={{ fontSize: '12px', outline: 'none' }}
                  />
                ) : null}
              </>
            ),
            /* eslint-enable react/prop-types */
          },
          {
            Header: 'Valor Unitário',
            accessor: 'unitValue',
          },
          {
            Header: 'Valor',
            accessor: 'totalValue',
          },
        ],
        [theme.colors.primary],
      ),
      data: useMemo(() => details, [details]),
    },
    useExpanded,
    details[0].link
      ? (hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'edit',
            Cell: ({ row }) => row.original.link(),
          },
          ...columns,
        ]);
      }
      : () => {},
  );

  return (
    <>
      <ContractTable
        borderless
        responsive
        style={style}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{ fontSize: '16px', fontWeight: 'bold', border: 'none' }}
            >
              {headerGroup.headers.map((column) => (
                <TableHeader {...column.getHeaderProps()}>{column.render('Header')}</TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows
            .filter(({ depth }) => depth === 0)
            .map((row) => {
              prepareRow(row);
              return (
                <>
                  <tr
                    {...row.getRowProps()}
                    style={{ fontSize: '14px', borderTop: '1px solid #E5E5E5' }}
                  >
                    {row.cells.map((cell) => (
                      <TableColumn {...cell.getCellProps()}>{cell.render('Cell')}</TableColumn>
                    ))}
                  </tr>
                  {row.isExpanded
                    ? row.subRows.map((childRow) => {
                      prepareRow(childRow);
                      return (
                        <tr>
                          {childRow.cells.map((childCell) => (
                            <TableColumnChildren {...childCell.getCellProps()}>
                              {childCell.render('Cell')}
                            </TableColumnChildren>
                          ))}
                        </tr>
                      );
                    })
                    : null}
                </>
              );
            })}
        </tbody>
      </ContractTable>

      <Divider
        width="1"
        margin="24px 0"
      />

      <DashboardTableFooter>
        {withDiscount && (
          <>
            <Text
              fontSize="16px"
              color={theme.colors.text.paragraph}
              margin="0 0 14px 0"
            >
              Valor: R$ 1.692/Mês
            </Text>
            <DropdownWrapper>
              <UncontrolledDropdown>
                <DropdownToggle
                  style={{ height: '30px', lineHeight: 1 }}
                  color="success"
                  caret
                >
                  Desconto: R$ 200/Mês
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <TextBold>Nome</TextBold>
                    <TextBold>De</TextBold>
                    <TextBold>Para</TextBold>
                  </DropdownItem>
                  <DropdownItem>
                    <span>Rastreadores</span>
                    <span>R$ 60,00</span>
                    <span>R$ 56,00</span>
                  </DropdownItem>
                  <DropdownItem>
                    <span>App Driver</span>
                    <span>R$ 5,00</span>
                    <span>R$ 0,00</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </DropdownWrapper>
          </>
        )}
        <TablePricingSummary fontSize="20px">
          <TextBold
            display="inline"
            fontSize="20px"
          >
            {withDiscount ? 'Total com desconto:' : pricingFooterText}
          </TextBold>
          {' '}
          R$ 1.492/Mês
        </TablePricingSummary>
      </DashboardTableFooter>
    </>
  );
}

Contract.defaultProps = {
  pricingFooterText: 'Total:',
  style: null,
  withDiscount: false,
};

Contract.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contract: any.isRequired,
  pricingFooterText: string,
  style: StylePropType,
  theme: ThemePropType.isRequired,
  withDiscount: bool,
};
