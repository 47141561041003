import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import {
  ActionPortal, Button, Contract, Row, Text, TextBold,
} from 'components';

import { ThemePropType } from 'assets/styles/theme';

export default function ActiveSubscription(props) {
  const { theme } = props;
  const history = useHistory();
  const { url } = useRouteMatch();
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text
          fontSize="24px"
          fontWeight="400"
          fontHeight="24px"
          whiteSpace="normal"
          theme={theme}
        >
          Seu Contrato
        </Text>
        <span style={{ color: '#999' }}>
          Pagamento:
          {' '}
          <span
            style={{
              marginLeft: '4px',
              border: `1px solid ${theme.colors.status.success}`,
              color: theme.colors.status.success,
              padding: '8px',
              fontSize: '10px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              borderRadius: '4px',
            }}
          >
            Em dia
          </span>
        </span>
      </div>
      <Row
        noGutters
        style={{
          border: `1px solid ${theme.colors.accent}`,
          backgroundColor: '#F5F5FF',
          margin: '24px 0',
          borderRadius: '0.5em',
          padding: '24px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '10px',
            border: '1px solid #908FC3',
          }}
        >
          <TextBold
            fontSize="40px"
            display="inline-flex"
            color={theme.colors.primary}
          >
            20
          </TextBold>
          <TextBold
            display="inline-flex"
            fontSize="16px"
            color={theme.colors.primary}
          >
            Rastreadores
          </TextBold>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '20px',
            justifyContent: 'space-evenly',
          }}
        >
          <Text
            fontSize="18px"
            lineHeight="20px"
            color={theme.colors.text.default}
          >
            Contrato Anual
          </Text>
          <Text
            color={theme.colors.text.default}
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            whiteSpace="normal"
            margin="0 0 1em 0"
          >
            R$ 1.692
            {' '}
            <Text
              display="inline"
              fontSize="14px"
              lineHeight="20px"
              color={theme.colors.text.paragraph}
            >
              {' '}
              / mês (valor sem desconto)
            </Text>
          </Text>
          <Text
            fontSize="14px"
            lineHeight="20px"
            color={theme.colors.text.paragraph}
          >
            Vencimento todo dia
            {' '}
            <TextBold
              display="inline"
              color={theme.colors.text.paragraph}
            >
              15
            </TextBold>
          </Text>
          <Text
            lineHeight="20px"
            color={theme.colors.text.paragraph}
          >
            Vigência do contrato até dia 05/02/2021
          </Text>
        </div>
      </Row>
      <Contract
        withDiscount
        theme={theme}
        contract={{
          details: [
            {
              qty: 20,
              group: 'Rastreadores',
              unitValue: 'R$ 60,00',
              totalValue: 'R$ 1.580/Mês',
              subRows: [
                {
                  qty: 10,
                  group: 'Veículos leves',
                  unitValue: '',
                  totalValue: '',
                },
                {
                  qty: 5,
                  group: 'Motos',
                  unitValue: '',
                  totalValue: '',
                },
                {
                  qty: 5,
                  group: 'Veículos pesados',
                  unitValue: '',
                  totalValue: '',
                },
                {
                  qty: 0,
                  group: 'Máquinas',
                  unitValue: '',
                  totalValue: '',
                },
              ],
            },
            {
              qty: 4,
              group: 'Bloqueios',
              unitValue: 'R$ 4,00',
              totalValue: 'R$ 12/Mês',
            },
            {
              qty: 20,
              group: 'App Contele Driver',
              unitValue: 'R$ 5,00',
              totalValue: 'R$ 100/Mês',
            },
          ],
        }}
      />
      <ActionPortal>
        <div style={{ display: 'flex', width: '100%', padding: '24px' }}>
          <Button
            onClick={() => history.push(`${url}/change-plan/reduce`)}
            borderColor={theme.colors.primary}
            textConfig={{
              color: theme.colors.primary,
              fontSize: '13px',
              textAlign: 'center',
            }}
            backgroundColor="transparent"
            width="auto"
            padding="10px"
            title="Reduzir Rastreadores"
          />
          <Button
            onClick={() => history.push(`${url}/cancel-subscription`)}
            border="none"
            backgroundColor="transparent"
            textConfig={{
              color: theme.colors.text.paragraph,
              fontSize: '13px',
              textAlign: 'center',
            }}
            width="auto"
            padding="10px"
            marginLeft="16px"
            title="Cancelar Contrato"
          />
          <Button
            onClick={() => history.push(`${url}/change-plan/increase`)}
            width="auto"
            customBackgroundColor={theme.colors.primary}
            customTextColor={theme.colors.text.white}
            title="Contratar mais Rastreadores"
            style={{
              marginLeft: 'auto',
              padding: '10px',
            }}
          />
        </div>
      </ActionPortal>
    </>
  );
}

ActiveSubscription.propTypes = {
  theme: ThemePropType.isRequired,
};
