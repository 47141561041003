import styled from 'styled-components';

export const CallToActionWrapper = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  margin: 'auto',
  maxWidth: '565px',
  textAlign: 'center',

  '> .col': {
    flexGrow: 0,
  },

  [`@media${props.theme.devices.laptop}`]: {
    width: '100%',
  },
}));
