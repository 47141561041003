import styled from 'styled-components';

import { Table } from '../../../components';

export const ActionCellEditor = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-around',

  '> button': {
    height: '24px',
    width: '24px',
  },
}));

export const TableHeader = styled.th(() => ({
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '0 5px 10px !important',
}));

export const TableColumn = styled.td(() => ({
  fontSize: '14px',
  verticalAlign: 'middle !important',
}));

const getRowBackroundColor = (props) => {
  const { overdue, pending, awaitingConfirmation } = props;
  if (overdue) return '#FFEBE5';
  if (pending) return '#FFFBE5';
  if (awaitingConfirmation) return '#E5F1FF';
  return 'white';
};

const getRowTextStyle = ({
  overdue, pending, awaitingConfirmation, paidLate,
}) => {
  let fontWeight = '400';
  let color = '#444';

  if (overdue || pending || awaitingConfirmation || paidLate) {
    fontWeight = '700';
  }

  if (overdue) {
    color = '#E65845';
  }

  return {
    fontWeight,
    color,
  };
};

export const TableRow = styled.tr((props) => ({
  borderTop: 'none !important',
  padding: '12px 14px',
  backgroundColor: getRowBackroundColor(props),
  ...getRowTextStyle(props),
}));

export const InvoiceTable = styled(Table)(() => ({
  margin: 0,
  'thead th': {
    border: 'none',
  },

  'tbody td': {
    button: {
      maxHeight: '12px',
    },
  },

  'thead > tr > th:last-of-type': {
    width: '80px',
    textAlign: 'center',
  },
  'tbody > tr > td:last-of-type': {
    width: '80px',
    textAlign: 'center',
  },
}));
