import { shape, string } from 'prop-types';

export const text = {
  family: {
    default: '"Roboto", sans-serif',
  },
  sizes: {
    h1: '2em',
    h2: '1.5em',
    h3: '1.17em',
    h4: '1.12em',
    h5: '.83em',
    h6: '.75em',
    small: '.8em',
    normal: '1em',
    medium: '1.2em',
    large: '1.5em',
    extraLarge: '2em',
  },
};

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1232px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  // mobileS: `(max-width: ${sizes.mobileS})`,
  // mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  // tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  // laptopM: `(max-width: ${sizes.laptopM})`,
  // laptopL: `(max-width: ${sizes.laptopL})`,
  // desktop: `(max-width: ${sizes.desktop})`,
  // desktopL: `(max-width: ${sizes.desktop})`,
};

export const palette = {
  background: '#FFFFFF',
  primary: '#1A237A',
  secondary: '#6A6A84',
  darkerAccent: '#6b6b99',
  accent: '#6E73CA',
  lighterAccent: '#EFF1FF',
  border: '#E5E5E5',
  lighterBorder: '#CED4DA',
  status: {
    success: '#41BA40',
    danger: '#E65845',
    warning: '#FFB904',
  },
  text: {
    default: '#222222',
    label: '#666666',
    paragraph: '#666666',
    lighter: '#999999',
    white: '#ffffff',
  },
};

const button = {
  background: palette.primary,
  text: 'white',
  border: palette.border,
};

const input = {
  placeholder: '#868E96',
  border: palette.border,
  text: palette.text.default,
  background: 'white',
  boxShadowColor: '#007BFF',
};

export const colors = {
  ...palette,
  button: {
    default: button,
    disabled: {
      ...button,
      background: '#6A6A84',
    },
  },
  input: {
    default: input,
    checkbox: {
      ...input,
      background: '#2531A4',
    },
    radio: {
      ...input,
      background: '#2531A4',
    },
    switch: {
      ...input,
      background: '#2531A4',
    },
    select: {
      ...input,
      border: palette.lighterBorder,
      controlTextBackground: '#495057',
      option: {
        default: {
          background: 'white',
        },
        selected: {
          background: '#eeeeee',
        },
      },
    },
    disabled: {
      ...input,
      background: '#F2F2F2',
    },
    onSuccess: {
      ...input,
      border: palette.status.success,
    },
    onError: {
      ...input,
      border: palette.status.danger,
    },
  },
};

export const theme = {
  text,
  colors,
  devices,
};

export const ThemePropType = shape({
  colors: {
    background: string.isRequired,
    primary: string.isRequired,
    secondary: string.isRequired,
    darkerAccent: string.isRequired,
    accent: string.isRequired,
    lighterAccent: string.isRequired,
    border: string.isRequired,
    lighterBorder: string.isRequired,
    status: {
      danger: string.isRequired,
      error: string.isRequired,
      warning: string.isRequired,
    },
    text: {
      default: string.isRequired,
      label: string.isRequired,
      paragraph: string.isRequired,
      white: string.isRequired,
    },
    button: {
      default: {
        background: string.isRequired,
        text: string.isRequired,
        border: string.isRequired,
      },
      disabled: {
        background: string.isRequired,
        text: string.isRequired,
        border: string.isRequired,
      },
    },
    input: {
      default: {
        placeholder: string.isRequired,
        border: string.isRequired,
        text: string.isRequired,
        background: string.isRequired,
      },
      select: {
        placeholder: string.isRequired,
        border: string.isRequired,
        text: string.isRequired,
        background: string.isRequired,
      },
      radio: {
        placeholder: string.isRequired,
        border: string.isRequired,
        text: string.isRequired,
        background: string.isRequired,
      },
      checkbox: {
        placeholder: string.isRequired,
        border: string.isRequired,
        text: string.isRequired,
        background: string.isRequired,
      },
      switch: {
        placeholder: string.isRequired,
        border: string.isRequired,
        text: string.isRequired,
        background: string.isRequired,
      },
      disabled: {
        placeholder: string.isRequired,
        border: string.isRequired,
        text: string.isRequired,
        background: string.isRequired,
      },
      onError: {
        placeholder: string.isRequired,
        border: string.isRequired,
        text: string.isRequired,
        background: string.isRequired,
      },
      onSuccess: {
        placeholder: string.isRequired,
        border: string.isRequired,
        text: string.isRequired,
        background: string.isRequired,
      },
    },
  },
  text: {
    family: {
      default: string.isRequired,
    },
    sizes: {
      h1: string.isRequired,
      h2: string.isRequired,
      h3: string.isRequired,
      h4: string.isRequired,
      h5: string.isRequired,
      h6: string.isRequired,
      small: string.isRequired,
      normal: string.isRequired,
      medium: string.isRequired,
      large: string.isRequired,
      extraLarge: string.isRequired,
    },
  },
  devices: {
    mobileS: string.isRequired,
    mobileM: string.isRequired,
    mobileL: string.isRequired,
    tablet: string.isRequired,
    laptop: string.isRequired,
    laptopM: string.isRequired,
    laptopL: string.isRequired,
    desktop: string.isRequired,
    desktopL: string.isRequired,
  },
});
