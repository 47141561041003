import React from 'react';
import { usePagination, useRowSelect, useTable } from 'react-table';

import { ThemePropType } from 'assets/styles/theme';

import { invoices } from '../../../test/fixtures/invoices';
import { Container, IconButton, Text } from '../../../components';

import {
  ActionCellEditor, InvoiceTable, TableColumn, TableHeader, TableRow,
} from './style';

export default function Invoices(props) {
  const { theme } = props;

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,
  } = useTable({
    columns: [
      {
        Header: 'Data de Vencimento',
        accessor: 'expirationDate',
      },
      {
        Header: 'Valor',
        accessor: 'value',
      },
      {
        accessor: 'status',
      },
      {
        Header: 'Ações',
        id: 'actions',
        accessor: 'id',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <ActionCellEditor>
            <IconButton
              // eslint-disable-next-line no-console
              onClick={() => console.log(value)}
              icon="view"
              customBackgroundColor="transparent"
              customIconColor="#999999"
              border="1px solid #999999"
              style={{ outline: 'none' }}
            />
            {' '}
            <IconButton
              // eslint-disable-next-line no-console
              onClick={() => console.log(value)}
              icon="file"
              customBackgroundColor="transparent"
              customIconColor="#999999"
              border="1px solid #999999"
              style={{ outline: 'none' }}
            />
          </ActionCellEditor>
        ),
      },
    ],
    data: invoices,
    useRowSelect,
    usePagination,
  });

  return (
    <Container
      fluid
      style={{
        padding: '40px',
        margin: '0 auto',
      }}
    >
      <Text
        fontSize="22px"
        fontWeight="400"
        fontHeight="24px"
        whiteSpace="normal"
        margin="0 0 40px 0"
        theme={theme}
      >
        Seus Boletos e Faturas
      </Text>

      <InvoiceTable
        responsive
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{ fontSize: '16px', fontWeight: 'bold', border: 'none' }}
            >
              {headerGroup.headers.map((column) => (
                <TableHeader {...column.getHeaderProps()}>{column.render('Header')}</TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                {...invoices[index]}
              >
                {row.cells.map((cell) => (
                  <TableColumn {...cell.getCellProps()}>{cell.render('Cell')}</TableColumn>
                ))}
              </TableRow>
            );
          })}
        </tbody>
      </InvoiceTable>
    </Container>
  );
}

Invoices.propTypes = {
  theme: ThemePropType.isRequired,
};
