import styled from "styled-components";

export const DiscountCouponContainer = styled.div`
    display: ${props => props.show ? 'grid' : 'none'};
    align-items: center;
    justify-content: flex-end;
`;

export const DiscountCouponContent = styled.div`
    display: flex;
    align-items: center;
    min-width: 140px;
    max-width: 250px;
    margin-right: 8px;
`;