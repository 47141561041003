import React from 'react';
import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';

import {
  ActionPortal,
  Col,
  DefaultActionButtons,
  Form,
  FormGroup,
  Paragraph,
  Icon,
  PlayerWrapper,
  Step,
  Switch,
  Text,
} from 'components';

import { StepPropTypes } from '../../constants/propTypes';

import { AppChecklist } from './style';

export default function App(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { app } = {},
  } = props;
  const { formState, handleSubmit, register } = useForm({
    defaultValues: app,
    mode: 'all',
  });

  const { isValid } = formState;

  const [useAppSwitch, setUseAppSwitch] = React.useState(true)
  const changeUseAppSwitch = () => setUseAppSwitch(!useAppSwitch)

  return (
    <Step title="APP Contele Driver">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PlayerWrapper>
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=4Wbe8X-py44"
            width="100%"
            height="100%"
            style={{
              left: 0,
              position: 'absolute',
              top: 0,
            }}
            controls={true}
          />
        </PlayerWrapper>

        <Text
          color={theme.colors.text.label}
          fontSize="22px"
          fontHeight="30"
          whiteSpace="normal"
          margin="24px 0"
          style={{ textAlign: 'center' }}
        >
          <strong style={{ fontWeight: 'bold' }}>Otimize a gestão</strong>
          {' '}
          da frota com App Contele
          Driver.
        </Text>
        <AppChecklist theme={theme}>
          <Col
            md="4"
            xs="12"
          >
            <Icon
              icon="circleCheck"
              color="green"
              style={{ marginRight: '10px', fontSize: '20px' }}
            />
            <Text
              color={theme.colors.text.default}
              fontSize="16px"
            >
              Identificação do Motorista
            </Text>
          </Col>
          <Col
            md="4"
            xs="12"
          >
            <Icon
              icon="circleCheck"
              color="green"
              style={{ marginRight: '10px', fontSize: '20px' }}
            />
            <Text
              color={theme.colors.text.default}
              fontSize="16px"
            >
              Abastecimento
            </Text>
          </Col>
          <Col
            md="4"
            xs="12"
          >
            <Icon
              icon="circleCheck"
              color="green"
              style={{ marginRight: '10px', fontSize: '20px' }}
            />
            <Text
              color={theme.colors.text.default}
              fontSize="16px"
            >
              Checklist
            </Text>
          </Col>
        </AppChecklist>

        <hr />

        <Text
          color={theme.colors.text.default}
          fontSize="18px"
          fontHeight="20px"
          whiteSpace="normal"
          margin="0 0 1em 0"
        >
          Deseja ter acesso ao APP Contele Driver?
        </Text>

        <FormGroup>
          <div style={{display: 'flex'}}>
            <Switch
              inline
              id="hireApp"
              name="hireApp"
              label="Quero o app"
              innerRef={register}
              checked={useAppSwitch}
              onClick={changeUseAppSwitch}
            />
            <Paragraph
              fontSize="12px"
              lineHeight="20px"
              color={'#666666'}
            >
              (
            </Paragraph>
            <Paragraph
              fontSize="12px"
              lineHeight="20px"
              color={'#666666'}
              style={{textDecoration: 'line-through #666666'}}
            >
              R$5/Veículo
            </Paragraph>
            <pre style={{color: '#666666'}}> - </pre>
            <Paragraph
              fontSize="12px"
              lineHeight="20px"
              color={'#51BB41'}
            >
              Gratuito
            </Paragraph>
            <Paragraph
              fontSize="12px"
              lineHeight="20px"
              color={'#666666'}
            >
              )
            </Paragraph>
          </div>
        </FormGroup>

        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

App.propTypes = StepPropTypes;
