import React from 'react';
import { node } from 'prop-types';
import { useForm } from 'react-hook-form';

import {
  ActionPortal,
  DefaultActionButtons,
  Checkbox,
  Icon,
  Step,
  Text,
  TextBold,
} from 'components';

import { ThemePropType } from 'assets/styles/theme';

import { StepPropTypes } from '../../constants/propTypes';

import { ChecklistItem } from './style';

const ConfirmationChecklistItem = ({ theme, children }) => (
  <ChecklistItem>
    <Icon
      icon="timesCircle"
      fontSize="12px"
      divProps={{ color: theme.colors.status.danger, marginRight: '10px' }}
    />
    <Text fontSize="14px">{children}</Text>
  </ChecklistItem>
);

export default function App(props) {
  const { theme, onSubmit, onCancel } = props;
  const { formState, handleSubmit, register } = useForm({
    mode: 'all',
  });
  const { isValid } = formState;

  return (
    <Step
      title="Você está prestes a cancelar 2 rastreadores"
      subtitle="Assim que seus rastreadores chegarem a Central da Contele Rastreadores você não terá mais acesso a eles."
    >
      <ul style={{ margin: '32px 0' }}>
        <ConfirmationChecklistItem theme={theme}>
          Seus Motoristas não poderão mais usar o aplicativo Contele Driver
          {' '}
          <TextBold display="inline">dos veículos reduzidos.</TextBold>
        </ConfirmationChecklistItem>
        <ConfirmationChecklistItem theme={theme}>
          Você não terá mais acesso
          {' '}
          <TextBold display="inline">
            aos relatórios e históricos gerados dos veículos reduzidos.
          </TextBold>
        </ConfirmationChecklistItem>
        <ConfirmationChecklistItem theme={theme}>
          Você perderá todas as status
          {' '}
          <TextBold display="inline">configurações dos veículos reduzidos.</TextBold>
        </ConfirmationChecklistItem>
      </ul>

      <div style={{ marginTop: '40px' }}>
        <Checkbox
          inline
          id="accept"
          label="Sim. Estou ciente que vou perder tudo isso."
          name="accept"
          innerRef={register({ required: true })}
        />
      </div>
      <ActionPortal>
        <DefaultActionButtons
          theme={theme}
          isValid={isValid}
          backButtonStyle={{
            iconPosition: 'left',
            border: 'none',
            customBackgroundColor: 'transparent',
            customIconColor: theme.colors.text.label,
            customTextColor: theme.colors.text.label,
            title: 'Passo Anterior',
          }}
          nextButtonStyle={{
            customBackgroundColor: theme.colors.primary,
            customIconColor: theme.colors.text.white,
            customTextColor: theme.colors.text.white,
            title: 'Próximo Passo',
          }}
          onCancel={onCancel}
          onSubmit={() => handleSubmit(onSubmit)()}
        />
      </ActionPortal>
    </Step>
  );
}

App.propTypes = StepPropTypes;
ConfirmationChecklistItem.propTypes = {
  theme: ThemePropType.isRequired,
  children: node.isRequired,
};
