import { AUTH_TYPES } from 'constants/authTypesName';

import { validateAuthorizationToken } from 'services/api';

const checkValidTokenFromUrl = async ({ location }) => {
    const queryParams = location && location.search;

    try {
        const urlToken = !!queryParams && new URLSearchParams(queryParams);

        const cgvWebToken = urlToken && urlToken.get(AUTH_TYPES.URL_AUTH_PARAM_NAME);

        const tokenIsValid = urlToken && await validateAuthorizationToken({ authToken: cgvWebToken });

        if (tokenIsValid) {
            localStorage.setItem(AUTH_TYPES.STORAGE_AUTH_PARAM_NAME, JSON.stringify(cgvWebToken));
        };

        return tokenIsValid;
    } catch (error) {
        console.error(error);
    }

    return false;
};

const checkValidTokenFromStorage = async ({ storageName }) => {
    try {
        const storageToken = storageName && localStorage.getItem(storageName);

        const parsedStorageToken = !!storageToken && JSON.parse(storageToken);

        const tokenIsValid = parsedStorageToken && await validateAuthorizationToken({ authToken: parsedStorageToken });

        if (!tokenIsValid) {
            localStorage.removeItem(AUTH_TYPES.STORAGE_AUTH_PARAM_NAME);
        };

        return tokenIsValid;
    } catch (error) {
        console.error(error);
    }

    return false;
};

const checkValidTokenFromStorageOrUrl = async ({ location, storageName }) => {
    const urlTokenIsValid = await checkValidTokenFromUrl({ location });

    const storageTokenIsValid = await checkValidTokenFromStorage({ storageName });

    const hasValidToken = !!urlTokenIsValid || !!storageTokenIsValid;

    return hasValidToken;
};

export {
    checkValidTokenFromStorage,
    checkValidTokenFromUrl,
    checkValidTokenFromStorageOrUrl
};