import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PrivateRoute, WithoutOrgPrivateRoute, ProvideAuth } from './services/authProvider';

import {
  Subscription,
  Member,
  IncreasePlan, 
  ReducePlan,
  Authorization
} from './pages';

export default function Router() {
  return (
    <ProvideAuth>
      <Switch>
        <Route path="/auth"
          render={(props) => (
            <Authorization {...props} />
          )}
        />
        <PrivateRoute path="/member">
          <Member/>
        </PrivateRoute>
        <PrivateRoute path="/member/change-plan/increase">
          <IncreasePlan/>
        </PrivateRoute>
        <PrivateRoute path="/member/change-plan/reduce">
          <ReducePlan/>
        </PrivateRoute>
        <WithoutOrgPrivateRoute path="/subscription">
          <Subscription />
        </WithoutOrgPrivateRoute>
        <Route
          render={({ location }) => (
            <Redirect to={{ pathname: '/auth', search: location.search, state: { from: location } }} />
          )}
        />
      </Switch>
    </ProvideAuth>
  );
}

export { Router };