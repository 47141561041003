import { useForm } from 'react-hook-form';
import React from 'react';

import {
  ActionPortal,
  DefaultActionButtons,
  Step,
  Form,
  FormGroup,
  Radio,
} from '../../../components';
import { StepPropTypes } from '../../../constants/propTypes';

export default function WouldHireAgain(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { wouldHireAgain } = {},
  } = props;

  const {
    errors, formState, handleSubmit, register,
  } = useForm({
    defaultValues: wouldHireAgain,
    mode: 'all',
  });

  const { isValid } = formState;

  return (
    <Step title="Você está mudando para um outro sistema?">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup tag="fieldset">
          <div style={{ marginBottom: '14px' }}>
            <Radio
              type="radio"
              name="wouldHireAgain"
              value="5"
              id="YES"
              invalid={!!errors.wouldHireAgain}
              innerRef={register({ required: true })}
              label="Sim"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Radio
              type="radio"
              name="wouldHireAgain"
              value="10"
              id="NO"
              invalid={!!errors.wouldHireAgain}
              innerRef={register({ required: true })}
              label="Não"
            />
          </div>
        </FormGroup>
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              iconPosition: 'left',
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

WouldHireAgain.propTypes = StepPropTypes;
