import React from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import { MenuItemText, MenuList, MenuListItem } from './style';

export default function Menu(props) {
  const location = useLocation();
  const { url } = useRouteMatch();
  return (
    <nav {...props}>
      <MenuList {...props}>
        <MenuListItem {...props}>
          <MenuItemText
            {...props}
            active={location.pathname === '/member'}
          >
            <Link to={`${url}`}>Contrato</Link>
          </MenuItemText>
        </MenuListItem>
        <MenuListItem {...props}>
          <MenuItemText
            {...props}
            active={location.pathname === '/member/invoices'}
          >
            <Link to={`${url}/invoices`}>Boletos e faturas</Link>
          </MenuItemText>
        </MenuListItem>
        <MenuListItem {...props}>
          <MenuItemText
            {...props}
            active={location.pathname === '/member/history'}
          >
            <Link to={`${url}/history`}>Histórico de alterações</Link>
          </MenuItemText>
        </MenuListItem>
        {location.pathname === '/member/cancel-subscription' && (
          <MenuListItem {...props}>
            <MenuItemText
              {...props}
              active
            >
              <Link to={`${url}/cancel-subscription`}>Cancelando Assinatura</Link>
            </MenuItemText>
          </MenuListItem>
        )}
      </MenuList>
    </nav>
  );
}
