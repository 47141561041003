import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Alert, Button, Icon, Modal, Paragraph, Step, TextBold,
} from 'components';

import { StepPropTypes } from '../../../constants/propTypes';

function Completed(props) {
  const { theme, history } = props;
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);

  return (
    <Step title="Sua Assinatura está cancelada">
      <Alert
        warning
        theme={theme}
      >
        <Icon
          icon="exclamationTriangle"
          width="18px"
          height="18px"
          color={theme.colors.status.warning}
          style={{ marginRight: '8px' }}
        />
        <Paragraph>
          Aviso: Após a conclusão do cancelamento (entrega dos módulos), você terá 48 horas para
          exportar os relatórios e dados do sistema. Depois disso, os dados serão excluídos
          automaticamente. Veja como exportar nos artigos dos Relatórios.
          {' '}
          <TextBold
            display="inline"
            onClick={() => setShowMoreInfoModal(true)}
            style={{ cursor: 'pointer' }}
          >
            Saiba mais.
          </TextBold>
        </Paragraph>
      </Alert>
      <div style={{ display: 'flex' }}>
        <Button
          textConfig={{
            color: 'white',
            fontSize: '13px',
            textAlign: 'center',
          }}
          backgroundColor={theme.colors.primary}
          width="auto"
          padding="10px"
          title="Fazer nova Contratação"
          onClick={() => history.push('/subscription')}
          style={{ marginRight: '24px' }}
        />
        <Button
          borderColor={theme.colors.status.danger}
          textConfig={{
            color: theme.colors.status.danger,
            fontSize: '13px',
            textAlign: 'center',
          }}
          backgroundColor="transparent"
          width="auto"
          padding="10px"
          title="Ir para Contrato"
          onClick={() => history.push('/member')}
        />
      </div>
      <Modal
        centered
        isOpen={showMoreInfoModal}
        setOpen={setShowMoreInfoModal}
        style={{ maxWidth: '550px' }}
        header={<TextBold fontSize="18px">Item 2.2 do Contrato</TextBold>}
        headerProps={{
          close: (
            <button
              type="button"
              className="close"
              style={{ opacity: 1, outline: 'none', color: theme.colors.status.danger }}
              onClick={() => setShowMoreInfoModal(false)}
            >
              &times;
            </button>
          ),
        }}
      >
        <Paragraph
          fontSize="14px"
          margin="0 auto 16px"
          color={theme.colors.text.paragraph}
        >
          *Efetivado o cancelamento do contrato, a CONTRATANTE se responsabilizará em desinstalar os
          equipamentos e devolvê-los a CONTRATADA num prazo máximo de 15 dias. Caso o equipamento
          não seja devolvido neste prazo, será cobrado um valor de R$ 500,00 por equipamento locado.
        </Paragraph>
        <Paragraph
          margin="0 auto"
          fontSize="14px"
          color={theme.colors.text.paragraph}
        >
          *2.2. Na existência de quaisquer danos materiais, defeito no equipamento locado decorrente
          de mau uso ou má conservação ou não devolução de algum item do Kit locado pela CONTRATADA,
          a CONTRATANTE pagará a quantia de, R$ 400,00 (quatrocentos reais) do MÓDULO, R$ 70,00
          (setenta reais) da ANTENA GPS, R$ 30,00 (trinta reais) da ANTENA GSM, R$ 20,00 (vinte
          reais) da CHAVE IBUTTON e R$ 140,00 (cento e quarenta reais) do LEITOR IBUTTON .
        </Paragraph>
      </Modal>
    </Step>
  );
}

Completed.propTypes = StepPropTypes;

export default withRouter(Completed);
