import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { UncontrolledDropdown } from 'reactstrap';

import {
  IconButton,
  Icon,
  Text, TextBold,
} from '../index';

import {
  DashboardTableFooter,
  DropdownToggle,
  DropdownWrapper,
  TablePricingSummary,
} from '../contract/style';

import {
  LoadingSpin,
} from 'components';

import {
  Table, Head, Td, SubTd,
} from './style';

export default function ContractTable({
  headers,
  body,
  theme,
  onClickInLineButton,
  loadingTotalValues,
  total,
  discountTotal,
  diffDiscount,
  couponInput,
  appliedCoupon
}) {
  const [showSubRow, setShowSubRow] = useState(-1);

  const alterSubRowToShow = (id) => setShowSubRow(showSubRow === id ? -1 : id);

  const formatToCurrency = (value) => value && Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });

  return (
    <>
      <Table>
        <thead>
          <tr>
            <Head />
            {headers.map((header) => (
              <Head>{header.name}</Head>
            ))}
          </tr>
        </thead>
        <tbody>
          {
            body?.map((data) => (
              <>
                <tr>
                  <Td>
                    <IconButton
                      icon="edit"
                      customBackgroundColor="transparent"
                      customIconColor={theme.colors.text.default}
                      border="none"
                      style={{ outline: 'none' }}
                      onClick={() => onClickInLineButton(data)}
                    />
                  </Td>
                  {headers.map((header) => (
                    <Td>
                      {header.key === 'description' && data.subRow ? (
                        <div>
                          {data[header.key]}
                          <Icon
                            icon={showSubRow === data.id ? 'chevronUp' : 'chevronDown'}
                            width="25px"
                            height="25px"
                            border="none"
                            fill="#1A237A"
                            style={{
                              height: '20px',
                              fontSize: '20px',
                              cursor: 'pointer',
                              border: 'none',
                              backgroundColor: 'transparent',
                              outline: 'none',
                            }}
                            onClick={() => alterSubRowToShow(data.id)}
                          />
                        </div>
                      ) : (header.key === 'unitary_value' && data['value'] === 'Gratuito' ?
                        <s>{data[header.key]}</s>
                        :
                        <>{data[header.key]}</>
                      )}
                    </Td>
                  ))}
                </tr>
                {Array.isArray(data.subRow) && data.subRow.length > 0 && showSubRow === data.id
                  && data.subRow.map((subRow) => (
                    <tr>
                      <SubTd />
                      {headers.map((header) => (
                        <SubTd>{subRow[header.key]}</SubTd>
                      ))}
                    </tr>
                  ))}
              </>
            ))
          }
        </tbody>
      </Table>

      <DashboardTableFooter>
        {diffDiscount > 0 && 
          <Text
            fontSize="16px"
            color={theme.colors.text.paragraph}
            margin="0 0 14px 0"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Valor sem desconto:
            {' '}
            {formatToCurrency(total)}
            /Mês
          </Text>
        }
        {couponInput}
        {diffDiscount > 0 &&
          <DropdownWrapper>
            <UncontrolledDropdown>
              <DropdownToggle
                style={{ height: '30px', lineHeight: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                color="success"
              >
                Desconto:
                {' '}
                {loadingTotalValues ?
                  <LoadingSpin
                    show={loadingTotalValues}
                    weight="5px"
                    size="20px"
                    backColor="#1a2454"
                    frontColor="transparent"
                  />
                  :
                  formatToCurrency(diffDiscount)
                }
                /Mês
              </DropdownToggle>
            </UncontrolledDropdown>
          </DropdownWrapper>
        }
        <TablePricingSummary fontSize="20px" style={{ display: 'flex', alignItems: 'center' }}>
          <TextBold
            display="inline"
            fontSize="20px"
          >
            Total {diffDiscount > 0 && "com desconto"}:
          </TextBold>
          &nbsp;
          {loadingTotalValues ?
            <LoadingSpin
              show={loadingTotalValues}
              weight="5px"
              size="20px"
              backColor="#1a2454"
              frontColor="transparent"
            />
            :
            formatToCurrency(discountTotal)
          }
          /Mês
        </TablePricingSummary>
      </DashboardTableFooter>

    </>
  );
}

ContractTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  body: PropTypes.arrayOf(PropTypes.object).isRequired,
};
