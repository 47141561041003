import { useForm } from 'react-hook-form';
import React, { useState } from 'react';

import {
  Divider,
  Form,
  FormGroup,
  LoadingSpin,
  Radio,
  Step,
} from 'components';

import { StepPropTypes } from '../../constants/propTypes';

import { PaymentContent, PaymentContentForm } from './style';
import PaymentCreditCard from './paymentCreditCard';
import PaymentInvoice from './paymentInvoice';

import { WEB_SYSTEM_URL } from 'constants/environments';

export default function Payment(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { payment } = {}, onConfirmSubscription
  } = props;
  const {
    formState, register, handleSubmit, watch, control, errors,
  } = useForm({
    mode: 'all',
    defaultValues: payment,
  });

  const [loadingCreateSubscription, setLoadingCreateSubscription] = useState(false);
  const [failCreateSubscription, setFailCreateSubscription] = useState(false);

  const { isValid } = formState;
  const paymentType = watch('paymentType');

  const submitPayment = async (creditCard) => {
    setLoadingCreateSubscription(true);

    try {
      await onConfirmSubscription({ 
        paymentMethod: paymentType, 
        creditCard: creditCard
      });

      setFailCreateSubscription(false);
      onSubmit();

      const pathAfterLogin = "/aproveitamento";
      const token = localStorage.getItem("auth_token");
      const queryParams = `pathAfterLogin=${pathAfterLogin}&token=${token}`;

      window.open(`${WEB_SYSTEM_URL}/login?${queryParams}`, "_self")
    } catch (err) {
      console.error(err)
      setFailCreateSubscription(true);
    } finally {
      setLoadingCreateSubscription(false);
    };
  };

  const handleCreditCardInfo = (creditCard) => {
    handleSubmit(() => submitPayment(creditCard))();
  };

  const handleResetError = () => {
    setFailCreateSubscription(false);
  };

  return (
    <Step
      title="Pagamento"
      subtitle="Escolha a forma de pagamento"
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <PaymentContent>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{ padding: 0 }}
        >
          <FormGroup>
            <Radio
              type="radio"
              name="paymentType"
              value="world_bank_slip"
              id="payment-type-invoice"
              label="Boleto"
              innerRef={register({ required: true })}
              onClick={handleResetError}
              inline
            />
            <Radio
              type="radio"
              name="paymentType"
              value="world_credit_card"
              id="payment-type-creditcard"
              label="Cartão de crédito"
              innerRef={register({ required: true })}
              onClick={handleResetError}
              inline
            />
          </FormGroup>

          <Divider />
        </Form>

        {!loadingCreateSubscription &&
          <PaymentContentForm>
            {paymentType === 'world_bank_slip'
              && (
              <PaymentInvoice
                {...{
                  theme, 
                  onSubmit: submitPayment, 
                  onCancel, 
                  isValid, 
                  handleSubmit,
                }}
              />
              )}
            {paymentType === 'world_credit_card' && (
            <PaymentCreditCard
              {...{
                theme,
                onCancel,
                isValid,
                control,
                errors,
                watch,
                register,
                handleFields: handleCreditCardInfo,
              }}
            />
            )}
            {failCreateSubscription &&
              <div style={{ display: 'grid', rowGap: '18px', textAlign: 'center', padding: '25px'}}>
                <label>Ocorreu um erro inesperado ao tentar efetuar a contratação !</label>
                <label>Tente novamente dentro de alguns instantes ou entre em contato com nosso financeiro.</label>
              </div>
            }
          </PaymentContentForm>
        }
        {loadingCreateSubscription && 
          <div style={{ width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LoadingSpin
              show={loadingCreateSubscription}
              weight="5px"
              size="80px"
              backColor="#1a2454"
              frontColor="transparent"
            />
          </div>
        }
      </PaymentContent>
    </Step>
  );
}

Payment.propTypes = StepPropTypes;
