import React from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionPortal,
  Col,
  DefaultActionButtons,
  Form,
  FormGroup,
  Input,
  Radio,
  Row,
  Step,
} from 'components';

import { StepPropTypes } from '../../../constants/propTypes';

export default function System(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { system } = {},
  } = props;

  const {
    formState, handleSubmit, register, watch,
  } = useForm({
    mode: 'all',
    defaultValues: system,
  });

  const systemOption = watch('system');
  const { isValid } = formState;
  const options = [
    [
      'Cobli',
      'Rota Exata',
      'Sofit',
      'Sascar',
      'Carrorama',
      'Ituran',
      'Skytech',
      'CarSystem',
      'Positron',
      'Omnilink',
    ],
    [
      'Trekken',
      'Barazal Rastreadores',
      'Movisat',
      'Ceabs',
      'Golfleet',
      'Pointer Cielo',
      'Frota Control',
      'Fortesat',
      'Unifrota',
      'SatLight',
    ],
  ];

  return (
    <Step title="Para qual outro sistema você está mudando?">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup tag="fieldset">
          <Row>
            {options.map((optionGroup) => (
              <Col md="6">
                {optionGroup.map((option) => (
                  <div style={{ marginBottom: '14px' }}>
                    <Radio
                      name="system"
                      value={option}
                      id={option}
                      innerRef={register}
                      label={option}
                    />
                  </div>
                ))}
              </Col>
            ))}
            <Col md="6">
              <div style={{ marginBottom: '14px' }}>
                <Radio
                  name="system"
                  id="others"
                  value="others"
                  innerRef={register}
                  label="Outros"
                />
              </div>
              {systemOption === 'others' && (
                <div style={{ marginBottom: '14px' }}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="system"
                      id="othersOption"
                      innerRef={register}
                      placeholder="Digite o nome do Sistema"
                    />
                  </FormGroup>
                </div>
              )}
            </Col>
          </Row>
        </FormGroup>
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

System.propTypes = StepPropTypes;
