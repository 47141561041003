import React from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionPortal,
  Checkbox,
  DefaultActionButtons,
  Form,
  FormGroup,
  Input,
  Step,
} from 'components';

import { StepPropTypes } from '../../../constants/propTypes';

export default function Reasons(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { reasons } = {},
  } = props;

  const {
    formState, handleSubmit, register, watch,
  } = useForm({
    mode: 'all',
    defaultValues: reasons,
  });

  const othersOption = watch('othersOption');
  const { isValid } = formState;

  return (
    <Step
      title="Por qual razão a sua empresa solicitou o cancelamento do Contele Rastreador?"
      subtitle="Você pode selecionar mais de uma opção."
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup tag="fieldset">
          <div style={{ margin: '14px 0' }}>
            <Checkbox
              name="reason"
              id="didntFullfillNeeds"
              innerRef={register}
              label="Sistema não atendeu minhas necessidades"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="missingSatellite"
              innerRef={register}
              label="Necessidade de sistema via satélite"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="alreadyHaveTracker"
              innerRef={register}
              label="Seguradora fornece rastreador"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="unmetExpectations"
              innerRef={register}
              label="Não obtive os resultados desejados"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="usability"
              innerRef={register}
              label="Dificuldade de uso da sistema"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="contractEnded"
              innerRef={register}
              label="Término de contrato com clientes"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="outOfBusiness"
              innerRef={register}
              label="Fechamento da empresa"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="outsourcing"
              innerRef={register}
              label="Terceirização da frota"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="sellingVehicle"
              innerRef={register}
              label="Venda dos veículos"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="issuesWithSystem"
              innerRef={register}
              label="Problemas no sistema"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="cuttingBudget"
              innerRef={register}
              label="Redução de Custos"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="reason"
              id="lackOfSupport"
              innerRef={register}
              label="Falta de atendimento para dúvidas"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="othersOption"
              value="othersOption"
              id="othersOption"
              innerRef={register}
              label="Outros"
            />
          </div>
          {othersOption && (
            <div style={{ marginBottom: '14px', width: '50%' }}>
              <FormGroup>
                <Input
                  type="text"
                  name="reason"
                  id="others"
                  innerRef={register}
                  placeholder="Digite o motivo"
                />
              </FormGroup>
            </div>
          )}
        </FormGroup>
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

Reasons.propTypes = StepPropTypes;
