export function get_token_from_local_storage() {
    const token = localStorage.getItem("auth_token");

    const has_token = !!token && typeof token === 'string';

    if(!has_token){
        return { userInfo: {} }
    };

    const [,token_payload_raw] = token.split('.');
    const token_payload_json_string = atob(token_payload_raw);
    const token_payload_json = JSON.parse(token_payload_json_string);

    return token_payload_json;

};