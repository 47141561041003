import styled from 'styled-components';

export const CreditCardWrapper = styled.div((props) => ({
  justifyContent: 'self-start',
  width: '680px',

  [`@media${props.theme.devices.laptop}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const CreditCardContent = styled.div((props) => ({
  marginBottom: '30px',

  img: {
    maxWidth: '350px',
  },

  [`@media${props.theme.devices.laptop}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
