import React, { useContext } from 'react';
import { bool } from 'prop-types';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { ThemePropType } from 'assets/styles/theme';

import { InvoiceWizard, IncreasePlanWizard, withWizard } from 'containers';

import { ContentWithHeader, StepHeader, StepHeaderItem } from 'components';
import { changeStep } from 'store/modules';

import CompletedWizard from './completedWizard';

export const steps = [
  {
    title: 'Solução',
    content: (props) => <IncreasePlanWizard {...props} />,
  },
  {
    title: 'Sua nova fatura',
    content: (props) => (
      <InvoiceWizard
        withAlert
        {...props}
      />
    ),
  },
  {
    title: 'Finalizar Contratação',
    content: (props) => <CompletedWizard {...props} />,
  },
];

const Navigation = ({ theme, disabled }) => {
  const { parentCurrentStep: currentStep } = useSelector((state) => state.subscription);
  return (
    <StepHeader theme={theme}>
      {steps.map((step, index) => (
        <StepHeaderItem
          theme={theme}
          key={`step-header-item-${step.title}`}
          step={index}
          title={step.title}
          disabled={disabled}
          completed={currentStep > index}
          active={index === currentStep}
        />
      ))}
    </StepHeader>
  );
};

Navigation.propTypes = {
  theme: ThemePropType.isRequired,
  disabled: bool.isRequired,
};

export default function Subscription() {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();

  return (
    <ContentWithHeader
      callToActionText="Siga o passo a passo para finalizar seu cadastro."
      navigation={<Navigation />}
    >
      {withWizard({
        steps,
        theme,
        onStepChanged: ({ currentStep: step }) => {
          dispatch(changeStep({ parentCurrentStep: step }));
        },
      })(({ children }) => children)}
    </ContentWithHeader>
  );
}
