import styled from 'styled-components';

import { Container, Table } from 'components';

export const ActionCellEditor = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  maxWidth: '80px',

  '> button': {
    height: '24px',
    width: '24px',
  },
}));

export const TableHeader = styled.th(() => ({
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '0 5px 10px !important',
}));

export const TableColumn = styled.td(() => ({
  fontSize: '14px',
  verticalAlign: 'middle !important',
}));

export const TableChildRowHeader = styled(TableColumn)(() => ({
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '14px 10px !important',
  borderTop: 'none !important',
}));

export const TableChildRowCell = styled(TableColumn)((props) => ({
  fontSize: '14px',
  verticalAlign: 'middle !important',
  borderTop: 'none !important',
  color: props.theme.colors.text.paragraph,
}));

export const TableRow = styled.tr((props) => ({
  borderTop: 'none !important',
  padding: '12px 14px',
  backgroundColor: props.childRow ? '#F5F5FF' : undefined,
}));

export const InvoiceTable = styled(Table)(() => ({
  margin: 0,
  'thead th': {
    border: 'none',
  },

  'tbody td': {
    button: {
      maxHeight: '12px',
    },
  },
}));

export const ContractDetailsContainer = styled(Container)((props) => ({
  margin: '0 auto',
  padding: '80px',
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',

  [`@media${props.theme.devices.laptop}`]: {
    padding: '40px 16px',
  },
}));
