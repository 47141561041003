import Axios from "axios";

import {
    BILLING_API_URL,
    TOKEN_VALIDATION_API_URL,
    N8N_GV_ENDPOINTS
} from "constants/environments";

export const generateSubscriptionPreview = async ({ subscriptionData }) => {
    const API_URL = BILLING_API_URL + '/api/v1/preview_create_subscription';

    const response = await Axios({
        method: 'POST',
        url: API_URL,
        data: subscriptionData,
        validateStatus: function (status) {
            return status >= 200 && status <= 400;
        }
    });

    const subscription_preview = response.data || {};

    return subscription_preview;
};

export const createSubscription = async ({ subscriptionData, authToken }) => {
    const API_URL = BILLING_API_URL + '/api/v1/create_subscription';

    const response = await Axios({
        method: 'POST',
        url: API_URL,
        data: subscriptionData,
        headers: {
            'x-cgv-token': `Bearer ${authToken}`
        }
    });

    const subscription = response.data || {};

    return subscription;
};

export const validateAuthorizationToken = async ({ authToken }) => {
    try {
        await Axios({
            method: 'GET',
            url: TOKEN_VALIDATION_API_URL,
            headers: {
                'Authorization': `Bearer ${authToken}`
            },
        });

        return true;

    } catch (error) {
        return false;
    }
};

export const getSubscription = async () => { //TODO: VERIFY VALID SUBSCRIPTION FROM API
    const response = await Promise.resolve({
        subscriptions: [],
        best_subscription_id: "" || null
    });

    return response;
};

const default_available_billing_due_days_output = Object.freeze({
    available_billing_due_days: [
        5,
        10,
        20,
        25
    ],
});

export const listAvailableBillingDueDays = async ({
    billing_address_state,
    contele_product_id
}) => {
    const endpoint = `${BILLING_API_URL}/api/v1/list_available_billing_due_days`;

    try {
        const axios_response = await Axios({
            method: 'POST',
            url: endpoint,
            data: {
                billing_address_state,
                contele_product_id,
            }
        });

        const {
            available_billing_due_days
        } = axios_response.data;

        return {
            available_billing_due_days
        };
    } catch (error) {
        console.error(error);
        return default_available_billing_due_days_output;
    }
};

export const downloadRentalContract = async ({ create_contract_payload, attempt = 0 }) => {
    if(attempt>2){
        throw Error(`request failed! max attempt reached`)
    };

    try {
        const token = localStorage.getItem('auth_token').replaceAll('"', "")

        const response = await Axios({
            method: 'POST',
            url: `${N8N_GV_ENDPOINTS}/subscription/download_rental_contract`,
            data: {
                ...create_contract_payload
            },
            headers: {
                accept: 'application/pdf',
                'x-cgv-token-v0': 'Bearer ' + token
            },
            responseType: 'blob',


        })

        return response.data

    } catch (error) {

       return await downloadRentalContract({ create_contract_payload, attempt: attempt + 1})
    }
}
