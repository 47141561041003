import styled from 'styled-components';
import { Pagination } from 'reactstrap';

import { Row, Table } from 'components';

export const ConfirmationContent = styled.div`
  margin: auto;
  max-width: 600px;
`;

export const AppChecklist = styled(Row)((props) => ({
  justifyContent: 'space-evenly',

  '> div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`@media${props.theme.devices.laptop}`]: {
    '> div': {
      display: 'flex',
      justifyContent: 'start !important',
      alignItems: 'center',
    },
  },
}));

export const ChecklistItem = styled.li(() => ({
  alignItems: 'center',
  display: 'flex',
  margin: '24px 0',
}));

export const StyledTable = styled(Table)((props) => ({
  margin: 0,
  tr: {
    borderTop: 'none !important',
    borderBottom: `'1px solid ${props.theme.colors.border}`,
  },

  'tbody td': {
    button: {
      maxHeight: '12px',
    },
  },
}));

export const TableColumn = styled.td((props) => ({
  fontSize: '14px',
  color: props.theme.colors.text.label,
  padding: '12px 16px !important',
  verticalAlign: 'middle !important',
}));

export const StyledPagination = styled(Pagination)((props) => ({
  '.page-link': {
    color: props.theme.colors.primary,
  },

  '.page-item.active .page-link': {
    borderColor: props.theme.colors.primary,
    backgroundColor: props.theme.colors.primary,
    color: 'white',
  },
}));
