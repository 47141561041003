import styled from 'styled-components';
import { Container } from 'reactstrap';

export const DashboardContent = styled(Container)((props) => ({
  margin: '0 auto',
  padding: '80px',
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',

  [`@media${props.theme.devices.laptop}`]: {
    padding: '40px 16px',
  },
}));
