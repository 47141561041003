import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { generateSubscriptionPreview } from 'services/api';
import { parseDataToApi } from 'utils/parseDataToApi';

import {
  ActionPortal,
  Alert,
  DefaultActionButtons,
  Icon,
  Paragraph,
  Step,
  ContractTable,
  LoadingSpin,
  DiscountCouponInput,
  Button
} from 'components';


import { StepPropTypes } from '../../constants/propTypes';

import { ConfirmationContent } from './style';

export default function Confirmation(props) {
  const queryParams = useLocation().search;
  const defaultDiscountCoupon = new URLSearchParams(queryParams).get('discountCoupon');

  const ITEMS_DEFAULT = {
    quantity: 0,
    unit_price_per_cycle: 0,
    total_price_without_discount_per_cycle: 0,
    total_discount_absolute_value_per_cycle: 0
  };

  const {
    setValue, handleSubmit, register
  } = useForm({
    mode: 'onSubmit',
    defaultValues: { discountCoupon: defaultDiscountCoupon }
  });

  const [subscriptionPreview, setSubscriptionPreview] = useState(null);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [failLoadingPreview, setFailLoadingPreview] = useState(false);

  const [appliedCoupon, setAppliedCoupon] = useState(defaultDiscountCoupon);
  const [couponIsValid, setCouponIsValid] = useState(false);

  const [trackersInfo, setTrackersInfo] = useState(ITEMS_DEFAULT);
  const [obdInfo, setObdInfo] = useState(ITEMS_DEFAULT);
  const [blockersInfo, setBlockersInfo] = useState(ITEMS_DEFAULT);

  const {
    goToStep, theme, onSubmit, onCancel, initialValues: {
      basicInfo, solutionInfo
    },
  } = props;

  const {
    app, blocker, vehicleManager, plugAndPlay
  } = solutionInfo;

  const validateDiscountCoupon = async (discountCoupon) => {
    const subscriptionData = parseDataToApi({
      ...basicInfo,
      ...solutionInfo,
      discountCoupon
    });

    const responseSubscriptionPreview = await generateSubscriptionPreview({ subscriptionData });

    const {
      billing_preview,
      message,
      errors
    } = responseSubscriptionPreview;

    const isInvalidDiscountCoupon = message && message === 'discount_code_not_available';
    const hasUnexpectedErrorOnCreatePreview = !!errors && !isInvalidDiscountCoupon;

    return {
      isValid: !isInvalidDiscountCoupon && !!billing_preview,
      billing_preview,
      hasUnexpectedErrorOnCreatePreview
    }
  };

  const getSubscriptionPreview = async (discountCoupon, failByCoupon) => {
    const hasPreviousSubscriptionPreview = !!subscriptionPreview;

    setLoadingPreview(true);

    try {
      const couponResponse = await validateDiscountCoupon(discountCoupon);

      if(couponResponse?.hasUnexpectedErrorOnCreatePreview) {
        throw new Error('Unexpected error');
      };

      const hasCouponAndIsInvalid = !!discountCoupon && !couponResponse.isValid;
      const couponIsValid = !failByCoupon && couponResponse.isValid;

      setCouponIsValid(couponIsValid);

      const failFirstPreviewLoadingByCoupon = hasCouponAndIsInvalid && !hasPreviousSubscriptionPreview;

      if (failFirstPreviewLoadingByCoupon) {
        setAppliedCoupon('');
        return getSubscriptionPreview(false, true);
      };

      const conditional_billing_preview = couponResponse?.billing_preview || subscriptionPreview;

      setSubscriptionPreview(conditional_billing_preview);
      setFailLoadingPreview(false);
      generateTableValues(conditional_billing_preview);
    } catch (error) {
      setFailLoadingPreview(true);
    };

    setLoadingPreview(false);
  };

  useEffect(() => {
    getSubscriptionPreview(appliedCoupon);
    // eslint-disable-next-line
  }, []);

  const onClickLineItem = (line) => {
    goToStep(line.id);
  };

  const applyDiscountCoupon = async (discountCoupon) => {
    setLoadingPreview(true);
    try {
      const couponResponse = await validateDiscountCoupon(discountCoupon);

      if(couponResponse?.isValid){
        const conditional_billing_preview = couponResponse?.billing_preview || subscriptionPreview;
  
        setSubscriptionPreview(conditional_billing_preview);
        setValue('discountCoupon', discountCoupon);
        setAppliedCoupon(discountCoupon);
      };

      setCouponIsValid(couponResponse?.isValid);
    } catch (error) {
      setCouponIsValid(false);
    }
    setLoadingPreview(false);
  };

  const generateTableValues = (subscription_preview) => {
    const trackersRow = subscription_preview?.subscription_items?.find(item => item.contele_product_id.includes('cgv_tracker_device_unit'));
    const blockersRow = subscription_preview?.subscription_items?.find(item => item.contele_product_id.includes('cgv_tracker_feature_fuel_lock_unit'));
    const obdRow = subscription_preview?.subscription_items?.find(item => item.contele_product_id.includes('cgv_tracker_device_plug_and_play_unit'));

    setTrackersInfo(trackersRow || {});
    setBlockersInfo(blockersRow || {});
    setObdInfo(obdRow || {});
  };

  const submitForm = () => {
    setValue('discountCoupon', appliedCoupon);
    handleSubmit(onSubmit)();
  };

  const formatToCurrency = (value) => value && value.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });

  const calculateTable = () => (
    [
      {
        id: 0,
        amount: trackersInfo?.quantity,
        description: 'Rastreadores',
        unitary_value: formatToCurrency(trackersInfo?.unit_price_per_cycle),
        value: formatToCurrency(trackersInfo?.total_price_without_discount_per_cycle),
        normalizedValue: trackersInfo?.total_price_without_discount_per_cycle,
        discountValue: trackersInfo?.total_discount_absolute_value_per_cycle,
        subRow: [
          {
            id: 0,
            amount: vehicleManager.lightweightVehiclesCount,
            description: 'Veículos leves',
            unitary_value: '',
            value: '',
          }, {
            id: 1,
            amount: vehicleManager.motorbikeCount,
            description: 'Motos',
            unitary_value: '',
            value: '',
          }, {
            id: 2,
            amount: vehicleManager.heavyVehiclesCount,
            description: 'Veículos pesados',
            unitary_value: '',
            value: '',
          }, {
            id: 3,
            amount: vehicleManager.machineCount,
            description: 'Máquinas',
            unitary_value: '',
            value: '',
          },
        ],
      },
      plugAndPlay.usePlugAndPlay && {
        id: 1,
        amount: obdInfo?.quantity,
        description: 'Plug and Play',
        unitary_value: formatToCurrency(obdInfo?.unit_price_per_cycle),
        value: formatToCurrency(obdInfo?.total_price_without_discount_per_cycle),
        normalizedValue: obdInfo?.total_price_without_discount_per_cycle,
      },
      blocker.useBlocker && {
        id: 2,
        amount: blockersInfo?.quantity,
        description: 'Bloqueadores',
        unitary_value: formatToCurrency(blockersInfo?.unit_price_per_cycle),
        value: formatToCurrency(blockersInfo?.total_price_without_discount_per_cycle),
        normalizedValue: blockersInfo?.total_price_without_discount_per_cycle,
      },
      app.hireApp && {
        id: 3,
        amount: trackersInfo?.quantity,
        description: 'App Contele Driver',
        unitary_value: 'R$5,00',
        value: 'Gratuito',
        normalizedValue: '',
        discountValue: '',
      },
    ].filter((item) => item !== false)
  );

  const couponInput = (
    <DiscountCouponInput
      register={register}
      defaultCoupon={defaultDiscountCoupon}
      onApplyDiscountCoupon={applyDiscountCoupon}
      couponIsValid={couponIsValid}
      onLoading={loadingPreview}
    />
  );

  const totalDiscountValue = subscriptionPreview?.total_discount_absolute_value_per_cycle;

  return (
    <Step
      title="Confirmação"
      subtitle="Confirme os dados da sua contratação"
    >
      {!!subscriptionPreview &&
        <ConfirmationContent>
          <ContractTable
            headers={[
              {
                name: 'Quantidade',
                key: 'amount',
              }, {
                name: 'Descrição',
                key: 'description',
              }, {
                name: 'Valor unitário',
                key: 'unitary_value',
              }, {
                name: 'Valor',
                key: 'value',
              },
            ]}
            body={calculateTable()}
            theme={theme}
            onClickInLineButton={onClickLineItem}
            couponInput={couponInput}
            loadingTotalValues={loadingPreview}
            total={subscriptionPreview?.total_price_without_discount_per_cycle}
            discountTotal={subscriptionPreview?.total_price_with_discount_per_cycle}
            diffDiscount={totalDiscountValue}
            appliedCoupon={appliedCoupon}
          />

          {totalDiscountValue > 0 &&
            <Alert
              warning
              theme={theme}
            >
              <Icon
                icon="exclamationTriangle"
                width="18px"
                height="18px"
                color={theme.colors.status.warning}
                style={{ marginRight: '8px' }}
              />
              <Paragraph fontSize="14px">
                O desconto de
                {' '}
                {formatToCurrency(subscriptionPreview?.total_discount_absolute_value_per_cycle)}
                {' '}
                só será mantido nas faturas pagas até a data do vencimento.
              </Paragraph>
            </Alert>
          }
        </ConfirmationContent>
      }
      {loadingPreview && !subscriptionPreview &&
        <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingSpin
            show={loadingPreview}
            weight="5px"
            size="60px"
            backColor={theme.colors.primary}
            frontColor="transparent"
          />
        </div>
      }
      {!loadingPreview && !subscriptionPreview && failLoadingPreview &&
        <div style={{ width: '100%', height: '200px', display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <span>Tivemos um problema inesperado ao tentar gerar o valor de sua contratação.</span>
            <br/>
            <span>Por favor, tente novamente dentro de alguns instantes, caso o erro persista, entre em contato com nosso financeiro.</span>
          </div>
          <Button
            width="200px"
            padding="10px"
            title="Tentar Novamente"
            onClick={() => getSubscriptionPreview()}
          />
        </div>
      }

      <ActionPortal>
        <DefaultActionButtons
          theme={theme}
          isValid={!loadingPreview && !failLoadingPreview}
          backButtonStyle={{
            iconPosition: 'left',
            border: 'none',
            customBackgroundColor: 'transparent',
            customIconColor: theme.colors.text.label,
            customTextColor: theme.colors.text.label,
            title: 'Passo Anterior',
          }}
          nextButtonStyle={{
            customBackgroundColor: theme.colors.primary,
            customIconColor: theme.colors.text.white,
            customTextColor: theme.colors.text.white,
            title: 'Próximo Passo',
          }}
          onCancel={onCancel}
          onSubmit={() => !loadingPreview && submitForm()}
        />
      </ActionPortal>
    </Step>
  );
}

Confirmation.propTypes = StepPropTypes;
