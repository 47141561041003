import { cpf, cnpj } from 'cpf-cnpj-validator';

export const inputsValidations = {
    BR: {
        required: 'Campo obrigatório',
        peopleAndCompanyDocument: (toVerifyDocument) => {
            const isCnpj = cnpj.isValid(toVerifyDocument);
            const isCpf = cpf.isValid(toVerifyDocument);

            const documentIsValid = isCnpj || isCpf;

            return documentIsValid || 'Documento inválido.';
        },
        peopleDocument: (toVerifyDocument) => cpf.isValid(toVerifyDocument) || 'Documento inválido.',
        companyDocument: (toVerifyDocument) => cnpj.isValid(toVerifyDocument) || 'Documento inválido.',
        validThruCheck: (date) => {
            const MAX_VALID_YEAR = 2100;
            const MAX_VALID_MONTH = 12;

            const validDateFormat = [
                typeof date === 'string',
                date.split('/').length > 1
            ].every(Boolean);

            if(!validDateFormat) {
                return 'Data inválida.';
            };

            const [validMonth, validYear] = date.split('/');

            const currentDate = new Date();

            const currentMonth = currentDate.getMonth();
            const currentYear = currentDate.getFullYear();

            const validMonthNumber = Number(validMonth);
            const validYearNumber = Number(validYear);

            const dateToNumberIsValid = !!validMonthNumber && !!validYearNumber;
            const monthIsGreatterThanPossible = validMonthNumber > MAX_VALID_MONTH;
            const yearIsGreatterThanPossible = validYearNumber > MAX_VALID_YEAR;

            if(!dateToNumberIsValid || monthIsGreatterThanPossible || yearIsGreatterThanPossible){
                return 'Data inválida.';
            };

            const yearDiffValue = (validYearNumber - currentYear);
            const monthDiffValue = ((validMonthNumber - 1) - currentMonth);

            const yearIsNotExpired =  yearDiffValue >= 0;
            const monthIsNotExpired = yearDiffValue > 0 || (yearDiffValue === 0 && monthDiffValue >= 0);

            const validThruIsNotExpired = yearIsNotExpired && monthIsNotExpired;

            return validThruIsNotExpired || 'Data expirada.'
        },
        communText: {
            minLength: {
                value: 2,
                message: 'Deve conter pelo menos 2 caracteres.'
            },
            maxLength: {
                value: 256,
                message: 'Máximo de 256 caracteres.'
            }
        },
        peopleName: {
            minLength: {
                value: 3,
                message: 'Deve conter pelo menos 3 caracteres.'
            },
            maxLength: {
                value: 256,
                message: 'Máximo de 256 caracteres.'
            }
        },
        email: {
            minLength: {
                value: 3,
                message: 'Email inválido.'
            },
            maxLength: {
                value: 256,
                message: 'Máximo de 256 caracteres.'
            },
            pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                message: 'Email inválido.'
            }
        },
        phone: {
            minLength: {
                value: 10,
                message: 'Número inválido.'
            },
            maxLength: {
                value: 14,
                message: 'Número inválido.'
            },
            pattern: {
                value: /^(\+\d\d)?\d{10,11}$/,
                message: 'Número inválido.'
            }
        },
        cardNumber: {
            maxLength: {
                value: 19,
                message: 'Cartão inválido.'
            },
            minLength: {
                value: 16,
                message: 'Cartão inválido.'
            },
            pattern: {
                value: /^(1|2|3|4|5|6)/,
                message: 'Cartão inválido.'
            }
        },
        cardName: {
            maxLength: {
                value: 22,
                message: 'Máximo de 22 caracteres.'
            },
            minLength: {
                value: 2,
                message: 'Deve conter pelo menos 2 caracteres.'
            }
        },
        validThru: {
            pattern: {
                value: /[0-9]{2}\/[0-9]{4}/i,
                message: 'Data inválida.'
            }
        },
        cardCvc: {
            pattern: {
                value: /\d\d\d/,
                message: 'Código inválido.'
            }
        },
        cep: {
            pattern: {
                value: /^[0-9]{8}$/i,
                message: 'Cep inválido',
            }
        }
    }
};