import React from 'react';

import { StylingPropType } from '../propTypes';

import { CustomCheckbox } from './style';

export default function Checkbox(props) {
  return (
    <CustomCheckbox
      type="checkbox"
      style={{ cursor: 'pointer' }}
      {...props}
    />
  );
}

Checkbox.propTypes = StylingPropType;
