import styled from 'styled-components';

export const PaymentContent = styled.div(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const PaymentContentForm = styled.div((props) => ({
  display: 'grid',
  justifyItems: 'center',
  [`@media${props.theme.devices.laptop}`]: {
    flexDirection: 'column',
  },
}));
