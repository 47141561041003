import styled from 'styled-components';

const buttonDefault = styled.button((props) => ({
  height: '36px',
  width: props.width || '100px',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  outline: 'none',
  background: props.disabled ? '#8a8b96' : props.backgroundColor || '#1A237A',
  flexDirection: props.flexDirection || 'row',
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  alignItems: props.alignItems || 'center',
  border: props.disabled
    ? `1px solid ${props.theme.colors.button.disabled.border}`
    : props.border || `1px solid ${props.theme.colors.button.default.border}`,
  borderRadius: props.borderRadius || '0.25em',
  padding: props.padding || '0.5 1em',
  minWidth: props.minWidth,
  '& *': {
    opacity: props.disabled ? '0.7' : '1',
    cursor: props.disabled ? 'not-allowed' : 'pointer',
  },
  '&:hover': {
    ...props.hover,
  },
  ...props,
}));

export { buttonDefault as ButtonDefault };
