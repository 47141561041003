import styled from 'styled-components';

export const Table = styled.table`
    margin-bottom: 1rem;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;

`;

export const Head = styled.th`
    padding: 0 0 0rem ;
    font-size: 1em;
    font-weight: 700;
    color: #222222;
    &:last-of-type {
        text-align: right;
    }
`;

export const Td = styled.td`
    padding: 1.5rem 0 0;
    font-size: 1em;
    font-weight: 400;
    color: #444444;
    &:last-of-type {
        text-align: right;
    }
    div {
        display: flex;
    }
`;

export const SubTd = styled.td`
    padding: 0 0 0.75rem;
    font-size: 0.75em;
    font-weight: 400;
    color: #888888;
`;