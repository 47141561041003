import React from 'react';

import { Text, Input } from "components";

export default function ValidatedInput(props) {

    const {
        name,
        placeholder,
        invalid,
        innerRef,
        errors = {},
        disabled,
        defaultValue
    } = props;

    return (
        <div>
            <Input
                type="text"
                name={name}
                placeholder={placeholder}
                invalid={invalid}
                innerRef={innerRef}
                readOnly={disabled}
                defaultValue={defaultValue}
            />
            <Text
                fontSize={12}
                color={'#E65845'}
                style={{ marginTop: '8px' }}
            >
                {errors[name]?.message}
            </Text>
        </div>
    );
}