import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { bool } from 'prop-types';

import { ThemePropType } from 'assets/styles/theme';

import {
  BasicInfoWizard,
  ContractInfoWizard,
  PaymentInfoWizard,
  SolutionInfoWizard,
  withWizard,
} from 'containers';
import {
  ButtonWithIcon,
  Completed,
  ContentWithHeader,
  CTA,
  Footer,
  StepHeader,
  StepHeaderItem,
} from 'components';
import { changeStep } from 'store/modules';

export const steps = [
  {
    id: 0,
    content: (props) => <BasicInfoWizard {...props} />,
    title: 'Dados Cadastrais',
  },
  {
    id: 1,
    content: (props) => <SolutionInfoWizard {...props} />,
    title: 'Solução',
  },
  {
    id: 2,
    content: (props) => <ContractInfoWizard {...props} />,
    title: 'Contrato',
  },
  {
    id: 3,
    content: (props) => <PaymentInfoWizard {...props} />,
    title: 'Pagamento',
  },
];

const Navigation = ({ theme, disabled, fullCompleted }) => {
  const { parentCurrentStep: currentStep } = useSelector((state) => state.subscription);
  return (
    <StepHeader theme={theme}>
      {steps.map((step, index) => (
        <StepHeaderItem
          theme={theme}
          key={`step-header-item-${step.title}`}
          step={index}
          title={step.title}
          disabled={disabled}
          completed={currentStep > index || fullCompleted}
          active={index === currentStep}
        />
      ))}
    </StepHeader>
  );
};

Navigation.defaultProps = {
  disabled: false,
  theme: null,
};

Navigation.propTypes = {
  theme: ThemePropType,
  disabled: bool,
};

export default function Subscription() {
  const theme = useContext(ThemeContext);
  const [completed, setCompleted] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const dispatch = useDispatch();

  const renderContent = ({ enable, disabled, children }) => {
    if (completed) return <Completed />;
    if (disabled) {
      return (
        <>
          <CTA />
          <Footer padding="16px 24px">
            <ButtonWithIcon
              customBackgroundColor={theme.colors.primary}
              icon="arrowRight"
              title="Começar agora"
              onClick={() => {
                enable(true);
                setEnabled(true);
              }}
            />
          </Footer>
        </>
      );
    }

    return children;
  };

  return (
    <ContentWithHeader
      callToActionText="Siga o passo a passo para finalizar seu cadastro."
      navigation={<Navigation disabled={!enabled} fullCompleted={completed} />}
    >
      {withWizard({
        disabled: !enabled,
        initialValues: {},
        steps,
        theme,
        onStepChanged: ({ currentStep: step }) => {
          dispatch(changeStep({ parentCurrentStep: step }));
        },
        complete: (values) => {
          dispatch(changeStep({ parentCurrentStep: steps.length - 1 }));
          setCompleted(true);
        },
      })(renderContent)}
    </ContentWithHeader>
  );
}
