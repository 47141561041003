import React from 'react';
import { useForm } from 'react-hook-form';
import {
  ActionPortal,
  Col,
  DefaultActionButtons,
  Form,
  FormGroup,
  DocumentInput,
  Row,
  Step,
} from 'components';

import { inputsValidations } from 'validations/inputsFieldsValidations';

import { StepPropTypes } from '../../constants/propTypes';

export default function GettingStarted(props) {
  const {
    theme, onSubmit, onCancel, initialValues: defaultValues,
  } = props;

  const {
    control, errors, formState, handleSubmit, watch,
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  const fieldsValidations = inputsValidations.BR;

  const { document } = defaultValues || {};

  const { isValid } = formState;

  const documentIsValid = () => fieldsValidations.peopleAndCompanyDocument(watch('document'));

  const submitForm = (e) => {
    e.preventDefault();

    if(isValid){
      handleSubmit(onSubmit)();
    };
  }

  return (
    <Step
      title="Vamos começar!"
      subtitle="Digite o CNPJ ou CPF do contratante para iniciar uma nova contratação ou continuar uma já iniciada."
    >
      <Form onSubmit={submitForm}>
        <Row form>
          <Col md="12">
            <FormGroup>
              <DocumentInput
                control={control}
                name="document"
                defaultValue={document}
                options=""
                errors={errors}
                rules={{
                  required: fieldsValidations.required,
                  validate: () => documentIsValid()
                }}
                placeholder="Ex.: 55.234.739/0001-86"
              />
            </FormGroup>
          </Col>
        </Row>
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

GettingStarted.propTypes = StepPropTypes;
