import styled from 'styled-components';

export const DefaultHeader = styled.header((props) => ({
  backgroundColor: props.theme.colors.primary,
  color: props.theme.colors.text.white,
  width: '300px',
  flexShrink: 0,
  padding: '40px 0 20px',

  [`@media${props.theme.devices.laptop}`]: {
    padding: '16px 0',
    width: 'auto',

    hr: {
      display: 'none',
    },
  },
}));

export const Text = styled.p((props) => ({
  opacity: 0.8,
  fontFamily: '"Roboto", sans-serif',
  fontSize: '16px',
  lineHeight: '1.4',
  margin: '0 0 17px',
  color: props.theme.colors.text.white,

  [`@media${props.theme.devices.laptop}`]: {
    display: 'none',
  },
}));

export const HeaderContent = styled.div((props) => ({
  padding: '0 40px',

  [`@media${props.theme.devices.laptop}`]: {
    padding: '0 16px',
  },
}));

export const Divider = styled.hr((props) => ({
  width: '100%',
  borderTopWidth: props.width || '2px',
  margin: props.margin || '0 0 23px',
  borderColor: props.color,

  [`@media${props.theme.devices.laptop}`]: {
    display: 'none',
  },
}));
