import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  ActionPortal,
  Col,
  DefaultActionButtons,
  Form,
  FormGroup,
  FormLabel,
  FormText,
  MaskedInput,
  Row,
  Step,
  Text,
  TextBold,
} from 'components';

import { StepPropTypes } from '../../constants/propTypes';

const formValidationSchema = yup
  .object()
  .shape({
    lightweightVehiclesCount: yup.number().integer().moreThan(-1),
    motorbikeCount: yup.number().integer().moreThan(-1),
    heavyVehiclesCount: yup.number().integer().moreThan(-1),
    machineCount: yup.number().integer().moreThan(-1),
  })
  .test('sum', (value) => {
    if (!value) return true;
    const {
      lightweightVehiclesCount,
      motorbikeCount,
      heavyVehiclesCount,
      machineCount,
    } = value;

    const vehicleCountByType = [
      lightweightVehiclesCount,
      motorbikeCount,
      heavyVehiclesCount,
      machineCount,
    ].reduce((sum, cur) => sum + parseInt(cur || 0, 10), 0);

    return vehicleCountByType > 0;
  });

export default function VehicleManager(props) {
  const {
    theme,
    onSubmit,
    onCancel,
    initialValues: defaultValues,
    title = 'Quantidade de Veículos',
  } = props;

  const {
    control, formState, handleSubmit, watch,
  } = useForm({
    mode: 'all',
    defaultValues: defaultValues || {
      lightweightVehiclesCount: 0,
      motorbikeCount: 0,
      heavyVehiclesCount: 0,
      machineCount: 0,
    },
    resolver: yupResolver(formValidationSchema),
  });

  const lightweightVehiclesCount = watch('lightweightVehiclesCount');
  const motorbikeCount = watch('motorbikeCount');
  const heavyVehiclesCount = watch('heavyVehiclesCount');
  const machineCount = watch('machineCount');
  const sumOfAllVehicles = +lightweightVehiclesCount + +motorbikeCount + +heavyVehiclesCount + +machineCount;

  const { isValid } = formState;

  const submitForm = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <Step title={title}>
      <Form onKeyPress={e => e.key === 'Enter' && submitForm(e)}>
        <Row form>
          <Col md="12">
            <FormGroup>
              <Text
                color={theme.colors.text.default}
                fontSize="18px"
                fontWeight="400"
                fontHeight="20px"
                whiteSpace="normal"
                margin="16px 0 24px 0"
                inline
              >
                Informe quantos Veículos têm na sua empresa
              </Text>
              <Row>
                <Col
                  xs="12"
                  md="6"
                >
                  <FormGroup>
                    <FormLabel for="lightweightVehiclesCount">Veículos leves</FormLabel>
                    <MaskedInput
                      control={control}
                      name="lightweightVehiclesCount"
                      placeholder="Digite a quantidade de veículos"
                      format="numeric"
                    />
                    <FormText>(Carros, Caminhonetes, Caminhões pequenos e Vans)</FormText>
                  </FormGroup>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <FormGroup>
                    <FormLabel for="motorbikeCount">Motos</FormLabel>
                    <MaskedInput
                      control={control}
                      name="motorbikeCount"
                      placeholder="Digite a quantidade de motos"
                      format="numeric"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
              <Col
                xs="12"
                md="6"
              >
                <FormGroup>
                  <FormLabel for="heavyVehiclesCount">Veículos pesados</FormLabel>
                  <MaskedInput
                    control={control}
                    name="heavyVehiclesCount"
                    placeholder="Digite a quantidade de veículos pesados"
                    format="numeric"
                  />
                  <FormText>(Caminhões grandes e Ônibus)</FormText>
                </FormGroup>
              </Col>
              <Col
                xs="12"
                md="6"
              >
                <FormGroup>
                  <FormLabel for="machineCount">Máquinas</FormLabel>
                  <MaskedInput
                    control={control}
                    name="machineCount"
                    placeholder="Digite a quantidade de Máquinas"
                    format="numeric"
                  />
                </FormGroup>
              </Col>
            </Row>
            </FormGroup>
          </Col>
        </Row>
        {sumOfAllVehicles > 0 && (
          <>
            <Row>
                <Col md="12">
                  <Text
                    color={theme.colors.text.default}
                    fontSize="18px"
                    fontWeight="400"
                    fontHeight="20px"
                    whiteSpace="normal"
                    margin="16px 0 24px 0"
                    inline
                  >
                    Total de 
                    {' '}
                    <TextBold display="inline">{sumOfAllVehicles}</TextBold>
                    {' '}
                    Veículos
                  </Text>
                </Col>
              </Row>
          </>
        )}
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

VehicleManager.propTypes = StepPropTypes;
