import styled from 'styled-components';

import { Row } from 'components';

export const ConfirmationContent = styled.div`
  margin: auto;
  max-width: 600px;
`;

export const AppChecklist = styled(Row)((props) => ({
  justifyContent: 'space-evenly',

  '> div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`@media${props.theme.devices.laptop}`]: {
    '> div': {
      display: 'flex',
      justifyContent: 'start !important',
      alignItems: 'center',
    },
  },
}));
