import React from 'react';
import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';

import {
  ActionPortal,
  ButtonsHolder,
  ButtonWithIcon,
  Checkbox,
  Step,
  PlayerWrapper,
} from 'components';

import { StepPropTypes } from '../../../constants/propTypes';

export default function App(props) {
  const { theme, onSubmit, initialValues: { app } = {} } = props;
  const { register } = useForm({
    defaultValues: app,
  });

  return (
    <Step
      title="2 minutos para você mudar de idéia"
      subtitle="Recado do CEO do Contele Rastreador para você:"
    >
      <PlayerWrapper style={{ margin: '24px 0' }}>
        <ReactPlayer
          className="react-player"
          url="https://www.youtube.com/watch?v=4Wbe8X-py44"
          width="100%"
          height="100%"
          style={{
            left: 0,
            position: 'absolute',
            top: 0,
          }}
        />
      </PlayerWrapper>

      <Checkbox
        inline
        id="hireApp"
        name="hireApp"
        label="Assisti o vídeo e desejo seguir com o cancelamento da solução que reduz os custos da minha empresa."
        innerRef={register}
      />

      <ActionPortal>
        <ButtonsHolder justifyContent="flex-end">
          <ButtonWithIcon
            icon="arrowRight"
            iconPosition="right"
            border="none"
            customBackgroundColor="transparent"
            customIconColor={theme.colors.text.label}
            customTextColor={theme.colors.text.label}
            title="Próximo Passo"
            onClick={() => onSubmit()}
          />
        </ButtonsHolder>
      </ActionPortal>
    </Step>
  );
}

App.propTypes = StepPropTypes;
