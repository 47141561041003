import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import React, { useContext } from 'react';

import { changeStep } from 'store/modules';
import { StepContentWrapper } from 'components';
import { withWizard } from 'containers';

import Confirmation from './confirmation';
import TrackerManager from './trackerManager';

export default function PlanReductionWizard({
  navigateBack: parentNavigateBack,
  navigateForward: parentNavigateForward,
  currentStep: parentCurrentStep,
}) {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { reductionInfo } = useSelector((state) => state.subscription);
  const { confirmation, trackerManager } = reductionInfo || {};

  const onCancel = () => {
    parentNavigateBack();
  };

  const navigateBackOnCancel = ({ navigateBack }) => () => {
    navigateBack();
  };

  const onCompleted = (values) => {
    parentNavigateForward(values);
  };

  const onValuesChanged = ({ values, currentStep }) => {
    if (values) {
      const { childStep, formValues } = values;
      dispatch(
        changeStep({
          step: 'reductionInfo',
          currentStep,
          parentCurrentStep,
          [childStep]: formValues,
        }),
      );
    }
  };

  function onSubmit({ navigateForward, childStep }) {
    return (values) => navigateForward({ childStep, formValues: values });
  }

  const steps = [
    {
      content: (props) => (
        <TrackerManager
          {...props}
          initialValues={trackerManager}
          onCancel={onCancel}
          onSubmit={onSubmit({ ...props, childStep: 'trackerManager' })}
        />
      ),
    },
    {
      content: (props) => (
        <Confirmation
          {...props}
          initialValues={confirmation}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'confirmation' })}
        />
      ),
    },
  ];

  return withWizard({
    steps,
    theme,
    onValuesChanged,
    onCompleted,
  })(({ children }) => <StepContentWrapper>{children}</StepContentWrapper>);
}
