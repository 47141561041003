import React from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionPortal,
  Checkbox,
  DefaultActionButtons,
  Form,
  FormGroup,
  Input,
  Step,
} from 'components';

import { StepPropTypes } from '../../../constants/propTypes';

export default function Features(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { system } = {},
  } = props;

  const {
    formState, handleSubmit, register, watch,
  } = useForm({
    mode: 'all',
    defaultValues: system,
  });

  const { isValid } = formState;
  const othersOption = watch('othersOption');
  const options = [
    'Mapa dos Veículos',
    'KM rodado',
    'Abastecimentos',
    'Checklists',
    'Rotas',
    'Produtividade',
    'Excesso de Velocidade',
    'KM atingido',
    'Venda dos Veículos',
    'Carro parado com motor ligado',
    'Fora do Horário',
    'Cerca Eletrônica e Excesso dentro da Cerca',
  ];

  return (
    <Step title="Quais as funções que a sua empresa usa no Contele Rastreador?">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup tag="fieldset">
          {options.map((option) => (
            <div style={{ marginBottom: '14px' }}>
              <Checkbox
                name="features"
                value={option}
                id={option}
                innerRef={register}
                label={option}
              />
            </div>
          ))}
          <div style={{ marginBottom: '14px' }}>
            <Checkbox
              name="othersOption"
              value="othersOption"
              id="othersOption"
              innerRef={register}
              label="Outros"
            />
          </div>
          {othersOption && (
            <div style={{ marginBottom: '14px', width: '50%' }}>
              <FormGroup>
                <Input
                  type="text"
                  name="features"
                  id="others"
                  innerRef={register}
                  placeholder="Digite o nome do Sistema"
                />
              </FormGroup>
            </div>
          )}
        </FormGroup>
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

Features.propTypes = StepPropTypes;
