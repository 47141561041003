import React, { useEffect } from 'react';

import { AuthContext } from "services/authProvider";

import { LoadingSpin } from 'components';

export default function Authorization(props) {
    const { tryAuthorize } = AuthContext();

    useEffect(() => {
        tryAuthorize();
        // eslint-disable-next-line
    }, []);

    return (
        <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LoadingSpin
                show={true}
                weight="8px"
                size="80px"
                backColor="#1a2454"
                frontColor="transparent"
            />
        </div>
    );
};