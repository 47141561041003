import React from 'react';
import { Col as ColComponent } from 'reactstrap';

const Col = (props) => (
  <ColComponent
    {...props}
    widths={['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']}
  />
);

export default Col;
