import React from 'react';

import CreditCardsImage from 'assets/images/credit-card.png';
import { ThemePropType } from 'assets/styles/theme';
import {
  Col, FormGroup, FormLabel, Icon, Row, Text, ActionPortal,
  DefaultActionButtons,
  MaskedInput,
  ValidatedInput,
} from 'components';

import { inputsValidations } from 'validations/inputsFieldsValidations';

import { CreditCardContent, CreditCardWrapper } from './style';

export default function PaymentCreditCard({
  theme,
  isValid,
  onCancel,
  control,
  errors,
  watch,
  handleFields,
  register,
}) {
  const cardNumber = watch('cardNumber');
  const expirationDate = watch('expirationDate');
  const cvv = watch('cardSecurityCode');
  const cardHolder = watch('cardHolder');

  const fieldsValidations = inputsValidations.BR;

  return (
    <CreditCardWrapper theme={theme}>
      <CreditCardContent>
        <img
          style={{ display: 'block' }}
          src={CreditCardsImage}
          alt="Boleto"
        />
        <div style={{ display: 'inline-flex', marginTop: '12px', alignItems: 'center' }}>
          <Icon
            icon="lock"
            width="16px"
            height="16px"
            fontSize="14px"
            color="green"
          />
          <Text
            fontSize="12px"
            display="inline"
            margin="0 0 0 8px"
          >
            O pagamento é totalmente seguro e protegido. A Contele Rastreadores conta com a
            tecnologia SSL.
          </Text>
        </div>
      </CreditCardContent>

      <Row>
        <Col md="12">
          <FormGroup>
            <FormLabel for="cardHolder">Nome do titular</FormLabel>
            <ValidatedInput
              name="cardHolder"
              id="cardHolder"
              control={control}
              invalid={!!errors.cardHolder}
              errors={errors}
              defaultValue=""
              innerRef={register({ 
                required: fieldsValidations.required,
                ...fieldsValidations.cardName
              })}
              maxLength={22}
              placeholder="Digite o nome impresso no cartão"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormGroup>
            <FormLabel for="cardNumber">Número do cartão</FormLabel>
            <MaskedInput
              control={control}
              name="cardNumber"
              id="cardNumber"
              placeholder="Ex.: 2423 3242 2325 2312"
              errors={errors}
              defaultValue=""
              rules={{
                required: fieldsValidations.required,
                ...fieldsValidations.cardNumber
              }}
              options={{creditCard: true}}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <FormLabel for="expirationDate">Validade</FormLabel>
            <MaskedInput
              control={control}
              valueWithMask={true}
              name="expirationDate"
              id="expirationDate"
              format="validThru"
              placeholder="Ex.: 12/2021"
              errors={errors}
              defaultValue=""
              rules={{
                required: fieldsValidations.required,
                validate: (validThru) => fieldsValidations.validThruCheck(validThru),
                ...fieldsValidations.validThru
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <FormLabel for="cardSecurityCode">Código de segurança</FormLabel>
            <MaskedInput
              control={control}
              name="cardSecurityCode"
              id="cardSecurityCode"
              format="numeric"
              placeholder="Ex.: 333"
              errors={errors}
              defaultValue=""
              maxLength={3}
              rules={{
                required: fieldsValidations.required,
                ...fieldsValidations.cardCvc
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <ActionPortal>
        <DefaultActionButtons
          theme={theme}
          isValid={isValid}
          backButtonStyle={{
            iconPosition: 'left',
            border: 'none',
            customBackgroundColor: 'transparent',
            customIconColor: theme.colors.text.label,
            customTextColor: theme.colors.text.label,
            title: 'Passo Anterior',
          }}
          nextButtonStyle={{
            customBackgroundColor: isValid ? theme.colors.status.success : theme.colors.primary,
            customIconColor: theme.colors.text.white,
            customTextColor: theme.colors.text.white,
            title: 'Finalizar contratação',
          }}
          onCancel={onCancel}
          onSubmit={() => {
            handleFields({
              cardNumber, cvv, expirationDate, cardHolder,
            });
          }}
        />
      </ActionPortal>
    </CreditCardWrapper>
  );
}

PaymentCreditCard.propTypes = {
  theme: ThemePropType.isRequired,
};
