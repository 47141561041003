import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { changeStep } from 'store/modules';
import { withWizard } from 'containers';
import {
  Completed,
  Paragraph,
  StepContentWrapper,
  TextBold,
  ActionPortal,
  ButtonsHolder,
  Button,
} from 'components';
import { ThemePropType } from 'assets/styles/theme';

const CompletedRequestContent = ({ theme }) => (
  <>
    <Paragraph
      textAlign="center"
      fontSize="16px"
    >
      Aguarde que estamos processando seu pedido.
      {' '}
      <br />
      O prazo previsto de entrega dos novos
      Rastreadores é até o dia
      {' '}
      <TextBold display="inline">09/09/2020.</TextBold>
      <br />
      Os detalhes da contratação forão enviados para seu e-mail.
    </Paragraph>
    <div
      style={{
        border: '1px solid #E5E5E5',
        margin: '20px auto',
        padding: '20px',
        borderRadius: '10px',
      }}
    >
      <Paragraph
        fontSize="16px"
        color={theme.colors.text.default}
      >
        Os dados do seu contrato será atualizado somente após a entrega dos novos Rastreadores.
      </Paragraph>
    </div>
  </>
);

export default function InvoiceWizard({
  navigateBack: parentNavigateBack,
  navigateForward: parentNavigateForward,
  currentStep: parentCurrentStep,
}) {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { contractInfo } = useSelector((state) => state.subscription);
  const { contract } = contractInfo || {};

  const onCancel = () => {
    parentNavigateBack();
  };

  const onCompleted = (values) => {
    parentNavigateForward(values);
  };

  const onValuesChanged = ({ values, currentStep }) => {
    if (values) {
      const { childStep, formValues } = values;
      dispatch(
        changeStep({
          step: 'contractInfo',
          currentStep,
          parentCurrentStep,
          [childStep]: formValues,
        }),
      );
    }
  };

  function onSubmit({ history }) {
    return () => history.push('/member');
  }

  const steps = [
    {
      content: withRouter((props) => (
        <>
          <Completed
            {...props}
            initialValues={contract}
            onCancel={() => onCancel(props)}
            onSubmit={onSubmit({ ...props, childStep: 'completed' })}
            title="Pedido recebido com sucesso!"
            content={<CompletedRequestContent {...props} />}
          />
          <ActionPortal>
            <ButtonsHolder justifyContent="flex-end">
              <Button
                textConfig={{
                  color: 'white',
                  fontSize: '13px',
                  textAlign: 'center',
                }}
                backgroundColor={theme.colors.primary}
                width="auto"
                padding="10px"
                title="Acessar Contele Rastreador"
                onClick={onSubmit(props)}
              />
            </ButtonsHolder>
          </ActionPortal>
        </>
      )),
    },
  ];

  return withWizard({
    steps,
    theme,
    onValuesChanged,
    onCompleted,
  })(({ children }) => <StepContentWrapper>{children}</StepContentWrapper>);
}

CompletedRequestContent.propTypes = {
  theme: ThemePropType.isRequired,
};
