import styled from 'styled-components';

const linkDefault = styled.a((props) => ({
  fontFamily: `"${props.fontFamily || 'Roboto'}", sans-serif`,
  margin: props.margin || '0px',
  fontSize: props.fontSize || '13px',
  lineHeight: props.lineHeight || '18px',
  fontWeight: props.fontWeight || 'normal',
  transition: 'all 0.2s ease-out',
  color: props.theme?.colors.primary || 'blue',

  // stylelint-disable-next-line
  '&:hover': props.hover && {
    ...props.hover,
  },

  ...props,
}));

export { linkDefault as LinkDefault };
