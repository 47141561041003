import React from 'react';
import { Controller } from 'react-hook-form';
import SelectInput from 'react-select';

import { ControlledSelectInputPropType } from 'components/propTypes';

import { Text } from 'components';

import { getSelectStyle } from './style';

export default function Select(props) {
  const {
    name,
    rules,
    defaultValue,
    options,
    control,
    errors,
    placeholder,
    isClearable,
    blurInputOnSelect,
    onChange,
    disabled
  } = props;

  const hasErrors = !!errors[name];

  const onSelectChange = (e, controlledProps) => {
    controlledProps.onChange(e);
    if (onChange) { onChange(e); }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={(controlledProps) => (
        <div>
          <SelectInput
            {...controlledProps}
            isClearable={isClearable}
            defaultValue={defaultValue}
            blurInputOnSelect={blurInputOnSelect}
            styles={getSelectStyle(hasErrors)}
            placeholder={placeholder}
            options={options}
            onChange={(e) => onSelectChange(e, controlledProps)}
            isDisabled={disabled}
          />
          <Text
            fontSize={12}
            color={'#E65845'}
            style={{ marginTop: '8px' }}
          >
            {errors[name]?.message}
          </Text>
        </div>
      )}
    />
  );
}

Select.propTypes = ControlledSelectInputPropType;
