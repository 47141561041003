const getUrlsByRecognizedLocationHost = ({ host }) => {
    const urls = {
        "cgv-prd-web-contract-frontend.contele.io": {
            BILLING_API_URL: "https://cg-static-prd-billing-api.contele.io",
            TOKEN_VALIDATION_API_URL: "https://cgv-prd-api.contele.io/user/v1/?limit=1&offset=0&organization_id=878&status=1",
            WEB_SYSTEM_URL: "https://app.contelerastreador.com.br",
            WEB_BILLING_URL: "https://cgv-prd-web-contract-frontend.contele.io",
            N8N_GV_ENDPOINTS: "https://prd-api.contele.io/contele_rastreador/v1"
        },
        "cgv-stg-web-contract-frontend.contele.io": {
            BILLING_API_URL: "https://cg-static-stg-billing-api.contele.io",
            TOKEN_VALIDATION_API_URL: "https://tmp-cgv-stg-api.contele.io/user/v1/?limit=1&offset=0&organization_id=878&status=1",
            WEB_SYSTEM_URL: "https://cgv-stg-web-frontend.contele.io",
            WEB_BILLING_URL: "https://cgv-stg-web-contract-frontend.contele.io",
            N8N_GV_ENDPOINTS: "https://stg-api.contele.io/contele_rastreador/v1"
        }
    };

    const localhost_envs = urls["cgv-stg-web-contract-frontend.contele.io"];

    return urls[host] || localhost_envs;
};

export const { 
    BILLING_API_URL,
    TOKEN_VALIDATION_API_URL,
    WEB_SYSTEM_URL,
    WEB_BILLING_URL,
    N8N_GV_ENDPOINTS
} = getUrlsByRecognizedLocationHost({ host: window.location.host });