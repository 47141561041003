/* eslint-disable react/prop-types */
import React from 'react';

import { Icon } from '../../icon';
import { Text } from '../../text';
import LoadingSpin from '../../loadingSpin';

import { ButtonDefault } from './style';

export default function Button({
  title = '',
  onClick,
  form,
  hasIcon = false,
  iconConfig,
  textConfig,
  loading = false,
  disabled = false,
  hasLoad,
  ...options
}) {
  return (
    <ButtonDefault
      onClick={onClick}
      form={form}
      disabled={loading || disabled}
      {...options}
    >
      {hasIcon && <Icon {...iconConfig} />}
      {hasLoad && (
        <LoadingSpin
          show={loading}
          weight="5px"
          size="20px"
          position="absolute"
          left="10px"
          backColor="#1a2454"
          frontColor="transparent"
        />
      )}
      <Text
        color="#fff"
        cursor="pointer"
        {...textConfig}
      >
        {loading ? 'Carregando...' : title}
      </Text>
    </ButtonDefault>
  );
}
