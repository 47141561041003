import React from 'react';
import StylePropType from 'react-style-proptype';

import { Contract, Text } from 'components';
import { ThemePropType } from 'assets/styles/theme';

export default function ContractDetails(props) {
  const { theme, contractStyle } = props;

  return (
    <>
      <Text
        fontSize="16px"
        fontWeight="bold"
        margin="24px 0"
        theme={theme}
      >
        Fatura após a Alteração
      </Text>
      <div style={contractStyle}>
        <Contract
          withDiscount
          theme={theme}
          style={contractStyle}
          contract={{
            details: [
              {
                qty: 20,
                group: 'Rastreadores',
                unitValue: 'R$ 60,00',
                totalValue: 'R$ 1.580/Mês',
                subRows: [
                  {
                    qty: 10,
                    group: 'Veículos leves',
                    unitValue: '',
                    totalValue: '',
                  },
                  {
                    qty: 5,
                    group: 'Motos',
                    unitValue: '',
                    totalValue: '',
                  },
                  {
                    qty: 5,
                    group: 'Veículos pesados',
                    unitValue: '',
                    totalValue: '',
                  },
                  {
                    qty: 0,
                    group: 'Máquinas',
                    unitValue: '',
                    totalValue: '',
                  },
                ],
              },
              {
                qty: 4,
                group: 'Bloqueios',
                unitValue: 'R$ 4,00',
                totalValue: 'R$ 12/Mês',
              },
              {
                qty: 20,
                group: 'App Contele Driver',
                unitValue: 'R$ 5,00',
                totalValue: 'R$ 100/Mês',
              },
            ],
          }}
        />
      </div>
      <Text
        fontSize="16px"
        fontWeight="bold"
        margin="24px 0"
        theme={theme}
      >
        Fatura antes a Alteração
      </Text>
      <div style={contractStyle}>
        <Contract
          withDiscount
          theme={theme}
          style={contractStyle}
          contract={{
            details: [
              {
                qty: 20,
                group: 'Rastreadores',
                unitValue: 'R$ 60,00',
                totalValue: 'R$ 1.580/Mês',
                subRows: [
                  {
                    qty: 10,
                    group: 'Veículos leves',
                    unitValue: '',
                    totalValue: '',
                  },
                  {
                    qty: 5,
                    group: 'Motos',
                    unitValue: '',
                    totalValue: '',
                  },
                  {
                    qty: 5,
                    group: 'Veículos pesados',
                    unitValue: '',
                    totalValue: '',
                  },
                  {
                    qty: 0,
                    group: 'Máquinas',
                    unitValue: '',
                    totalValue: '',
                  },
                ],
              },
              {
                qty: 4,
                group: 'Bloqueios',
                unitValue: 'R$ 4,00',
                totalValue: 'R$ 12/Mês',
              },
              {
                qty: 20,
                group: 'App Contele Driver',
                unitValue: 'R$ 5,00',
                totalValue: 'R$ 100/Mês',
              },
            ],
          }}
        />
      </div>
    </>
  );
}

ContractDetails.defaultProps = {
  contractStyle: {},
};

ContractDetails.propTypes = {
  theme: ThemePropType.isRequired,
  contractStyle: StylePropType,
};
