import React from 'react';
import PropTypes from 'prop-types';

import { StepList } from './style';

export default function StepHeader({ children, ...props }) {
  return (
    <nav {...props}>
      <StepList {...props}>{children}</StepList>
    </nav>
  );
}

StepHeader.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};
