import { ACTION_TYPES } from './actionTypes';

export function changeStep(values) {
  return {
    type: ACTION_TYPES.CHANGE_STEP,
    payload: values,
  };
}

export function setLoading(isLoading) {
  return {
    type: ACTION_TYPES.LOADING,
    payload: {
      isLoading,
    },
  };
}

export function setOrganization(organization) {
  return {
    type: ACTION_TYPES.SET_ORGANIZATION,
    payload: {
      organization,
    },
  };
}

const api = {
  post() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 2000);
    });
  },
};

export const fetchAddressByZipcode = (data) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    await api.post(data);
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setLoading(false));
  }
};
