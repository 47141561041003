const consultants = [
    {
        id: 'default',
        name: 'Contratei diretamento pelo site',
        email: 'contele@contele.com',
        phone: '+5513999999999'
    },
    {
        id: '4447438',
        name: 'Thiago',
        email: 'thiago.andrade@contele.com.br',
        phone: '+5511981965590'
    },
    {
        id: '9771097',
        name: 'Gustavo',
        email: 'gustavo.nogueira@contele.com.br',
        phone: '+5511958624740'
    },
    {
        id: '6186902',
        name: 'Sheila Barros',
        email: 'sheila.barros@contele.com.br',
        phone: '+5513997036505'
    },
    {
        id: '11630177',
        name: 'Fabiano Nogueira',
        email: 'fabiano.nogueira@contele.com.br',
        phone: '+5513991027186'
    },
    {
        id: '12615204',
        name: 'Felipe',
        email: 'felipe@contele.io',
        phone: '+5511996900888'
    },
];

export { consultants };