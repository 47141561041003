import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { changeStep } from 'store/modules';
import { StepContentWrapper } from 'components';
import { withWizard } from 'containers';

import {
  createSubscription,
} from 'services/api';
import { parseDataToApi } from 'utils/parseDataToApi';

import { AUTH_TYPES } from 'constants/authTypesName';

import Payment from './payment';
import { get_token_from_local_storage } from 'utils/getTokenFromStorage';

export default function SolutionWizard({
  navigateBack: parentNavigateBack,
  complete: parentNavigateForward,
  currentStep: parentCurrentStep,
}) {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.subscription);
  const { contract } = subscription.paymentInfo || {};
  const onCancel = () => {
    parentNavigateBack();
  };

  const onCompleted = async () => {
    parentNavigateForward();
  };

  const onConfirmSubscription = async ({ paymentMethod, creditCard }) => {
    const { basicInfo, solutionInfo, contractInfo } = subscription;

    const { consultant = {} } = contractInfo || {};
    const { confirmation = {} } = solutionInfo || {};

    const validConsultant = consultant.consultantId || 'default';
    const validDiscountCoupon = confirmation.discountCoupon || '';

    const subscriptionData = parseDataToApi({
      ...basicInfo,
      ...solutionInfo,
      consultantId: validConsultant,
      discountCoupon: validDiscountCoupon,
      paymentMethod,
      creditCardInfo: creditCard
    });

    const tokenFromStorage = localStorage.getItem(AUTH_TYPES.STORAGE_AUTH_PARAM_NAME) || "";

    const authToken = JSON.parse(tokenFromStorage);

    await createSubscription({ subscriptionData, authToken });
  };

  const onValuesChanged = ({ values, currentStep }) => {
    if (values) {
      const { childStep, formValues } = values;
      dispatch(
        changeStep({
          step: 'paymentInfo',
          currentStep,
          parentCurrentStep,
          [childStep]: formValues,
        }),
      );
    }
  };

  function onSubmit({ navigateForward, childStep }) {
    return (values) => navigateForward({ childStep, formValues: values });
  }

  const steps = [
    {
      content: (props) => (
        <Payment
          {...props}
          initialValues={contract}
          onCancel={() => onCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'payment' })}
          onConfirmSubscription={onConfirmSubscription}
        />
      ),
    },
  ];

  return withWizard({
    steps,
    theme,
    onValuesChanged,
    onCompleted,
  })(({ children }) => <StepContentWrapper>{children}</StepContentWrapper>);
}
