/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

import { Header, PageWrapper } from 'components';

import { DefaultContent } from './style';

// eslint-disable-next-line react/prop-types
export default function ContentWithHeader({ children, navigation, callToActionText }) {
  return (
    <PageWrapper>
      <Header callToActionText={callToActionText}>{navigation}</Header>
      <DefaultContent>{children}</DefaultContent>
    </PageWrapper>
  );
}
