import React from 'react';
import PropTypes from 'prop-types';

import { LinkDefault } from './style';

export function Link({ href = '', ...options }) {
  return (
    <LinkDefault
      {...options}
      rel="noopener noreferrer"
      href={href}
    >
      {options.children}
    </LinkDefault>
  );
}

Link.defaultProps = {
  href: '',
};

Link.propTypes = {
  href: PropTypes.string,
};
