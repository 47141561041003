import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionPortal, Col, DefaultActionButtons, Row, Step, Switch,
} from 'components';

import { StepPropTypes } from '../../constants/propTypes';

import AddressForm from '../forms/address';

export default function DeliveryAddress(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { address, deliveryAddress } = {},
  } = props;

  const {
    control, errors, formState, handleSubmit, register, watch, setValue, trigger
  } = useForm({
    mode: 'all',
    defaultValues: deliveryAddress,
  });

  const { isValid } = formState;
  const useSameAddress = watch('useSameAddress');

  const [sameAddressSwitch, setSameAddressSwitch] = useState(useSameAddress);

  const changeSameAddressSwitch = () => {
    const addressSwitchNewState = !sameAddressSwitch;

    if(addressSwitchNewState) {
      Object.entries(address).forEach(([k, v]) => setValue(k, v));
      trigger();
    };

    setSameAddressSwitch(addressSwitchNewState);
  };

  const submitForm = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <Step title="Endereços de Entrega">
      <form onKeyPress={e => e.key === 'Enter' && submitForm(e)}>
        <Row style={{ marginBottom: '24px' }}>
          <Col>
            <Switch
              inline
              id="useSameAddress"
              name="useSameAddress"
              label="Usar o mesmo endereço da Matriz"
              innerRef={register}
              checked={sameAddressSwitch}
              onClick={changeSameAddressSwitch}
            />
          </Col>
        </Row>
        <AddressForm
          errors={errors}
          register={register}
          control={control}
          setValue={setValue}
          trigger={trigger}
          disableFields={useSameAddress}
        />
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </form>
    </Step>
  );
}

DeliveryAddress.propTypes = StepPropTypes;
