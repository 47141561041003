import { subscriptionItems } from "constants/subscriptionItems";
import { consultants } from "constants/consultants";

export function parseDataToApi({
    gettingStarted,
    address: companyAddress,
    deliveryAddress,
    contactInfo,
    responsible,
    consultantId,
    vehicleManager,
    blocker,
    plugAndPlay,
    expirationDate: billingExpirationDate = {},
    discountCoupon,
    paymentMethod,
    creditCardInfo
}) {
    const hasNeededValues = [
        typeof gettingStarted === 'object',
        typeof billingExpirationDate === 'object',
        typeof responsible === 'object',
        typeof companyAddress === 'object',
        typeof deliveryAddress === 'object',
        typeof contactInfo === 'object',
        typeof vehicleManager === 'object',
        typeof blocker === 'object',
        typeof plugAndPlay === 'object',
    ].every(Boolean);

    if(!hasNeededValues){
        throw new Error('Missing/Invalid params');
    };

    const { expirationDate = 5 } = billingExpirationDate;

    const contele_customer = makeConteleCustomer({ 
        gettingStarted, 
        responsible, 
        consultantId,
        contactInfo, 
        deliveryAddress, 
        companyAddress 
    });

    const contele_subscription = makeConteleSubscription({
        vehicleManager,
        blocker,
        plugAndPlay,
        discountCoupon,
        expirationDate,
        paymentMethod
    });

    const contele_subscription_payload = {
        contele_customer,
        contele_subscription,
    };

    const paymentMethodIsCard = !!paymentMethod && paymentMethod === 'world_credit_card';

    if(paymentMethodIsCard && creditCardInfo){
        const [cardExpMonth, cardExpYear] = creditCardInfo.expirationDate.split('/');

        contele_subscription_payload.credit_card_to_pay = {
            card_number: creditCardInfo.cardNumber,
            holder_name: creditCardInfo.cardHolder,
            card_expiration_year: Number(cardExpYear),
            card_expiration_month: Number(cardExpMonth),
            card_cvv: creditCardInfo.cvv
        };
    };

    return contele_subscription_payload;
};

const makeConteleCustomer = ({ 
    gettingStarted, 
    responsible, 
    consultantId,
    contactInfo, 
    deliveryAddress,
    companyAddress 
}) => {
    const { document } = gettingStarted;

    const documentIsCpf = document && document.length <= 11;

    const documentType = documentIsCpf ? 'brasil_cpf' : 'brasil_cnpj';

    const { 
        company_name, 
        responsible: personalResponsible, 
        responsibleDocument 
    } = responsible;

    const people = makeSubscriptionPeoples({ 
        contactInfo, 
        consultantId, 
        responsibleDocument, 
        personalResponsible 
    });

    const addresses = makeSubscriptionAddress({ deliveryAddress, companyAddress });

    return {
        document_number: document,
        document_type: documentType,
        name: company_name || personalResponsible,
        people,
        addresses
    };
};

const makeConteleSubscription = ({
    vehicleManager,
    blocker,
    plugAndPlay,
    discountCoupon,
    expirationDate,
    paymentMethod
}) => {
    const hasBlockers = blocker && blocker.useBlocker;
    const hasPlugAndPlay = plugAndPlay && plugAndPlay.usePlugAndPlay;

    const itemsList = [];

    const trackersQuantity = Object.values(vehicleManager).reduce((acc, value) => {
        const newValue = acc + value;

        return newValue;
    }, 0);

    const trackersSpecifications = {
        cgv_tracker_device_unit_heavy_vehicle: vehicleManager.heavyVehiclesCount,
        cgv_tracker_device_unit_light_vehicle: vehicleManager.lightweightVehiclesCount,
        cgv_tracker_device_unit_machine: vehicleManager.machineCount,
        cgv_tracker_device_unit_motorcycle: vehicleManager.motorbikeCount
    };

    if(hasPlugAndPlay){
        const { vehicleCount: plugAndPlayQuantity } = plugAndPlay;

        trackersSpecifications.cgv_tracker_feature_unit_plug_and_play = Number(plugAndPlayQuantity);

        itemsList.push(['PLUGANDPLAY', plugAndPlayQuantity]);
    };

    itemsList.push(['TRACKERS', trackersQuantity, trackersSpecifications]);

    if(hasBlockers){
        const { vehicleCount: blockerSQuantity } = blocker;

        itemsList.push(['BLOCKERS', blockerSQuantity]);
    };

    const items = makeSubscriptionItemByType({ items: itemsList });

    const billing_discounts = !!discountCoupon ? [{ code: discountCoupon }] : [];

    const payment_method = paymentMethod || 'world_bank_slip';

    return {
        items,
        billing_due_day_of_month: Number(expirationDate),
        billing_currency: 'BRL',
        billing_frequency: 'every_one_months',
        billing_discounts,
        payment_method
    };
};

const makeSubscriptionPeoples = ({ contactInfo, consultantId, responsibleDocument, personalResponsible }) => {

    const managerInfo = {
        name: contactInfo.managerName,
        email: contactInfo.managerEmail,
        phone_number: contactInfo.managerPhone,
        phone_number_type: 'mobile',
        role: 'system_admin'
    };

    const financialManagerInfo = contactInfo.useManagerInfo ? {
        ...managerInfo,
        role: 'company_financial'
    } : {
        name: contactInfo.financeManagerName,
        email: contactInfo.financeManagerEmail,
        phone_number: contactInfo.financeManagerPhone,
        phone_number_type: 'mobile',
        role: 'company_financial'
    };

    const companyLegalRepresentative = {
        name: personalResponsible,
        document_number: responsibleDocument,
        document_type: 'brasil_cpf',
        role: 'company_legal_representative'
    };

    const peoples = [managerInfo, financialManagerInfo, companyLegalRepresentative];

    if(!!consultantId){
        const selectedConsultant = consultants.find(consultant => consultant.id === consultantId);

        const consultantInfo = {
            name: selectedConsultant.name,
            email: selectedConsultant.email,
            phone_number: selectedConsultant.phone,
            phone_number_type: 'mobile',
            role: 'consultant'
        };

        peoples.push(consultantInfo);
    };

    return peoples;
};

const makeSubscriptionAddress = ({ companyAddress, deliveryAddress }) => {
    const { value: companyCity } = companyAddress.city || {};
    const { value: companyState } = companyAddress.state || {};

    const { value: deliveryCity } = deliveryAddress.city || {};
    const { value: deliveryState } = deliveryAddress.state || {};
    
    const company_address = {
        type: 'billing',
        phone_number: companyAddress.landline,
        street: companyAddress.streetAddress,
        number: companyAddress.number,
        additional_details: companyAddress.a,
        zipcode: companyAddress.zipCode,
        neighborhood: companyAddress.neighborhood,
        city: companyCity,
        state: companyState,
        country: 'BR'
    };

    const delivery_address = deliveryAddress.useSameAddress ? {
        ...company_address,
        type: 'other'
    } : {
        type: 'other',
        phone_number: companyAddress.landline,
        street: deliveryAddress.streetAddress,
        number: deliveryAddress.number,
        additional_details: deliveryAddress.a,
        zipcode: deliveryAddress.zipCode,
        neighborhood: deliveryAddress.neighborhood,
        city: deliveryCity,
        state: deliveryState,
        country: 'BR'
    };
    
    return [company_address, delivery_address];
};

const makeSubscriptionItemByType = ({ items }) => {
    const itemByTypeId = items.map(item => {
        const [itemType, itemQuatity, itemSpecification] = item || [];

        const itemTypeId = subscriptionItems[itemType];

        if(!itemTypeId){
            return false;
        };

        const formattedItem = {
            contele_product_id: itemTypeId,
            quantity: Number(itemQuatity)
        };

        if(itemSpecification){
            formattedItem.specification = itemSpecification;
        };

        return formattedItem;
    });

    const hasAllSearchedItems = itemByTypeId.every(Boolean);

    if(!hasAllSearchedItems){
        throw new Error('Missing product');
    };

    return itemByTypeId;
};