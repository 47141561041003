import { combineReducers } from 'redux';

import subscription from './subscription/reducer';

const allReducers = combineReducers({
  subscription,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    localStorage.clear();
    return allReducers(undefined, action);
  }

  return allReducers(state, action);
};

export default rootReducer;
