import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import Footer from '../footer';

class ActionPortal extends React.Component {
  el = document.createElement('div');

  componentDidMount() {
    // nav element is selected here by id
    this.targetEl = document.getElementById('actions-portal');
    this.targetEl.appendChild(this.el);
  }

  componentWillUnmount() {
    this.targetEl.removeChild(this.el);
  }

  render() {
    const { children, ...actionProps } = this.props;
    return createPortal(<Footer {...actionProps}>{children}</Footer>, this.el);
  }
}

ActionPortal.defaultProps = {
  children: null,
};

ActionPortal.propTypes = {
  children: PropTypes.node,
};

export default ActionPortal;
