import styled from 'styled-components';
import {
  DropdownMenu as DropdownMenuComponent,
  DropdownToggle as DropdownToggleComponent,
  Table,
} from 'reactstrap';

export const DropdownWrapper = styled.div`
  margin-bottom: 14px;
`;

export const Divider = styled.hr((props) => ({
  width: '100%',
  borderTopWidth: props.width || '2px',
  margin: props.margin || '0 0 23px',
  borderColor: props.color,

  [`@media${props.theme.devices.laptop}`]: {
    display: 'none',
  },
}));

export const DropdownToggle = styled(DropdownToggleComponent)(() => ({
  width: '216px',
  fontSize: '16px',
  cursor: 'default !important',
}));

export const DropdownMenu = styled(DropdownMenuComponent)(() => ({
  width: '300px',
  padding: '20px',
  right: 0,
}));

export const DropdownItem = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  fontSize: '14px',
  marginTop: '10px',

  '&:first-child': {
    margin: 0,
  },
  'span:nth-child(1)': {
    width: '46%',
  },
  'span:nth-child(2)': {
    width: '27%',
  },
  'span:nth-child(3)': {
    width: '27%',
    textAlign: 'right',
  },
}));

export const DashboardTableFooter = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const TablePricingSummary = styled.div(() => ({
  fontSize: '18px',
}));

export const TableHeader = styled.th(() => ({
  fontSize: '16px',
  color: '#444',
  fontWeight: 'bold',
  padding: '0 5px 10px !important',
}));

export const TableColumn = styled.td(() => ({
  fontSize: '16px',
  color: '#444',
  padding: '12px 5px 6px !important',
  verticalAlign: 'middle !important',
}));

export const TableColumnChildren = styled.td(() => ({
  fontSize: '12px',
  color: '#888',
  padding: '4px 5px !important',
}));

export const ContractTable = styled(Table)(() => ({
  margin: 0,
  'thead th': {
    border: 'none',

    '&:last-of-type': {
      textAlign: 'right',
    },
  },

  tr: {
    borderTop: 'none !important',
  },

  'tbody td': {
    button: {
      maxHeight: '12px',
    },

    '&:last-of-type': {
      textAlign: 'right',
    },
  },
}));
