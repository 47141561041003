import React, { useState, useContext, createContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';

import { AUTH_TYPES } from 'constants/authTypesName';
import { checkValidTokenFromStorageOrUrl } from './tokenValidation';

import { getSubscription } from 'services/api';

import { WEB_SYSTEM_URL } from 'constants/environments';

const authContext = createContext();

const useAuth = () => {
    return useContext(authContext);
};

const useProvideAuth = () => {
    const history = useHistory();

    const { location } = history;

    const queryParams = new URLSearchParams(location.search);

    const [authorization, setAuthorization] = useState(false);

    const tryAuthorize = async () => {
        const isAuth = await checkValidTokenFromStorageOrUrl({ location, storageName: AUTH_TYPES.STORAGE_AUTH_PARAM_NAME });

        queryParams.delete(AUTH_TYPES.URL_AUTH_PARAM_NAME);
        const queryParamsWithoutToken = `?${queryParams.toString()}`;

        const subscription = await getSubscription();

        const hasOrganization = subscription && !!subscription.best_subscription_id;

        const authParams = {
            isAuth,
            hasOrganization
        };

        if (!authParams.isAuth) {
            return window.open(`${WEB_SYSTEM_URL}/criar-nova-conta${queryParamsWithoutToken}`, "_self");
        };

        const redirectPath = authParams.hasOrganization ? '/member' : '/subscription'

        setAuthorization(authParams);
        
        history.push({ pathname: redirectPath, search: queryParamsWithoutToken, state: { from: location } })
    };

    return {
        authorization,
        tryAuthorize,
    };
};

const PrivateRoute = ({ children, ...rest }) => {
    const { authorization } = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
            authorization.isAuth && authorization.hasOrganization ?
                    (
                        children
                    )
                    :
                    (
                        <Redirect
                            to={{
                                pathname: "/auth",
                                state: { from: location },
                                search: location.search
                            }}
                        />
                    )
            }
        />
    );
};

const WithoutOrgPrivateRoute = ({ children, ...rest }) => {
    const { authorization } = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
            authorization.isAuth ?
                    (
                        children
                    )
                    :
                    (
                        <Redirect
                            to={{
                                pathname: "/auth",
                                state: { from: location },
                                search: location.search
                            }}
                        />
                    )
            }
        />
    );
};

const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
};

export { PrivateRoute, WithoutOrgPrivateRoute, ProvideAuth, useAuth as AuthContext};
