import styled from 'styled-components';

export const LogoImageWrapper = styled.div((props) => ({
  width: '186px',
  margin: '0 0 14px',

  '& img': {
    width: '100%',
  },

  [`@media${props.theme.devices.laptop}`]: {
    margin: '0 0 16px',
    width: '135px',
  },
}));
