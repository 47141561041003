import styled from 'styled-components';

import { colors } from '../../assets/styles/theme';

const getTextColor = ({
  theme: { colors: { text: { default: defaultTextColor } = {} } = {} } = {},
  color: specificColor,
}) => specificColor || defaultTextColor || 'black';

const getParagraphTextColor = ({
  theme: { colors: { text: { paragraph: defaultParagraphColor } = {} } = {} } = {},
  color: specificColor,
}) => specificColor || defaultParagraphColor || 'black';

const getFontSize = ({
  theme: { text: { sizes: { normal: defaultFontSize } = {} } = {} } = {},
  fontSize: specificFontSize,
}) => specificFontSize || defaultFontSize || '1em';

const heading = styled.h1(({ children, ...props }) => ({
  fontFamily: `"${props.fontFamily || 'Roboto'}", sans-serif`,
  margin: props.margin || '0px',
  color: getTextColor(props),
  fontSize: props.theme.text.sizes.h1,
  lineHeight: props.lineHeight || 'normal',
  fontWeight: props.fontWeight || 'normal',
  cursor: props.cursor || 'normal',
  whiteSpace: props.whiteSpace || 'normal',
  letterSpacing: props.letterSpacing,
  display: props.display || 'block',
  ...props,
}));

const subheading = styled.h2((props) => ({
  fontFamily: `"${props.fontFamily || 'Roboto'}", sans-serif`,
  margin: props.margin || '0px',
  color: getTextColor(props),
  fontSize: props.theme.text.sizes.h2,
  lineHeight: props.lineHeight || 'normal',
  fontWeight: props.fontWeight || 'normal',
  cursor: props.cursor || 'normal',
  whiteSpace: props.whiteSpace || 'normal',
  letterSpacing: props.letterSpacing,
  display: props.display || 'block',
  ...props,
}));

const textDefault = styled.span((props) => ({
  fontFamily: `"${props.fontFamily || 'Roboto'}", sans-serif`,
  margin: props.margin || '0px',
  color: getTextColor(props),
  fontSize: getFontSize(props),
  lineHeight: props.lineHeight || 'normal',
  fontWeight: props.fontWeight || 'normal',
  cursor: props.cursor || 'normal',
  whiteSpace: props.whiteSpace || 'normal',
  letterSpacing: props.letterSpacing,
  display: props.display || 'block',
  // stylelint-disable-next-line
  '> a': props.withLink && {
    cursor: 'pointer',
    ...props.linkProps,
  },
  ...props,
}));

const boldText = styled(textDefault)((props) => ({
  fontWeight: 'bold',
  ...props,
}));

const label = styled(textDefault)((props) => ({
  color: colors.text.label,
  ...props,
}));

const paragraphDefault = styled.p(({ children, ...props }) => ({
  fontFamily: `"${props.fontFamily || 'Roboto'}", sans-serif`,
  margin: props.margin || '0px',
  color: getParagraphTextColor(props),
  fontSize: getFontSize(props),
  lineHeight: props.lineHeight || 'normal',
  fontWeight: props.fontWeight || 'normal',
  cursor: props.cursor || 'normal',
  whiteSpace: props.whiteSpace || 'normal',
  letterSpacing: props.letterSpacing,

  // stylelint-disable-next-line
  '> a': props.withLink && {
    cursor: 'pointer',
    ...props.linkProps,
  },
  ...props,
}));

export {
  textDefault as TextDefault,
  paragraphDefault as ParagraphDefault,
  boldText as TextBoldDefault,
  label as TextLabelDefault,
  heading as HeadingDefault,
  subheading as SubHeadingDefault,
};
