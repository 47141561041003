import React, { useState } from 'react';
import InputMask from 'react-input-mask';

import { Controller } from 'react-hook-form';

import { ControlledInputPropType } from '../propTypes';

import { Text } from 'components';

const masks = {
  cpf: {
    mask: '999.999.999-99',
    pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/i,
    message: 'Documento inválido',
    length: 11
  },
  cnpj: {
    mask: '99.999.999/9999-99',
    pattern: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/i,
    message: 'Documento inválido',
    length: 14
  },
};

export default function DocumentInput(props) {
  const {
    name,
    rules,
    format,
    defaultValue,
    options,
    control,
    errors,
    placeholder,
    ...otherProps
  } = props;
  const defaultValueGreatterThanCpf = defaultValue && defaultValue.length > masks.cpf.length;

  const currentFormat = defaultValueGreatterThanCpf ? 'cnpj' : 'cpf';

  const [corretFormat, setCorretFormat] = useState(currentFormat);

  const onInsertKeys = (event) => {
    const normalizedValue = normalizeValue(event.target.value);

    if (normalizedValue.length >= masks.cpf.length) {
      setCorretFormat('cnpj');
    };
  };

  const onRemoveKeys = (event) => {
    const normalizedValue = normalizeValue(event.target.value);

    if (normalizedValue.length <= masks.cpf.length) {
      setCorretFormat('cpf');
    };
  };

  const onPasteDocument = (event) => {
    const pastedValue = event?.clipboardData?.getData('text/plain') || '';

    const pastedValueGreatterThanCpf = pastedValue.length > masks.cpf.length;

    if (pastedValueGreatterThanCpf) {
      setCorretFormat('cnpj');
    } else {
      setCorretFormat('cpf');
    }
    event.target.value = pastedValue;
  };

  const normalizeValue = (value) => {
    return value.replace(/[_./-]/g, '');
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{
        ...rules,
      }}
      render={(propsRender) => (
        <div>
          <InputMask
            {...propsRender}
            placeholder={placeholder}
            mask={masks[corretFormat].mask}
            className={`form-control ${errors[name] && 'is-invalid'}`}
            onKeyDown={e => onInsertKeys(e)}
            onKeyUp={e => onRemoveKeys(e)}
            onPaste={e => onPasteDocument(e)}
            onChange={e => {
              const normalizedValue = normalizeValue(e.target.value);

              propsRender.onChange(normalizedValue);
            }}
          />
          <Text
            fontSize={12}
            color={'#E65845'}
            style={{ marginTop: '8px' }}
          >
            {errors[name]?.message}
          </Text>
        </div>
      )}
      {...otherProps}
    />
  );
}

DocumentInput.propTypes = ControlledInputPropType;
