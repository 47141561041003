import React from 'react';
import {
  bool, shape, string, func,
} from 'prop-types';

import ButtonWithIcon from '../buttonWithIcon';

import { ButtonsHolder } from './style';

export default function DefaultActionButtons({
  onCancel,
  onSubmit,
  isValid,
  backButtonIcon,
  nextButtonIcon,
  backButtonStyle,
  nextButtonStyle,
  ...otherProps
}) {
  return (
    <ButtonsHolder {...otherProps}>
      <ButtonWithIcon
        icon={backButtonIcon}
        {...backButtonStyle}
        onClick={() => onCancel()}
      />
      <ButtonWithIcon
        icon={nextButtonIcon}
        {...nextButtonStyle}
        disabled={!isValid}
        onClick={() => onSubmit()}
      />
    </ButtonsHolder>
  );
}

const ButtonStylePropType = shape({
  iconPosition: string,
  border: string,
  customBackgroundColor: string,
  customIconColor: string,
  customTextColor: string,
  title: string,
});

DefaultActionButtons.defaultProps = {
  backButtonStyle: {},
  nextButtonStyle: {},
  backButtonIcon: 'arrowLeft',
  nextButtonIcon: 'arrowRight',
};

DefaultActionButtons.propTypes = {
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  isValid: bool.isRequired,
  backButtonIcon: string,
  nextButtonIcon: string,
  backButtonStyle: ButtonStylePropType,
  nextButtonStyle: ButtonStylePropType,
};

export { ButtonsHolder } from './style';
