import React from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionPortal, DefaultActionButtons, Form, FormGroup, Step, Radio,
} from 'components';

import { StepPropTypes } from '../../../constants/propTypes';

export default function ReplacingSystem(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { replacingSystem } = {},
  } = props;

  const {
    errors, formState, handleSubmit, register,
  } = useForm({
    defaultValues: replacingSystem,
    mode: 'all',
  });

  const { isValid } = formState;

  return (
    <Step title="Você está mudando para um outro sistema?">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup tag="fieldset">
          <div style={{ marginBottom: '14px' }}>
            <Radio
              type="radio"
              name="replacing"
              value="5"
              id="YES"
              invalid={!!errors.replacing}
              innerRef={register({ required: true })}
              label="Sim"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Radio
              type="radio"
              name="replacing"
              value="10"
              id="NO"
              invalid={!!errors.replacing}
              innerRef={register({ required: true })}
              label="Não"
            />
          </div>
          <div style={{ marginBottom: '14px' }}>
            <Radio
              type="radio"
              name="replacing"
              value="NOT_SURE"
              id="notSure"
              invalid={!!errors.replacing}
              innerRef={register({ required: true })}
              label="Ainda não sei"
            />
          </div>
        </FormGroup>
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

ReplacingSystem.propTypes = StepPropTypes;
