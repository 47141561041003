import { ACTION_TYPES } from './actionTypes';
import { get_token_from_local_storage } from 'utils/getTokenFromStorage';

const INITIAL_STATE = {
  currentStep: 0,
  parentCurrentStep: 0,
  basicInfo: null,
  solutionInfo: null,
  contractInfo: null,
  paymentInfo: null,
  isLoading: true,
  organization: null,
};

const make_local_storage_key = () => {
  const {
    organization_id,
    userInfo: { 
      email 
    }
  } = get_token_from_local_storage();

  const has_info_to_make_storage_key = !!organization_id && !!email;

  if(!has_info_to_make_storage_key){
    return false;
  };

  const storage_key = `subscription:${organization_id}:${email}`;

  return storage_key;
};

const get_previous_subscription_state_from_local_storage = () => {
  const storage_key = make_local_storage_key();

  const subscription_on_local_storage = localStorage.getItem(storage_key);

  const has_previous_state = !!subscription_on_local_storage;

  if(!has_previous_state){
    return INITIAL_STATE;
  };

  const subscription_previous_state = JSON.parse(subscription_on_local_storage);

  return subscription_previous_state;
};

const alocate_subscription_state_in_local_storage = (state) => {
  const storage_key = make_local_storage_key();

  if(storage_key){
    localStorage.setItem(storage_key, JSON.stringify(state));
  };
};

export default function subscription(state = get_previous_subscription_state_from_local_storage(), action) {
  switch (action.type) {
    case ACTION_TYPES.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }
    case ACTION_TYPES.CHANGE_STEP: {
      const {
        step,
        currentStep: actionCurrentStep,
        parentCurrentStep: actionParentCurrentStep,
        ...values
      } = action.payload;

      const { parentCurrentStep, currentStep } = state;

      const new_step_state = {
        ...state,
        parentCurrentStep: actionParentCurrentStep || parentCurrentStep,
        currentStep: actionCurrentStep || currentStep,
        [step]: {
          ...(state[step] ?? {}),
          ...(values || {}),
        },
      };

      const is_the_first_step = new_step_state.currentStep === 0 && new_step_state.parentCurrentStep === 0;

      if(!is_the_first_step) {
        alocate_subscription_state_in_local_storage(new_step_state);
      };

      return new_step_state;
    }
    default: {
      return state;
    }
  }
}