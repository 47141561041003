import styled from 'styled-components';
import {
  Container,
  DropdownMenu as DropdownMenuComponent,
  DropdownToggle as DropdownToggleComponent,
} from 'reactstrap';

export const MenuList = styled.ol((props) => ({
  [`@media${props.theme.devices.laptop}`]: {
    display: 'flex',
    marginLeft: '8px',
  },
}));

export const MenuListItem = styled.li((props) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '24px',

  [`@media${props.theme.devices.laptop}`]: {
    marginBottom: '0',
    marginRight: '0.8rem',
  },
}));

function mobileActive(props) {
  return (!props.active && props.step > 0) || (!props.step && props.completed);
}

const getColor = (props) => {
  if (props.active) {
    return props.theme.colors.text.white;
  }
  if (props.completed) {
    return props.theme.colors.accent;
  }
  return props.theme.colors.darkerAccent;
};

export const MenuItemText = styled.span((props) => ({
  fontWeight: 'bold',
  fontSize: '16px',

  ' > a': {
    textDecoration: 'none',
    color: getColor(props),
  },

  [`@media${props.theme.devices.laptop}`]: {
    display: mobileActive(props) ? 'none' : 'inline',
    fontSize: '14px',
  },
}));

export const DashboardContent = styled(Container)((props) => ({
  margin: '0 auto',
  padding: '80px',
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',

  [`@media${props.theme.devices.laptop}`]: {
    padding: '40px 16px',
  },
}));

export const DropdownWrapper = styled.div`
  margin-bottom: 14px;
`;

export const DropdownToggle = styled(DropdownToggleComponent)(() => ({
  width: '216px',
  fontSize: '16px',
}));
export const DropdownMenu = styled(DropdownMenuComponent)(() => ({
  width: '300px',
  padding: '20px',
  right: 0,
}));

export const DropdownItem = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  fontSize: '14px',
  marginTop: '10px',

  '&:first-child': {
    margin: 0,
  },
  'span:nth-child(1)': {
    width: '46%',
  },
  'span:nth-child(2)': {
    width: '27%',
  },
  'span:nth-child(3)': {
    width: '27%',
    textAlign: 'right',
  },
}));

export const DashboardTableFooter = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const TablePricingSummary = styled.div(() => ({
  fontSize: '18px',
}));
