import React, { useState } from 'react';

import {
    IconButton,
    Text,
    Input,
    Button
} from '../../index';

import {
    DiscountCouponContainer,
    DiscountCouponContent
} from './styles';

export default function DiscountCouponInput({
    register,
    defaultCoupon = '',
    couponIsValid,
    onLoading,
    onApplyDiscountCoupon = () => {}
}) {
    const [showDiscountInput, setShowDiscountInput] = useState(!!defaultCoupon);
    const [appliedDiscountCoupon, setAppliedDiscountCoupon] = useState(!!defaultCoupon && couponIsValid);
    const [discountCoupons, setDiscountCoupons] = useState(defaultCoupon);

    const handleInsertCoupon = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData);

        const { discountCoupon: inputValue } = formProps || '';

        const hasDiscountCoupon = !!inputValue;

        if(hasDiscountCoupon){
            setDiscountCoupons(inputValue);
            onApplyDiscountCoupon(inputValue);
            setAppliedDiscountCoupon(true);
        }
    };

    const couponIsValidAndApplied = couponIsValid && appliedDiscountCoupon && !onLoading;

    return (
        <form onSubmit={handleInsertCoupon} style={{ paddingBottom: '1em' }}>
            {showDiscountInput ?
                <div>
                    <DiscountCouponContainer show={!couponIsValid || !appliedDiscountCoupon || onLoading}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Input
                                type="text"
                                name="discountCoupon"
                                innerRef={register()}
                                defaultValue={discountCoupons}
                                placeholder="Cupom de desconto"
                                maxLength="256"
                                disabled={onLoading}
                                style={{ 
                                    width: '180px', 
                                    marginRight: '8px', 
                                    border: `${couponIsValid ? '1px solid #E5E5E5' : '1px solid #E65845'}` 
                                }}
                            />
                            <Button
                                title={'Aplicar'}
                                type='submit'
                                disabled={onLoading}
                            />
                        </div>
                        {!couponIsValid &&
                            <div>
                                <Text
                                    fontSize={"10px"}
                                    color={"#E65845"}
                                    style={{ marginLeft: '5px', marginTop: '2px', width: '50%' }}
                                >
                                    Cupom inválido.
                                </Text>
                            </div>
                        }
                    </DiscountCouponContainer>
                    <DiscountCouponContainer show={couponIsValidAndApplied}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <DiscountCouponContent>
                                <IconButton
                                    icon="edit"
                                    customBackgroundColor="transparent"
                                    customIconColor={"#1D1B84"}
                                    border="none"
                                    style={{ outline: 'none' }}
                                    onClick={() => setAppliedDiscountCoupon(false)}
                                />
                                <Text
                                    fontSize={"15px"}
                                    color={"#41BA40"}
                                    fontWeight={"bold"}
                                    overflow={"hidden"}
                                    textOverflow={"ellipsis"}
                                    title={discountCoupons}
                                >
                                    {discountCoupons}
                                </Text>
                            </DiscountCouponContent>
                            <Button
                                title={'Aplicado'}
                                disabled={true}
                            />
                        </div>
                    </DiscountCouponContainer>
                </div>
                :
                <Button
                    title={'Cupom de Desconto'}
                    onClick={() => setShowDiscountInput(true)}
                    style={{ width: '100%', padding: '15px' }}
                />
            }
        </form>
    );
}
