import React, { memo, useContext } from 'react';
import { any, node, string } from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Container } from 'reactstrap';

import { Text } from '../text';

function Step({
  children, title, subtitle, style,
}) {
  const theme = useContext(ThemeContext);
  return (
    <>
      <Container
        fluid
        style={style}
      >
        {title && (
          <Text
            fontSize="22px"
            fontWeight="400"
            fontHeight="24px"
            whiteSpace="normal"
            margin="0 0 40px 0"
            theme={theme}
          >
            {title}
          </Text>
        )}
        {subtitle && (
          <Text
            fontSize="16px"
            color={theme.colors.text.paragraph}
            fontWeight="400"
            fontHeight="24px"
            whiteSpace="normal"
            margin="0 0 16px 0"
            theme={theme}
          >
            {subtitle}
          </Text>
        )}
        {children}
      </Container>
    </>
  );
}

Step.defaultProps = {
  style: null,
  title: null,
  subtitle: null,
};

Step.propTypes = {
  children: node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: any,
  title: string,
  subtitle: string,
};

export default memo(Step);
