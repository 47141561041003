import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { changeStep } from 'store/modules';
import { withWizard } from 'containers';

import Contract from './contract';
import Consultant from './consultant';
import { ContractWrapper } from './style';

export default function SolutionWizard({
  navigateBack: parentNavigateBack,
  navigateForward: parentNavigateForward,
  currentStep: parentCurrentStep,
}) {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { contractInfo } = useSelector((state) => state.subscription);
  const { contract, consultant } = contractInfo || {};

  const onCancel = () => {
    parentNavigateBack();
  };

  const onCompleted = (values) => {
    parentNavigateForward(values);
  };

  const onValuesChanged = ({ values, currentStep }) => {
    if (values) {
      const { childStep, formValues } = values;
      dispatch(
        changeStep({
          step: 'contractInfo',
          currentStep,
          parentCurrentStep,
          [childStep]: formValues,
        }),
      );
    }
  };

  function onSubmit({ navigateForward, childStep }) {
    return (values) => navigateForward({ childStep, formValues: values });
  }

  const steps = [
    {
      content: (props) => (
        <Contract
          {...props}
          initialValues={contract}
          onCancel={() => onCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'contract' })}
        />
      ),
    },
    {
      content: (props) => (
        <Consultant
          {...props}
          initialValues={consultant}
          onCancel={() => onCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'consultant' })}
        />
      ),
    },
  ];

  return withWizard({
    steps,
    theme,
    onValuesChanged,
    onCompleted,
  })(({ children }) => <ContractWrapper>{children}</ContractWrapper>);
}
