import styled from 'styled-components';

export const PageWrapperStyle = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  alignItems: 'stretch',
  background: props.theme.colors.background,
  height: '100vh',
  // maxWidth: '1024px',
  // margin: 'auto',

  [`@media${props.theme.devices.laptop}`]: {
    flexDirection: 'column',
  },
}));
