/* eslint-disable react/prop-types */

import React from 'react';

import { Icon } from '../../icon';

import { ButtonWithIconStyle } from './style';

// eslint-disable-next-line import/no-cycle

export default function IconButton({
  onClick,
  formId = '',
  icon,
  customIconColor = '#fff',
  customBackgroundColor = '#192379',
  disabled = false,
  ...option
}) {
  return (
    <ButtonWithIconStyle
      onClick={onClick}
      backgroundColor={disabled ? '#8a8b96' : customBackgroundColor}
      form={formId}
      width="auto"
      disabled={disabled}
      {...option}
    >
      <Icon
        icon={icon}
        width="14px"
        height="14px"
        color={customIconColor}
        {...option.iconOptions}
      />
    </ButtonWithIconStyle>
  );
}
