import styled from 'styled-components';
import { lighten } from 'polished';

import { Icon } from '../icon';

export const StepList = styled.ol((props) => ({
  [`@media${props.theme.devices.laptop}`]: {
    display: 'flex',
    marginLeft: '8px',
  },
}));

export const Item = styled.li((props) => ({
  alignItems: 'center',
  display: 'flex',
  marginBottom: '24px',

  [`@media${props.theme.devices.laptop}`]: {
    marginBottom: '0',
    marginRight: '0.8rem',
  },
}));

function mobileActive(props) {
  return (!props.active && props.step > 0) || (!props.step && props.completed);
}

const getColor = (props) => {
  if (props.active) {
    return props.theme.colors.text.white;
  }
  if (props.completed) {
    return props.theme.colors.accent;
  }
  return '#6B6B99';
};

export const StepText = styled.span((props) => ({
  color: getColor(props),
  fontSize: '16px',
  fontWeight: props.active || props.completed ? 'bold' : 'normal',

  [`@media${props.theme.devices.laptop}`]: {
    display: mobileActive(props) ? 'none' : 'inline',
    fontSize: '14px',
  },
}));

const getStepNumberBackgroundColor = ({
  theme, active, completed, disabled,
}) => {
  if (completed) return theme.colors.status.success;
  if (!disabled && active) return theme.colors.accent;
  return lighten(0.06, theme.colors.primary);
};

export const StepNumber = styled(StepText)((props) => ({
  alignItems: 'center',
  backgroundColor: getStepNumberBackgroundColor(props),
  borderRadius: '50%',
  color: props.theme.colors.text.white,
  display: 'flex',
  fontSize: '14px',
  fontWeight: 'bold',
  height: '24px',
  justifyContent: 'center',
  marginRight: '7px',
  textAlign: 'center',
  width: '24px',

  [`@media${props.theme.devices.laptop}`]: {
    marginRight: mobileActive(props) ? '0' : '7px',
  },
}));

export const StepIcon = styled(Icon)((props) => ({
  marginLeft: props.completed ? '-2px' : '0',
}));

export const StepContentWrapper = styled.section((props) => ({
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: '40px',

  [`@media${props.theme.devices.laptop}`]: {
    padding: '40px 16px',
  },
}));
