/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { any, array, func } from 'prop-types';
import { PaginationItem, PaginationLink } from 'reactstrap';
import {
  useTable,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from 'react-table';

import { ThemePropType } from 'assets/styles/theme';

import { Button, Input } from 'components';

import { StyledTable, StyledPagination, TableColumn } from './style';

// eslint-disable-next-line react/prop-types
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(() => {
    setGlobalFilter(value);
  }, 200);

  return (
    <Input
      value={value || ''}
      placeholder="Busque pelo veículo"
      onChange={(e) => {
        setValue(e.target.value || undefined);
        onChange();
      }}
      style={{ width: '33%' }}
    />
  );
}

export default function TrackerTable(props) {
  const {
    theme, trackers, style, onChange,
  } = props;

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
          : true;
      }),
    }),
    [],
  );

  const {
    setGlobalFilter,
    state: { selectedRowIds, globalFilter, pageIndex },
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns: useMemo(
        () => [
          {
            id: 'description',
            accessor: (row) => `${row.description} - ${row.licensePlate}`,
          },
        ],
        [],
      ),
      data: useMemo(() => trackers, [trackers]),
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'selection',
          /* eslint-disable react/prop-types */
          Cell: ({ row }) => (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                border={`1px solid ${theme.colors.text.label}`}
                textConfig={{
                  color: row.isSelected ? 'white' : theme.colors.text.label,
                  fontSize: '13px',
                  textAlign: 'center',
                }}
                backgroundColor={row.isSelected ? theme.colors.primary : 'transparent'}
                width="auto"
                padding="10px"
                title={row.isSelected ? 'Selecionado' : 'Selecionar'}
                onClick={() => row.toggleRowSelected()}
              />
            </div>
          ),
          /* eslint-enable react/prop-types */
        },
      ]);
    },
  );

  useEffect(() => {
    onChange(Object.keys(selectedRowIds || {}).map((index) => trackers[index]));
  }, [onChange, selectedRowIds, trackers]);

  return (
    <>
      <div style={{ margin: '16px 0' }}>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <div style={{ minHeight: '250px' }}>
        <StyledTable
          borderless
          responsive
          style={style}
          {...getTableProps()}
        >
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              /* eslint-disable react/prop-types */
              return (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <TableColumn {...cell.getCellProps({ width: '80%' })}>
                        {cell.render('Cell')}
                      </TableColumn>
                    ))}
                  </tr>
                </>
              );
              /* eslint-enable react/prop-types */
            })}
          </tbody>
        </StyledTable>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <StyledPagination>
          <PaginationItem>
            <PaginationLink
              first
              disabled={!canPreviousPage}
              onClick={() => gotoPage(0)}
            />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              previous
              disabled={!canPreviousPage}
              onClick={() => previousPage()}
            />
          </PaginationItem>
          {Array.from(pageOptions)
            .slice(
              Math.max(pageIndex - 3, 0),
              pageIndex + 3 > pageOptions.length ? pageOptions.length : pageIndex + 3,
            )
            .map((index) => (
              <PaginationItem active={index === pageIndex}>
                <PaginationLink
                  onClick={(e) => {
                    e.stopPropagation();
                    gotoPage(index);
                  }}
                >
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
            ))}

          <PaginationItem disabled={!canNextPage}>
            <PaginationLink
              next
              onClick={() => nextPage()}
            />
          </PaginationItem>
          <PaginationItem disabled={!canNextPage}>
            <PaginationLink
              last
              onClick={() => gotoPage(pageCount - 1)}
            />
          </PaginationItem>
        </StyledPagination>
      </div>
    </>
  );
}

TrackerTable.defaultProps = {
  style: null,
};

TrackerTable.propTypes = {
  style: any,
  theme: ThemePropType.isRequired,
  trackers: array.isRequired,
  onChange: func.isRequired,
};
