import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import React, { useContext } from 'react';

import { changeStep } from 'store/modules';
import { StepContentWrapper } from 'components';
import { withWizard } from 'containers';

import GettingStarted from './gettingStarted';
import Responsible from './responsible';
import ContactInfo from './contactInfo';
import Address from './address';
import DeliveryAddress from './deliveryAddress';

export default function BasicInfoWizard({
  currentStep: parentCurrentStep,
  navigateForward: parentNavigateForward,
  enable,
  reset,
  cancel,
}) {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { basicInfo } = useSelector((state) => state.subscription);
  const {
    gettingStarted, responsible, address, contactInfo, deliveryAddress,
  } = basicInfo || {};

  const onCancel = () => {
    enable(false);
    reset();
  };
  const navigateBackOnCancel = ({ navigateBack }) => () => {
    navigateBack();
  };

  const onCompleted = async (values) => {
    parentNavigateForward(values);
  };

  const onValuesChanged = ({ values, currentStep }) => {
    if (values) {
      const { childStep, formValues } = values;
      dispatch(
        changeStep({
          step: 'basicInfo',
          currentStep,
          parentCurrentStep,
          [childStep]: formValues,
        }),
      );
    }
  };

  function onSubmit({ navigateForward, childStep }) {
    return (values) => {
      navigateForward({ childStep, formValues: values });
    };
  }

  const steps = [

    {
      content: (props) => (
        <GettingStarted
          {...props}
          initialValues={gettingStarted}
          onCancel={onCancel}
          onSubmit={onSubmit({ ...props, childStep: 'gettingStarted' })}
        />
      ),
    },
    {
      content: (props) => (
        <Responsible
          {...props}
          initialValues={{responsible, gettingStarted}}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'responsible' })}
        />
      ),
    },
    {
      content: (props) => (
        <ContactInfo
          {...props}
          initialValues={contactInfo}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'contactInfo' })}
        />
      ),
    },
    {
      content: (props) => (
        <Address
          {...props}
          initialValues={address}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'address' })}
        />
      ),
    },
    {
      content: (props) => (
        <DeliveryAddress
          {...props}
          initialValues={{ address, deliveryAddress }}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'deliveryAddress' })}
        />
      ),
    },
  ];

  return withWizard({
    steps,
    cancel,
    theme,
    onCompleted,
    onValuesChanged,
  })(({ children }) => <StepContentWrapper>{children}</StepContentWrapper>);
}
