import React, { memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { node } from 'prop-types';

import {
  HeadingDefault,
  ParagraphDefault,
  SubHeadingDefault,
  TextBoldDefault,
  TextDefault,
  TextLabelDefault,
} from './style';

const withText = (Component) => memo(({ children, ...options }) => {
  const theme = useContext(ThemeContext);
  return (
    <Component
      {...options}
      theme={theme}
    >
      {children}
    </Component>
  );
});

export const Heading = withText(HeadingDefault);
export const SubHeading = withText(SubHeadingDefault);
export const Paragraph = withText(ParagraphDefault);
export const TextBold = withText(TextBoldDefault);
export const Label = withText(TextLabelDefault);
export const Text = withText(TextDefault);

Heading.propTypes = {
  children: node.isRequired,
};

SubHeading.propTypes = {
  children: node.isRequired,
};

Paragraph.propTypes = {
  children: node.isRequired,
};

TextBold.propTypes = {
  children: node.isRequired,
};

Label.propTypes = {
  children: node.isRequired,
};

Text.propTypes = {
  children: node.isRequired,
};
