import React from 'react';

import logoImage from '../../../assets/images/logo.svg';

import { LogoImageWrapper } from './style';

export default function Logo() {
  return (
    <LogoImageWrapper>
      <img
        src={logoImage}
        alt="company logo"
      />
    </LogoImageWrapper>
  );
}
