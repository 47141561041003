import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';

import { store } from './store';
import Routes from './routes';
import { theme } from './assets/styles/theme';
import { GlobalStyle } from './assets/styles/global';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <GlobalStyle />
          <Routes />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
          />
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
