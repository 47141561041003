import styled from 'styled-components';
import { Modal, ModalHeader } from 'reactstrap';

export const StyledModalHeader = styled(ModalHeader)(() => ({
  borderBottom: 'none',
}));

export const StyledModal = styled(Modal)(() => ({
  '.modal-content': {
    padding: '10px 24px',
  },
}));
