import React, { useContext, useState } from 'react';
import { string, oneOf, node } from 'prop-types';
import { ThemeContext } from 'styled-components';

import {
  ButtonWithIcon, Col, Container, Icon, Modal, Paragraph, Text, Footer
} from 'components';

import ReferralForm from './referralForm';
//import { ReferralWrapper } from './style';

import { WEB_SYSTEM_URL } from 'constants/environments';

export default function Completed(props) {
  const { title, content } = props;
  const theme = useContext(ThemeContext);
  const [isReferralFormOpen, setReferralFormOpen] = useState(false);

  return (
    <>
      <Container style={{ margin: 'auto', textAlign: 'center' }}>
        <Col xs="12">
          <Icon
            icon="solidCircleCheck"
            style={{ borderRadius: '50%', fontSize: '128px', margin: 'auto' }}
            width="128px"
            height="128px"
            color="green"
          />
        </Col>
        <Col xs="12">
          <Text
            color={theme.colors.text.default}
            fontSize="24px"
            fontWeight="400"
            whiteSpace="normal"
            margin="24px 0"
          >
            {title}
          </Text>
        </Col>
        <Col xs="12">
          {!content && (
            <Paragraph
              fontSize="16px"
              lineHeight="24px"
              maxWidth="565px"
              textAlign="center"
              margin="auto"
            >
              Aguarde, a sua contratação está em avaliação pelo Financeiro da Contele Rastreadores.
              Enviaremos um e-mail assim que a contratação for aprovada.
            </Paragraph>
          )}
          {content}
        </Col>
        {/* <Col>
          <ReferralWrapper>
            <Text fontSize="20px">Quanto mais você indicar, mais você ganha!</Text>
            <ButtonWithIcon
              customBackgroundColor={theme.colors.primary}
              customIconColor={theme.colors.text.white}
              customTextColor={theme.colors.text.white}
              iconPosition="left"
              icon="gift"
              title="Indique e Ganhe"
              onClick={() => setReferralFormOpen(true)}
              style={{ margin: '24px auto' }}
            />
          </ReferralWrapper>
        </Col> */}
      </Container>
      <Modal
        centered
        isOpen={isReferralFormOpen}
        setOpen={setReferralFormOpen}
        style={{ maxWidth: '400px' }}
      >
        <ReferralForm
          theme={theme}
          onSubmit={() => {
            setReferralFormOpen(false);
          }}
        />
      </Modal>
      <Footer padding="16px 24px">
        <ButtonWithIcon
          customBackgroundColor={theme.colors.status.success}
          icon="arrowRight"
          title="Acessar Contele Rastreador"
          onClick={() => {
            const pathAfterLogin = "/aproveitamento";
            const token = localStorage.getItem("auth_token");
            const queryParams = `pathAfterLogin=${pathAfterLogin}&token=${token}`;

            window.open(`${WEB_SYSTEM_URL}/login?${queryParams}`, "_self")
          }}
        />
      </Footer>
    </>
  );
}

Completed.defaultProps = {
  title: 'Contrato recebido com Sucesso!',
  content: null,
};

Completed.propTypes = {
  title: string,
  content: oneOf([node, string]),
};
