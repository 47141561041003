import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import React, { useContext } from 'react';

import { changeStep } from 'store/modules';
import { StepContentWrapper } from 'components';
import { withWizard } from 'containers';

import { VehicleManager, PlugAndPlay, Blocker } from '../steps';

import App from './app';
import ExpirationDate from './expirationDate';
import Confirmation from './confirmation';

export default function SolutionWizard({
  navigateBack: parentNavigateBack,
  navigateForward: parentNavigateForward,
  currentStep: parentCurrentStep,
}) {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { basicInfo, solutionInfo } = useSelector((state) => state.subscription);
  const {
    app, blocker, expirationDate, vehicleManager, plugAndPlay,
  } = solutionInfo || {};

  const onCancel = () => {
    parentNavigateBack();
  };

  const navigateBackOnCancel = ({ navigateBack }) => () => {
    navigateBack();
  };

  const onCompleted = (values) => {
    parentNavigateForward(values);
  };

  const onValuesChanged = ({ values, currentStep }) => {
    if (values) {
      const { childStep, formValues } = values;
      dispatch(
        changeStep({
          step: 'solutionInfo',
          currentStep,
          parentCurrentStep,
          [childStep]: formValues,
        }),
      );
    }
  };

  function onSubmit({ navigateForward, childStep }) {
    return (values) => navigateForward({ childStep, formValues: values });
  }

  const steps = [

    {
      content: (props) => (
        <VehicleManager
          {...props}
          initialValues={vehicleManager}
          onCancel={onCancel}
          onSubmit={onSubmit({ ...props, childStep: 'vehicleManager' })}
        />
      ),
    },
    {
      content: (props) => (
        <PlugAndPlay
          {...props}
          initialValues={{plugAndPlay, vehicleManager}}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'plugAndPlay' })}
        />
      ),
    },
    {
      content: (props) => (
        <Blocker
          {...props}
          initialValues={{blocker, plugAndPlay, vehicleManager}}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'blocker' })}
        />
      ),
    },
    {
      content: (props) => (
        <App
          {...props}
          initialValues={app}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'app' })}
        />
      ),
    },
    {
      content: (props) => (
        <ExpirationDate
          {...props}
          initialValues={expirationDate}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'expirationDate' })}
        />
      ),
    },
    {
      content: (props) => (
        <Confirmation
          {...props}
          initialValues={{ basicInfo, solutionInfo }}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'confirmation' })}
        />
      ),
    },
  ];
  
  return withWizard({
    steps,
    theme,
    onValuesChanged,
    onCompleted,
    parentCurrentStep,
  })(({ children }) => <StepContentWrapper>{children}</StepContentWrapper>);
}
