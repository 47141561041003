import styled from 'styled-components';

import { StepContentWrapper } from 'components';

export const ContractWrapper = styled(StepContentWrapper)((props) => ({
  padding: '40px',

  [`@media${props.theme.devices.laptop}`]: {
    padding: '40px 16px',
  },
}));

export const ButtonsHolder = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 24px',
}));
