import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionPortal, DefaultActionButtons, Form, FormGroup, Radio, Step,
} from 'components';

import { useSelector } from 'react-redux';
import { StepPropTypes } from '../../constants/propTypes';
import {
  listAvailableBillingDueDays
} from '../../services/api.js';

export default function ExpirationDate(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { expirationDate } = {},
  } = props;

  const {
    errors, formState, handleSubmit, register,
  } = useForm({
    mode: 'all',
    defaultValues: expirationDate,
  });

  const { isValid } = formState;


  const { basicInfo } = useSelector((state) => state.subscription);
  const [RadioOptions, setRadioOptions] = useState([<div />]);

  useEffect(() => {
    (async () => {
      const {
        address: {
          state: {
            value: billing_address_state = '*'
          } = {},
        } = {}
      } = basicInfo || {};
      const {
        available_billing_due_days
      } = await listAvailableBillingDueDays({
        billing_address_state,
        contele_product_id: 'cgv_tracker_device_unit',
      });

      const radio_options = available_billing_due_days.map((due_day) => {
        const due_day_with_pad_start = due_day.toString().padStart(2, "0");

        return (
          <div style={{ marginBottom: '14px' }}>
            <Radio
              type="radio"
              name="expirationDate"
              value={due_day}
              id={"expirationDate-" + due_day}
              invalid={!!errors.expirationDate}
              innerRef={register({ required: true })}
              label={"Dia " + due_day_with_pad_start}
            />
          </div>
        );
      });

      setRadioOptions(radio_options);
    })();
    // eslint-disable-next-line
  }, []);

  const submitForm = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <Step
      title="Data de Vencimento"
      subtitle="Qual o melhor dia de vencimento para a fatura mensal?"
    >
      <Form onKeyPress={e => e.key === 'Enter' && submitForm(e)}>
        <FormGroup tag="fieldset">
          {RadioOptions}
        </FormGroup>
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

ExpirationDate.propTypes = StepPropTypes;
