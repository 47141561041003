import React from 'react';
import Cleave from 'cleave.js/react';
import { Controller } from 'react-hook-form';

import { ControlledInputPropType } from '../propTypes';

import { Text } from 'components';

const maskFormats = {

  phone: {
    options: {
      phone: true,
      phoneRegionCode: 'br',
    },
  },
  cep: {
    options: {
      delimiter: '-',
      numericOnly: true,
      blocks: [5, 3],
      uppercase: true,
    },
  },
  cpf: {
    options: {
      delimiters: ['.', '.', '-'],
      numericOnly: true,
      blocks: [3, 3, 3, 2],
    },
  },
  cnpj: {
    options: {
      delimiters: ['.', '.', '/', '-'],
      numericOnly: true,
      blocks: [2, 3, 3, 4, 2],
    },
  },
  numeric: {
    options: {
      numericOnly: true,
    },
  },
  validThru: {
    options: {
      numericOnly: true,
      delimiter: '/',
      blocks: [2, 4]
    }
  },
};

export default function MaskedInput(props) {
  const {
    name,
    rules,
    format,
    defaultValue,
    options,
    control,
    errors = {},
    placeholder,
    valueWithMask,
    disabled,
    ...otherProps
  } = props;

  const formatOptions = {
    rules,
    options,
    ...(maskFormats[format] || {}),
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, ...controlledProps }) => (
        <div>
          <Cleave
            {...controlledProps}
            {...otherProps}
            {...formatOptions}
            disabled={disabled}
            placeholder={placeholder}
            className={`form-control ${errors[name] && 'is-invalid'}`}
            onChange={(e) => {
              e.persist();
              const value = valueWithMask ? e.target.value : e.target.rawValue;

              onChange(value);

              otherProps.onChange && otherProps.onChange(e);
            }}
          />
          <Text
            fontSize={12}
            color={'#E65845'}
            style={{ marginTop: '8px' }}
          >
            {errors[name]?.message}
          </Text>
        </div>
      )}
    />
  );
}

MaskedInput.propTypes = ControlledInputPropType;
