import React from 'react';

import { StylingPropType } from '../propTypes';

import { CustomRadio } from './style';

export default function Radio(props) {
  return (
    <CustomRadio
      type="radio"
      style={{ cursor: 'pointer' }}
      {...props}
    />
  );
}

Radio.propTypes = StylingPropType;
