import React from 'react';
import PropTypes from 'prop-types';

import {
  DefaultHeader, Divider, HeaderContent, Text,
} from './style';
import Logo from './logo';

export default function Header({ children, callToActionText }) {
  return (
    <DefaultHeader>
      <HeaderContent>
        <Logo />
        {callToActionText && <Text size="16px">{callToActionText}</Text>}
      </HeaderContent>

      <Divider
        width="4px"
        margin={!callToActionText && '40px 0'}
        color="#3B4182"
      />

      <HeaderContent>{children}</HeaderContent>
    </DefaultHeader>
  );
}

Header.defaultProps = {
  children: null,
  callToActionText: null,
};

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.node,
  callToActionText: PropTypes.string,
};
