import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { any, string } from 'prop-types';

import * as iconSet from '../iconSet';

import { StylableDiv } from './style';

export const Icon = memo(({ icon, divProps = {}, ...props }) => (
  <StylableDiv {...divProps}>
    <FontAwesomeIcon
      icon={iconSet[icon]}
      {...props}
    />
  </StylableDiv>
));

Icon.defaultProps = {
  divProps: null,
};

Icon.propTypes = {
  icon: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  divProps: any,
};
