import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import {
  ActionPortal,
  Checkbox,
  CheckboxWrapper,
  DefaultActionButtons,
  Paragraph,
  Step,
  LoadingSpin
} from 'components';

import { StepPropTypes } from '../../constants/propTypes';

import ContractPDF from '../../components/contractPDF';

export default function Contract(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { contract } = {},
  } = props;
  const { formState, register, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: contract,
  });

  const { basicInfo, solutionInfo } = useSelector((state) => state.subscription);

  const [contractPDF, setContractPDF] = useState('');
  const [loadingContract, setLoadingContract] = useState(true);
  const [failLoadingContract, setFailLoadingContract] = useState(false);

  const { isValid } = formState;

  useEffect(() => {
    const generateContract = async () => {
      try {
        const contractView = await ContractPDF({ basicInfo, solutionInfo });
        setContractPDF(contractView);
      }catch (err){
        setFailLoadingContract(true);
       console.error(err)
      }finally {
        setLoadingContract(false);
      }
    };
    
    generateContract();
    // eslint-disable-next-line
  }, [])

  return (
    <Step
      title="Contrato de locação"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      
      {!loadingContract && !failLoadingContract &&
        <div style={{width: '100%', height: '100%'}}>
          {contractPDF}
        </div>
      }

      {loadingContract &&
        <div style={{ width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingSpin
            show={loadingContract}
            weight="5px"
            size="80px"
            backColor="#1a2454"
            frontColor="transparent"
          />
        </div>
      }

      <ActionPortal vertical>
        <CheckboxWrapper>
          <Paragraph
            fontSize="14px"
            margin="0 0 12px 0"
            color={theme.colors.text.default}
          >
            Após a confirmação você receberá o Contrato no seu e-mail.
          </Paragraph>

          <Checkbox
            type="checkbox"
            id="optIn"
            name="optInContract"
            color={theme.colors.text.default}
            innerRef={register({
              required: true,
            })}
            fontSize="14px"
            label="Li e concordo com todos os termos e cláusulas mencionadas no Contrato de locação dispostos acima."
            inline
          />
        </CheckboxWrapper>

        <DefaultActionButtons
          theme={theme}
          isValid={isValid}
          backButtonStyle={{
            iconPosition: 'left',
            border: 'none',
            customBackgroundColor: 'transparent',
            customIconColor: theme.colors.text.label,
            customTextColor: theme.colors.text.label,
            title: 'Passo Anterior',
          }}
          nextButtonStyle={{
            customBackgroundColor: theme.colors.primary,
            customIconColor: theme.colors.text.white,
            customTextColor: theme.colors.text.white,
            title: 'Próximo Passo',
          }}
          onCancel={onCancel}
          onSubmit={() => handleSubmit(onSubmit)()}
        />
      </ActionPortal>
    </Step>
  );
}

Contract.propTypes = StepPropTypes;
