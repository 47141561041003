import React from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionPortal,
  DefaultActionButtons,
  Form,
  FormGroup,
  FormLabel,
  Input,
  Step,
} from 'components';

import { StepPropTypes } from '../../../constants/propTypes';

export default function Feedback(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { feedback } = {},
  } = props;

  const { formState, handleSubmit, register } = useForm({
    mode: 'all',
    defaultValues: feedback,
  });

  const { isValid } = formState;

  return (
    <Step title="Estamos tristes com a sua partida, e gostaríamos de ouvir o que tem a dizer sobre nossos serviços">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel for="feedback">Deixe uma mensagem para a gente: </FormLabel>
          <Input
            name="feedback"
            id="feedback"
            type="textarea"
            innerRef={register}
            placeholder="Digite aqui sua mensagem"
            style={{ height: '160px' }}
          />
        </FormGroup>
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

Feedback.propTypes = StepPropTypes;
