import React from 'react';

import { Font} from '@react-pdf/renderer';
import { parseDataToApi } from 'utils/parseDataToApi';

import { downloadRentalContract, generateSubscriptionPreview } from 'services/api';
import { mountCreateContractPayload } from 'utils/mountCreateContractPayload';



export default async function ContractPDF({ basicInfo = {}, solutionInfo = {} }) {
    const robotoBoldFontUrl = "https://fonts.gstatic.com/s/roboto/v15/bdHGHleUa-ndQCOrdpfxfw.ttf";

    Font.register({
        family: "Roboto Bold",
        src: robotoBoldFontUrl,
    });

    const { discountCoupon } = solutionInfo?.confirmation || {};


    const subscriptionData = parseDataToApi({
        ...basicInfo,
        ...solutionInfo,
        discountCoupon
    });

    const responseSubscriptionPreview = await generateSubscriptionPreview({ subscriptionData });

    const create_contract_payload = mountCreateContractPayload({...responseSubscriptionPreview});

    const response_blob = await downloadRentalContract({ create_contract_payload });
    
    const pdf_url = window.URL.createObjectURL(response_blob);

    const hasValidSubscriptionPreview = responseSubscriptionPreview?.billing_preview;

    if (!hasValidSubscriptionPreview) {
        throw new Error('Fail to generate preview response');
    };

    const subscriptionItems = responseSubscriptionPreview.billing_preview.subscription_items;

    const hasItemsInSubscription = Array.isArray(subscriptionItems) && subscriptionItems;

    if (!hasItemsInSubscription) {
        throw new Error('No items founded');
    };

    return (
    
        <iframe title={Math.random()} style={{ width: '100%', height: '100%' }} src={pdf_url}>

        </iframe>
    );
}
