import React from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionPortal,
  Contract,
  DefaultActionButtons,
  Checkbox,
  Step,
  Alert,
  Icon,
  Paragraph,
} from 'components';

import { StepPropTypes } from '../../constants/propTypes';

import { ConfirmationContent } from './style';

export default function Confirmation(props) {
  const {
    theme,
    onSubmit,
    onCancel,
    withDiscount,
    pricingFooterText = 'Valor dessa contratação:',
    withAlert,
    title = 'Confirmação de novos Veículos',
    subtitle = 'Confirme os dados da sua contratação de mais 20 veículos',
  } = props;
  const { formState, register, handleSubmit } = useForm({
    mode: 'all',
  });
  const { isValid } = formState;

  return (
    <Step
      title={title}
      subtitle={subtitle}
    >
      <ConfirmationContent>
        <Contract
          theme={theme}
          withDiscount={withDiscount}
          pricingFooterText={pricingFooterText}
          contract={{
            details: [
              {
                qty: 20,
                group: 'Rastreadores',
                unitValue: 'R$ 60,00',
                totalValue: 'R$ 1.580/Mês',
                subRows: [
                  {
                    qty: 10,
                    group: 'Veículos leves',
                    unitValue: '',
                    totalValue: '',
                  },
                  {
                    qty: 5,
                    group: 'Motos',
                    unitValue: '',
                    totalValue: '',
                  },
                  {
                    qty: 5,
                    group: 'Veículos pesados',
                    unitValue: '',
                    totalValue: '',
                  },
                  {
                    qty: 0,
                    group: 'Máquinas',
                    unitValue: '',
                    totalValue: '',
                  },
                ],
              },
              {
                qty: 4,
                group: 'Bloqueios',
                unitValue: 'R$ 4,00',
                totalValue: 'R$ 12/Mês',
              },
              {
                qty: 20,
                group: 'App Contele Driver',
                unitValue: 'R$ 5,00',
                totalValue: 'R$ 100/Mês',
              },
            ],
          }}
        />
      </ConfirmationContent>

      {withAlert && (
        <div style={{ marginTop: '40px' }}>
          <Alert
            warning
            theme={theme}
            style={{ flexDirection: 'column' }}
          >
            <div style={{ display: 'flex', marginBottom: '16px' }}>
              <Icon
                icon="exclamationTriangle"
                width="18px"
                height="18px"
                color={theme.colors.status.warning}
                style={{ marginRight: '8px' }}
              />
              <Paragraph display="inline-flex">
                O desconto de R$ 200,00 só será mantido nas faturas pagas até a data do vencimento.
              </Paragraph>
            </div>
            <Checkbox
              id="accept"
              label="Sim. Estou ciente."
              name="accept"
              innerRef={register({ required: true })}
            />
          </Alert>
        </div>
      )}
      <ActionPortal>
        <DefaultActionButtons
          theme={theme}
          isValid={isValid}
          backButtonStyle={{
            iconPosition: 'left',
            border: 'none',
            customBackgroundColor: 'transparent',
            customIconColor: theme.colors.text.label,
            customTextColor: theme.colors.text.label,
            title: 'Passo Anterior',
          }}
          nextButtonStyle={{
            customBackgroundColor: theme.colors.primary,
            customIconColor: theme.colors.text.white,
            customTextColor: theme.colors.text.white,
            title: 'Próximo Passo',
          }}
          onCancel={onCancel}
          onSubmit={() => handleSubmit(onSubmit)()}
        />
      </ActionPortal>
    </Step>
  );
}

Confirmation.propTypes = StepPropTypes;
