import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Col } from 'reactstrap';

import {
  Heading, Paragraph, SubHeading, TextBold,
} from 'components';
import CTAImage from 'assets/images/CTA.png';

import { CallToActionWrapper } from './style';

export default function CTA() {
  const theme = useContext(ThemeContext);
  return (
    <CallToActionWrapper>
      <Col>
        <Heading fontWeight="700">Contratação Online.</Heading>
        <SubHeading fontWeight="400">Rápida. Fácil. E livre de papéis.</SubHeading>
      </Col>
      <Col>
        <img
          style={{ marginTop: '2em', marginBottom: '2em' }}
          src={CTAImage}
          alt="Garantia Risco Zero"
        />
      </Col>
      <Col>
        <Paragraph
          lineHeight="22px"
          fontSize="16px"
        >
          Clique em
          {' '}
          <TextBold
            color={theme.colors.text.paragraph}
            display="inline"
          >
            “Começar agora”
          </TextBold>
          {' '}
          para gerar o seu Contrato.
        </Paragraph>
      </Col>
    </CallToActionWrapper>
  );
}
