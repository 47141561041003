export function mountCreateContractPayload({ contele_customer, contele_subscription}) {

      const billing_due_day_of_month = contele_subscription.billing_due_day_of_month;
      //company legal representative 
      const company_legal_representative = contele_customer.people.find(people => people.role === 'company_legal_representative')  || {}
      
      const company_legal_representative_document_number =  company_legal_representative.document_number;
      const company_legal_representative_name = company_legal_representative.name;
      //contract data
      const contract_sign_date = new Date().toISOString();
      const contract_version = 'v1';
      //customer billing address
      const customer_billing_address = contele_customer.addresses.find(address => address.type === 'billing') || {};
      const	customer_billing_address_additional_details  = '';
      const	customer_billing_address_city = customer_billing_address.city;
      const	customer_billing_address_neighborhood = customer_billing_address.neighborhood;
      const	customer_billing_address_phone_number = customer_billing_address.phone_number || '';
      const	customer_billing_address_state = customer_billing_address.state;
      const	customer_billing_address_street = customer_billing_address.street;
      const	customer_billing_address_number = customer_billing_address.number;
      const	customer_billing_address_zipcode = customer_billing_address.zipcode;
      
      
      //customer financial
      const company_financial = contele_customer.people.find(people => people.role === 'company_financial') || {};
      const customer_company_financial_email = company_financial.email || '';
      const	customer_company_financial_name = company_financial.name || '';
      const	customer_company_financial_phone_number = company_financial.phone_number || '';
      
      //customer delivery 
      const customer_delivery_address = contele_customer.addresses.find(address => address.type === 'other') || {};
      const customer_delivery_address_additional_details = "";
      const customer_delivery_address_city = customer_delivery_address.city;
      const customer_delivery_address_neighborhood = customer_delivery_address.neighborhood;
      const customer_delivery_address_phone_number = customer_delivery_address.phone_number || '';
      const customer_delivery_address_state = customer_delivery_address.state;
      const customer_delivery_address_street = customer_delivery_address.street;
      const customer_delivery_address_number = customer_delivery_address.number;
      const customer_delivery_address_zipcode = customer_delivery_address.zipcode;
      
      
      const customer_document_number = contele_customer.document_number;
      const customer_name =  contele_customer.name;
      
      
      const customer_system_admin = contele_customer.people.find(people => people.role === 'system_admin') || {}
      const customer_system_admin_email =  customer_system_admin.email || '';
      const customer_system_admin_name =  customer_system_admin.name || '';
      const customer_system_admin_phone_number =  customer_system_admin.phone_number || '';
      
      const round_to_precision = (input) =>
          Number(input.toFixed(5));

      
      contele_subscription.items = contele_subscription.items.map(
            (item) => {
              const total_price_without_discount_per_cycle =
                item.quantity * item.unit_price_per_cycle;
      
              const {
                discount_absolute_value_per_cycle:
                  _discount_absolute_value_per_cycle = 0,
                discount_percentual_value_per_cycle = 0,
              } = item;
      
              const percentual_value = discount_percentual_value_per_cycle / 100;
              const computed_percentual =
                total_price_without_discount_per_cycle * percentual_value;
      
              const total_discount_absolute_value_per_cycle =
                _discount_absolute_value_per_cycle || computed_percentual;
              const total_price_with_discount_per_cycle =
                total_price_without_discount_per_cycle -
                total_discount_absolute_value_per_cycle;
      
              return {
                ...item,
                total_discount_absolute_value_per_cycle: round_to_precision(
                  total_discount_absolute_value_per_cycle,
                ),
                total_price_with_discount_per_cycle: round_to_precision(
                  total_price_with_discount_per_cycle,
                ),
                total_price_without_discount_per_cycle: round_to_precision(
                  total_price_without_discount_per_cycle,
                ),
              };
            },
          );
      
      
      const cgv_tracker_device_plug_and_play = contele_subscription.items.find(item => item.contele_product_id.includes('cgv_tracker_device_plug_and_play_unit')) || {}
      const tracker_device = contele_subscription.items.find(item => item.contele_product_id.includes('cgv_tracker_device_unit')) || {}
      const tracker_feature_fuel_lock = contele_subscription.items.find(item => item.contele_product_id.includes('cgv_tracker_feature_fuel_lock_unit')) || {}
      
      
      
      const subscription_item_cgv_tracker_device_plug_and_play_quantity =  cgv_tracker_device_plug_and_play.quantity ||0;
      const subscription_item_cgv_tracker_device_plug_and_play_unit_absolute_value =  cgv_tracker_device_plug_and_play.unit_price_per_cycle || 0;
      const subscription_item_cgv_tracker_device_plug_and_play_total_absolute_value =  cgv_tracker_device_plug_and_play.total_price_without_discount_per_cycle || 0;
      
      const subscription_item_cgv_tracker_device_quantity =  tracker_device.quantity || 0;
      const subscription_item_cgv_tracker_device_unit_absolute_value =   tracker_device.unit_price_per_cycle ||0;
      const subscription_item_cgv_tracker_device_total_absolute_value =  tracker_device.total_price_without_discount_per_cycle || 0
      
      const subscription_item_cgv_tracker_feature_fuel_lock_quantity =  tracker_feature_fuel_lock.quantity || 0;
      const subscription_item_cgv_tracker_feature_fuel_lock_unit_absolute_value =  tracker_feature_fuel_lock.unit_price_per_cycle || 0;
      const subscription_item_cgv_tracker_feature_fuel_lock_total_absolute_value =  tracker_feature_fuel_lock.total_price_without_discount_per_cycle || 0;
      
      const subscription_total_price_without_discount_per_cycle =  subscription_item_cgv_tracker_feature_fuel_lock_total_absolute_value 
      + subscription_item_cgv_tracker_device_total_absolute_value + subscription_item_cgv_tracker_device_plug_and_play_total_absolute_value;
      
      const subscription_total_price_with_discount_per_cycle = contele_subscription.items.reduce((acc, currentItem) => {
      return acc + currentItem.total_price_with_discount_per_cycle
      }, 0)
      
      
      const subscription_discount_absolute_value_per_cycle = subscription_total_price_without_discount_per_cycle - subscription_total_price_with_discount_per_cycle  ;
      
      
        
      return  {
                billing_due_day_of_month,
          company_legal_representative_document_number,
          company_legal_representative_name,
          contract_sign_date,
          contract_version,
          customer_billing_address_additional_details,
          customer_billing_address_city,
          customer_billing_address_neighborhood,
          customer_billing_address_phone_number,
          customer_billing_address_state,
          customer_billing_address_street,
          customer_billing_address_number,
          customer_billing_address_zipcode,
          customer_company_financial_email,
          customer_company_financial_name,
          customer_company_financial_phone_number,
          customer_delivery_address_additional_details,
          customer_delivery_address_city,
          customer_delivery_address_neighborhood,
          customer_delivery_address_phone_number,
          customer_delivery_address_state,
          customer_delivery_address_street,
          customer_delivery_address_number,
          customer_delivery_address_zipcode,
          customer_document_number,
          customer_name,
          customer_system_admin_email,
          customer_system_admin_name,
          customer_system_admin_phone_number,
          subscription_discount_absolute_value_per_cycle: Number(subscription_discount_absolute_value_per_cycle),
          subscription_item_cgv_tracker_device_plug_and_play_quantity,
          subscription_item_cgv_tracker_device_plug_and_play_total_absolute_value: Number(subscription_item_cgv_tracker_device_plug_and_play_total_absolute_value),
          subscription_item_cgv_tracker_device_plug_and_play_unit_absolute_value: Number(subscription_item_cgv_tracker_device_plug_and_play_unit_absolute_value),
          subscription_item_cgv_tracker_device_quantity,
          subscription_item_cgv_tracker_device_total_absolute_value: Number(subscription_item_cgv_tracker_device_total_absolute_value),
          subscription_item_cgv_tracker_device_unit_absolute_value: Number(subscription_item_cgv_tracker_device_unit_absolute_value),
          subscription_item_cgv_tracker_feature_fuel_lock_quantity,
          subscription_item_cgv_tracker_feature_fuel_lock_total_absolute_value: Number(subscription_item_cgv_tracker_feature_fuel_lock_total_absolute_value),
          subscription_item_cgv_tracker_feature_fuel_lock_unit_absolute_value: Number(subscription_item_cgv_tracker_feature_fuel_lock_unit_absolute_value),
          subscription_total_price_with_discount_per_cycle: Number(subscription_total_price_with_discount_per_cycle),
          subscription_total_price_without_discount_per_cycle: Number(subscription_total_price_without_discount_per_cycle)
      }
      
};