import styled from 'styled-components';
import { CustomInput } from 'reactstrap';

export const CheckboxWrapper = styled.div((props) => ({
  padding: '16px 24px',
  backgroundColor: props.theme.colors.lighterAccent,
}));

export const CustomRadio = styled(CustomInput)((props) => ({
  paddingLeft: '2.5rem',
  fontSize: props.fontSize || '16px',
  color: props.color,

  '.custom-control-label::before': {
    height: '1.35rem',
    maxHeight: '18px',
    width: '1.35rem',
    maxWidth: '18px',
    top: '.15rem',
    left: '-2.5rem',
    border: '2px solid #868E96',
  },

  '.custom-control-input:focus~.custom-control-label::before': {
    boxShadow: 'none',
  },

  '.custom-control-label:after': {
    height: '1.3rem',
    width: '1.3rem',
    top: '.15rem',
    left: '-2.5rem',
  },

  '.custom-control-input:checked~.custom-control-label::before': {
    top: '.15rem',
    left: '-2.5rem',
    borderColor: props.theme.colors.primary,
    backgroundColor: props.theme.colors.input.radio.background,
  },
  '.custom-control-input:checked~.custom-control-label::after': {
    backgroundSize: '7px',
  },
}));

export const CustomCheckbox = styled(CustomInput)((props) => ({
  paddingLeft: '2.5rem',
  fontSize: props.fontSize || '16px',
  color: props.color,

  '.custom-control-label::before': {
    height: '1.35rem',
    width: '1.35rem',
    top: '.15rem',
    left: '-2.5rem',
    border: '2px solid #868E96',
    borderRadius: '4px',
  },

  '.custom-control-input:focus~.custom-control-label::before': {
    boxShadow: 'none',
  },

  '.custom-control-label:after': {
    height: '1.35rem',
    width: '1.35rem',
    top: '.15rem',
    left: '-2.5rem',
  },

  '.custom-control-input:checked~.custom-control-label::before': {
    top: '.15rem',
    left: '-2.5rem',
    borderColor: props.theme.colors.primary,
    backgroundColor: props.theme.colors.input.checkbox.background,
  },
}));

export const CustomSwitch = styled(CustomInput)((props) => ({
  paddingLeft: '3rem',
  fontSize: props.fontSize || '16px',
  color: props.color,

  '.custom-control-label::before': {
    height: '1.35rem',
    width: '2.5rem',
    top: '.15rem',
    left: '-3rem',
    border: '1px solid #1A237A',
    borderRadius: '0.7rem',
    backgroundColor: 'rgba(37, 49, 164, 0.5)',
  },

  '.custom-control-input:focus~.custom-control-label::before': {
    boxShadow: 'none',
  },

  '.custom-control-label:after': {
    height: '1.15rem',
    width: '1.15rem',
    top: 'calc(.1rem + 2px)',
    left: 'calc(-3rem + 2px)',
    borderRadius: '0.7rem',
    backgroundColor: 'white',
  },

  '.custom-control-input:checked~.custom-control-label::before': {
    borderColor: props.theme.colors.primary,
    backgroundColor: props.theme.colors.input.switch.background,
  },
  '.custom-control-input:checked~.custom-control-label::after': {
    transform: 'translateX(1.1rem)',
  },
}));
