import React, { useState } from 'react';
import { useLocation } from 'react-router';

import { useForm } from 'react-hook-form';

import { consultants } from 'constants/consultants';

import {
    ActionPortal,
    DefaultActionButtons,
    Step,
    Radio,
    Form,
    FormGroup,
    Input,
    Icon
} from 'components';

import { StepPropTypes } from '../../constants/propTypes';
export default function Consultant(props) {
    const {
        theme, onSubmit, onCancel, initialValues: { consultantId } = {}
    } = props;

    const { formState, register, handleSubmit } = useForm({
        mode: 'all',
    });

    const queryParams = useLocation().search;
    const defaultConsultant = new URLSearchParams(queryParams).get('consultant');

    const hasValidQueryConsultant = consultants.find(consultant => consultant.id === defaultConsultant);
    const defaultCheckedConsultantId = (hasValidQueryConsultant && defaultConsultant) || consultantId || 'default';

    const [searchText, setSearchText] = useState('');
    const [checkedConsultant, setCheckedConsultant] = useState(defaultCheckedConsultantId);

    const { isValid } = formState;

    const handleSearchText = (e) => {
        e.preventDefault();

        return setSearchText(e.target.value);
    };

    const handleCheckedConsultant = (e) => {
        return setCheckedConsultant(e.target.value);
    };

    const consultantsSearchFilter = consultants.filter(consultant => {
        const searchTerm = searchText.toLowerCase();
        const consultantNameToLower = consultant.name.toLowerCase();

        const hasConsultantInSearchTerm = consultantNameToLower.includes(searchTerm);

        return hasConsultantInSearchTerm || consultant.id === 'default';
    });

    const hasCheckedConsultantOnFilter = !!consultantsSearchFilter.find(consultant => consultant.id === checkedConsultant);

    return (
        <Step
            title="Consultor responsável"
            subtitle="Selecione o consultor que atendeu você até esse momento:"
        >
            <div style={{ display: 'flex', paddingBottom: '24px', maxWidth: '420px' }}>
                <Input
                    type="text"
                    placeholder="Busque pelo nome do consultor"
                    onChange={e => handleSearchText(e)}
                    style={{ borderRight: 'none', borderRadius: '4px 0 0 4px' }}
                />
                <div style={{ display: 'flex', alignItems: 'center', padding: '0 12px', borderRadius: '0 4px 4px 0', backgroundColor: '#F3F3F3', border: '1px solid #E5E5E5' }}>
                    <Icon
                        icon={"search"}
                        width="14px"
                        height="14px"
                        color={"#868E96"}
                    />
                </div>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup tag="fieldset">
                    {consultantsSearchFilter.map(consultant => {
                        const hasValidConsultantToCheck = hasCheckedConsultantOnFilter && consultant.id === checkedConsultant;

                        const checkDefaultOnNotFound = !hasCheckedConsultantOnFilter && consultant.id === 'default'

                        const checkedRadio = hasValidConsultantToCheck || checkDefaultOnNotFound;

                        return (
                            <div style={{ marginBottom: '14px' }}>
                                <Radio
                                    type="radio"
                                    name="consultantId"
                                    id={consultant.id}
                                    value={consultant.id}
                                    checked={checkedRadio}
                                    innerRef={register({ required: true })}
                                    label={consultant.name}
                                    style={{ marginBottom: '14px' }}
                                    onClick={handleCheckedConsultant}
                                />
                            </div>
                        )
                    })}
                </FormGroup>

                <ActionPortal vertical>
                    <DefaultActionButtons
                        theme={theme}
                        isValid={isValid}
                        backButtonStyle={{
                            iconPosition: 'left',
                            border: 'none',
                            customBackgroundColor: 'transparent',
                            customIconColor: theme.colors.text.label,
                            customTextColor: theme.colors.text.label,
                            title: 'Passo Anterior',
                        }}
                        nextButtonStyle={{
                            customBackgroundColor: theme.colors.primary,
                            customIconColor: theme.colors.text.white,
                            customTextColor: theme.colors.text.white,
                            title: 'Ir para Pagamentos',
                        }}
                        onCancel={onCancel}
                        onSubmit={() => handleSubmit(onSubmit)()}
                    />
                </ActionPortal>
            </Form>
        </Step >
    );
}

Consultant.propTypes = StepPropTypes;
