import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { get_token_from_local_storage } from 'utils/getTokenFromStorage';

import {
  ActionPortal,
  Col,
  DefaultActionButtons,
  Form,
  FormGroup,
  FormLabel,
  FormText,
  Input,
  MaskedInput,
  Row,
  Step,
  Switch,
  Text,
  ValidatedInput
} from 'components';

import { inputsValidations } from 'validations/inputsFieldsValidations';

import { StepPropTypes } from '../../constants/propTypes';

export default function ContactInfo(props) {
  const {
    theme, onSubmit, onCancel, initialValues: defaultValues,
  } = props;

  const {
    control, errors, formState, handleSubmit, register, watch, setValue, trigger
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  const fieldsValidations = inputsValidations.BR;

  const { isValid } = formState;
  const useManagerInfo = watch('useManagerInfo');

  const managerName = watch("managerName");
  const managerEmail = watch("managerEmail");
  const managerPhone = watch("managerPhone");

  const [managerInfoSwitch, setManagerInfoSwitch] = useState(useManagerInfo)

  const changeManagerInfoSwitch = () => {
    const newManagerInfoSwitchState = !managerInfoSwitch;
    setManagerInfoSwitch(newManagerInfoSwitchState);
  };

  const syncFinanceManagerInfoWithManager = () => {
    const copyManagerToFinanceManager = {
      financeManagerName: managerName,
      financeManagerEmail: managerEmail,
      financeManagerPhone: managerPhone,
    };

    Object.entries(copyManagerToFinanceManager).forEach(([key, value]) => setValue(key, value));
    trigger();
  };
  const getEmailFromLoggedUserInToken = () => {
    const {
      userInfo: { 
        email 
      }
    } = get_token_from_local_storage();

    return email;
  };

  useEffect(() => {
    if(managerInfoSwitch){
      syncFinanceManagerInfoWithManager();
    }
    // eslint-disable-next-line
  }, [managerName, managerPhone, managerEmail, managerInfoSwitch]);

  const submitForm = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <Step
      {...props}
      title="Informações para contato"
    >
      <Form onKeyPress={e => e.key === 'Enter' && submitForm(e)}>
        <Row form>
          <Col md="12">
            <Text
              fontSize="18px"
              fontWeight="400"
              fontHeight="20px"
              whiteSpace="normal"
              margin="0 0 1em 0"
            >
              Gestor do Sistema
            </Text>
          </Col>
        </Row>
        <Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="managerName">Nome*</FormLabel>
              <ValidatedInput
                type="text"
                name="managerName"
                errors={errors}
                placeholder="Nome do gestor"
                invalid={!!errors.managerName}
                innerRef={register({
                  required: fieldsValidations.required,
                  ...fieldsValidations.peopleName
                })}
              />
              <FormText color="muted">* Pessoa responsável pela utilização do sistema.</FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="managerEmail">E-mail</FormLabel>
              <ValidatedInput
                type="text"
                name="managerEmail"
                placeholder="Ex.: nomeresponsavel@email.com"
                invalid={!!errors.managerEmail}
                innerRef={register({
                  required: fieldsValidations.required,
                  ...fieldsValidations.email
                })}
                disabled={true}
                defaultValue={getEmailFromLoggedUserInToken()}
              />
              <Text
                fontSize={12}
                color={'#E65845'}
                style={{ marginTop: '8px' }}
              >
                {errors.managerEmail?.message}
              </Text>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="managerPhone">Telefone Celular</FormLabel>
              <MaskedInput
                control={control}
                name="managerPhone"
                rules={{
                  required: fieldsValidations.required,
                  ...fieldsValidations.phone
                }}
                maxLength={17}
                placeholder="Ex.: (11) 98123-5432"
                errors={errors}
                options={{
                  phone: true,
                  phoneRegionCode: 'br',
                  prefix: '+55'
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <hr />

        <Row form>
          <Col md="12">
            <Text
              fontSize="18px"
              fontWeight="400"
              fontHeight="20px"
              whiteSpace="normal"
              margin="0 0 1em 0"
            >
              Responsável pelo Financeiro
            </Text>
          </Col>
        </Row>
        <Row form>
          <Col md="12">
            <FormGroup>
              <Switch
                inline
                id="useManagerInfo"
                name="useManagerInfo"
                label="Usar os mesmos dados do Gestor"
                innerRef={register}
                checked={managerInfoSwitch}
                onClick={changeManagerInfoSwitch}
              />
            </FormGroup>
          </Col>
        </Row>
        <>
          <Row form>
            <Col md="12">
              <FormGroup>
                <FormLabel for="financeManagerName">Nome*</FormLabel>
                <ValidatedInput
                  type="text"
                  name="financeManagerName"
                  errors={errors}
                  placeholder="Nome do gestor"
                  invalid={!!errors.financeManagerName}
                  innerRef={register({
                    required: fieldsValidations.required,
                    ...fieldsValidations.peopleName
                  })}
                  disabled={managerInfoSwitch}
                />
                <FormText color="muted">
                  * Pessoa responsável pelo pagamento das faturas.
                </FormText>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md="12">
              <FormGroup>
                <FormLabel for="financeManagerEmail">E-mail</FormLabel>
                <Input
                  type="text"
                  name="financeManagerEmail"
                  id="financeManagerEmail"
                  placeholder="Ex.: nomeresponsavel@email.com"
                  invalid={!!errors.financeManagerEmail}
                  innerRef={register({
                    required: fieldsValidations.required,
                    ...fieldsValidations.email
                  })}
                  disabled={managerInfoSwitch}
                />
                <Text
                  fontSize={12}
                  color={'#E65845'}
                  style={{ marginTop: '8px' }}
                >
                  {errors.financeManagerEmail?.message}
                </Text>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md="12">
              <FormGroup>
                <FormLabel for="financeManagerPhone">Telefone Celular</FormLabel>
                <MaskedInput
                  control={control}
                  name="financeManagerPhone"
                  rules={{
                    required: fieldsValidations.required,
                    ...fieldsValidations.phone
                  }}
                  maxLength={17}
                  errors={errors}
                  placeholder="Ex.: (11) 98123-5432"
                  options={{
                    phone: true,
                    phoneRegionCode: 'br',
                    prefix: '+55'
                  }}
                  disabled={managerInfoSwitch}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

ContactInfo.propTypes = StepPropTypes;
