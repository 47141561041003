import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './modules/rootReducer';
// import { persistStore } from 'redux-persist';
// import persistReducers from './persistReducer';

const initialState = {};
const middlewares = [thunk];
// const persistedReducer = persistReducers(rootReducer);
const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middlewares),
    // eslint-disable-next-line no-underscore-dangle
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  ),
);

// const persistor = persistStore(store);

export { store };
// export { store, persistor };
