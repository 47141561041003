import React from 'react';

import { StylingPropType } from '../propTypes';

import { CustomSwitch } from './style';

export default function Switch(props) {
  return (
    <CustomSwitch
      type="switch"
      style={{ cursor: 'pointer' }}
      {...props}
    />
  );
}

Switch.propTypes = StylingPropType;
