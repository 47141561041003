/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  func, any, node, bool,
} from 'prop-types';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { StyledModal, StyledModalHeader } from './style';

export const Modal = ({
  header,
  footer,
  children,
  isOpen,
  setOpen,
  style,
  centered,
  headerProps = {},
}) => (
  <StyledModal
    isOpen={isOpen}
    onClose={() => setOpen(false)}
    style={style}
    centered={centered}
  >
    {header && <ModalHeader {...headerProps}>{header}</ModalHeader>}
    {!header && (
      <StyledModalHeader
        close={(
          <button
            type="button"
            className="close"
            onClick={() => setOpen(false)}
          >
            &times;
          </button>
        )}
      />
    )}
    <ModalBody>{children}</ModalBody>
    {footer && <ModalFooter>{footer}</ModalFooter>}
  </StyledModal>
);

Modal.defaultProps = {
  header: null,
  headerProps: null,
  footer: null,
  style: null,
  centered: null,
};

Modal.propTypes = {
  header: node,
  headerProps: any,
  footer: node,
  style: node,
  children: node.isRequired,
  centered: bool,
  isOpen: bool.isRequired,
  setOpen: func.isRequired,
};
