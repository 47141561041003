import React, { useMemo, useState } from 'react';
import {
  useExpanded, usePagination, useRowSelect, useTable,
} from 'react-table';

import {
  ButtonWithIcon, Container, IconButton, Paragraph, Text,
} from 'components';

import { ThemePropType } from 'assets/styles/theme';

import { invoiceHistory } from '../../../test/fixtures/invoices';

import ContractDetails from './contractDetails';
import {
  ActionCellEditor,
  InvoiceTable,
  TableChildRowCell,
  TableChildRowHeader,
  TableColumn,
  TableHeader,
  TableRow,
} from './style';

export default function History(props) {
  const { theme } = props;
  const [currentContract, setCurrentContract] = useState(null);

  const {
    headerGroups, rows, getTableProps, getTableBodyProps, prepareRow,
  } = useTable(
    {
      columns: useMemo(
        () => [
          {
            Header: 'E-mail de alteração',
            accessor: 'email',
          },
          {
            Header: 'IP',
            accessor: 'ip',
          },
          {
            Header: 'Alteração',
            accessor: 'changeContext',
          },
          {
            id: 'expander',
            accessor: 'id',
            // eslint-disable-next-line react/prop-types
            Cell: ({ row }) => (row.canExpand ? (
              <ActionCellEditor>
                <IconButton
                  icon="view"
                    // eslint-disable-next-line react/prop-types
                  onClick={() => setCurrentContract(row.values)}
                  customBackgroundColor="transparent"
                  customIconColor={theme.colors.primary}
                  border="none"
                  style={{ outline: 'none' }}
                />
                {' '}
                <IconButton
                    // eslint-disable-next-line react/prop-types
                  {...row.getToggleRowExpandedProps()}
                    // eslint-disable-next-line react/prop-types
                  icon={row.isExpanded ? 'chevronUp' : 'chevronDown'}
                  customBackgroundColor="transparent"
                  customIconColor={theme.colors.text.default}
                  border="none"
                  style={{ outline: 'none' }}
                />
              </ActionCellEditor>
            ) : null),
          },
        ],
        [theme.colors.primary, theme.colors.text.default],
      ),
      data: useMemo(() => invoiceHistory, []),
    },
    useExpanded,
    usePagination,
    useRowSelect,
  );

  return (
    <Container
      fluid
      style={{ padding: '40px' }}
    >
      {currentContract && (
        <>
          <ButtonWithIcon
            border="none"
            customBackgroundColor="transparent"
            customTextColor={theme.colors.primary}
            customIconColor={theme.colors.primary}
            iconPosition="left"
            icon="arrowLeft"
            onClick={() => setCurrentContract(null)}
            width="auto"
            padding="0 10px"
            marginLeft="-8px"
            title="Voltar para Lista"
            style={{ outline: 'none' }}
            textOptions={{ fontSize: '14px' }}
          />
          <Text
            fontSize="22px"
            fontWeight="400"
            fontHeight="24px"
            whiteSpace="normal"
            margin="16px 0 24px 0"
            theme={theme}
          >
            20 de Agosto de 2020 - Redução
          </Text>
          <ContractDetails
            theme={theme}
            contractStyle={{ padding: '40px', backgroundColor: '#F5F5F5' }}
          />
        </>
      )}
      {!currentContract && (
        <>
          <Text
            fontSize="22px"
            fontWeight="400"
            fontHeight="24px"
            whiteSpace="normal"
            margin="0 0 40px 0"
            theme={theme}
          >
            Seu Histórico de Alterações
          </Text>

          <InvoiceTable
            responsive
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <TableRow
                  {...headerGroup.getHeaderGroupProps()}
                  style={{ fontSize: '16px', fontWeight: 'bold', border: 'none' }}
                >
                  {headerGroup.headers.map((column) => (
                    <TableHeader {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </TableHeader>
                  ))}
                </TableRow>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows
                .filter(({ depth }) => depth === 0)
                .map((row) => {
                  prepareRow(row);
                  return (
                    <>
                      <TableRow
                        {...row.getRowProps()}
                        style={{ fontSize: '14px', borderTop: '1px solid #E5E5E5' }}
                      >
                        {row.cells.map((cell) => (
                          <TableColumn {...cell.getCellProps()}>{cell.render('Cell')}</TableColumn>
                        ))}
                      </TableRow>
                      {row.isExpanded ? (
                        <>
                          <TableRow childRow>
                            <TableChildRowHeader>Qtd.</TableChildRowHeader>
                            <TableChildRowHeader colSpan="2">Descrição</TableChildRowHeader>
                            <TableChildRowHeader>Valor</TableChildRowHeader>
                          </TableRow>
                          {row.subRows.map((childRow) => {
                            const { qty, description, value } = childRow.original;
                            return (
                              <TableRow childRow>
                                <TableChildRowCell theme={theme}>{qty}</TableChildRowCell>
                                <TableChildRowCell
                                  colSpan="2"
                                  theme={theme}
                                >
                                  {description}
                                </TableChildRowCell>
                                <TableChildRowCell theme={theme}>{value}</TableChildRowCell>
                              </TableRow>
                            );
                          })}
                          <TableRow childRow>
                            <TableChildRowCell theme={theme} />
                            <TableChildRowCell
                              style={{ fontWeight: 'bold' }}
                              theme={theme}
                            >
                              {' '}
                              Valor da fatura
                            </TableChildRowCell>
                            <TableChildRowCell theme={theme} />
                            <TableChildRowCell
                              style={{ fontWeight: 'bold' }}
                              theme={theme}
                            >
                              R$ 1.520,00
                            </TableChildRowCell>
                          </TableRow>
                        </>
                      ) : null}
                    </>
                  );
                })}
            </tbody>
          </InvoiceTable>
          {!invoiceHistory?.length && (
            <Paragraph
              fontSize="24px"
              textAlign="center"
              margin="20% auto"
              color={theme.colors.text.lighter}
            >
              Não foi feita nenhuma alteração em seu contrato
            </Paragraph>
          )}
        </>
      )}
    </Container>
  );
}

History.propTypes = {
  theme: ThemePropType.isRequired,
};
