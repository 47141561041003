import React from 'react';
import PropTypes from 'prop-types';

import {
  StepIcon, StepNumber, StepText, Item as StyledItem,
} from './style';

export { StepNumber } from './style';

export default function StepHeaderItem(props) {
  const { title, step, completed } = props;

  return (
    <StyledItem {...props}>
      <StepNumber {...props}>
        {completed ? (
          <StepIcon
            icon="check"
            width="12px"
            height="12px"
            color="white"
            className="icon"
          />
        ) : (
          step + 1
        )}
      </StepNumber>

      <StepText {...props}>{title}</StepText>
    </StyledItem>
  );
}

StepHeaderItem.defaultProps = {
  completed: false,
};

StepHeaderItem.propTypes = {
  completed: PropTypes.bool,
  title: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
};
