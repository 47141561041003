import React from 'react';
import { withRouter } from 'react-router-dom';

import { ThemePropType } from 'assets/styles/theme';

import ActiveSubscription from './active';
// import CancelledSubscription from './cancelled';

import { DashboardContent } from './style';

function Dashboard(props) {
  const { theme } = props;

  return (
    <DashboardContent>
      <ActiveSubscription theme={theme} />
      {/* <CancelledSubscription theme={theme} /> */}
    </DashboardContent>
  );
}

export default withRouter(Dashboard);

Dashboard.propTypes = {
  theme: ThemePropType.isRequired,
};
