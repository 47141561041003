import styled from 'styled-components';

export const DefaultFooter = styled.footer((props) => ({
  display: props.vertical ? 'block' : 'flex',
  justifyContent: props.spaceBetween ? 'space-between' : 'flex-end',
  backgroundColor: 'white',
  width: '100%',
  border: `1px solid ${props.theme.colors.border}`,
  boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.06)',
  padding: props.noGutters ? 0 : props.padding,
  alignItems: 'center',
  height: props.vertical ? 'unset' : '84px',
  flexShrink: 0,
  position: 'relative',

  [`@media${props.theme.devices.laptop}`]: {
    position: 'fixed',
    bottom: 0,
    zIndex: 1
  },
}));
