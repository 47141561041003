import styled from 'styled-components';

export const DefaultContent = styled.main((props) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  overflowY: 'auto',
  flexDirection: 'column',

  [`@media${props.theme.devices.laptop}`]: {
    height: '80%',
    position: 'fixed',
    top: 120,
  },
}));
