import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';

import { text } from './theme';

export const palette = {
  background: '#FFFFFF',
  primary: '#1A237A',
  secondary: '#6A6A84',
  accent: '#6E73CA',
  lighterAccent: '#EFF1FF',
  border: '#E5E5E5',
  status: {
    success: '#41BA40',
    error: '#E65845',
    warning: '#FFB904',
  },
  text: {
    default: '#222222',
    label: '#666666',
    paragraph: '#666666',
    lighter: '#999999',
    white: '#ffffff',
  },
};

const generalStyle = css`
  /* PLACEHOLDER ANIMATION */
  @keyframes placeHolderShimmer {
    0% {
      background-position: 0px 0;
    }
    100% {
      background-position: 100em 0;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }

  * {
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body,
  #root {
    min-height: 100vh;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 22px;
  }

  svg {
    overflow: inherit;
  }

  h1 {
    font-size: ${text.sizes.h1};
  }

  h2 {
    font-size: ${text.sizes.h2};
  }

  h3 {
    font-size: ${text.sizes.h3};
  }

  h4 {
    font-size: ${text.sizes.h4};
  }

  h5 {
    font-size: ${text.sizes.h5};
  }

  h6 {
    font-size: ${text.sizes.h6};
  }

  form {
    padding-bottom: 2em;
  }
`;

const silverChalice = '#ababab60';
const resetWebkitScrollBarStyle = css`
  /* SCROLLBAR */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${silverChalice};
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset}
  ${resetWebkitScrollBarStyle}
  ${generalStyle}
`;
