/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { func, object } from 'prop-types';

import states from 'assets/states.json';
import cities from 'assets/cities.json';

import {
  Col, FormGroup, FormLabel, ValidatedInput, MaskedInput, Row, Select,
} from 'components';

import { getAddressByCep } from '../../services/cep';

import { inputsValidations } from 'validations/inputsFieldsValidations';

export default function AddressForm({
  control, register, errors, setValue, trigger, disableFields
}) {
  const [address, setAddress] = useState({
    state: {},
  });

  const fieldsValidations = inputsValidations.BR;

  const findAddressByCep = async (cep) => {
    try {
      const { data } = await getAddressByCep(cep);
      const selectedState = states.filter((state) => state.code === data.uf)[0];
      const selectedCity = cities[selectedState.code].filter((city) => city === data.localidade)[0];
      setAddress({
        state: {
          label: selectedState.name,
          value: selectedState.code,
        },
      });
      setValue('streetAddress', data.logradouro);
      setValue('state', {
        label: selectedState.name,
        value: selectedState.code,
      });
      setValue('city', {
        label: selectedCity,
        value: selectedCity,
      });
      setValue('neighborhood', data.bairro);
      trigger();
    } catch (error) {}
  };
  const canFindAddressByCep = ({ target: { value } }) => {
    const normalizedValue = value.replace(/_|-/g, '');
    if (normalizedValue.length === 8) {
      findAddressByCep(normalizedValue);
    }
  };

  const findCityByState = (stateSelected) => {
    setAddress({
      ...address,
      state: stateSelected || {},
    });
  };
  const loadStates = () => states.map((state) => ({
    label: state.name,
    value: state.code,
  }));
  const loadCities = () => (cities[address.state.value] || []).map((city) => ({
    label: city,
    value: city,
  }));

  return (
    <div>
      <Row form>
        <Col md="4">
          <FormGroup>
            <FormLabel for="zipCode">Cep</FormLabel>
            <MaskedInput
              control={control}
              name="zipCode"
              type="text"
              tabIndex="0"
              defaultValue=""
              format="cep"
              placeholder="Ex.: 12345-123"
              errors={errors}
              rules={{
                required: fieldsValidations.required,
                ...fieldsValidations.cep
              }}
              onChange={canFindAddressByCep}
              disabled={disableFields}
            />
          </FormGroup>
        </Col>
        <Col md="8">
          <FormGroup>
            <FormLabel for="streetAddress">Endereço</FormLabel>
            <ValidatedInput
              type="text"
              name="streetAddress"
              id="streetAddress"
              errors={errors}
              placeholder="Ex.: Avenida Costa e Silva"
              invalid={!!errors.streetAddress}
              maxLength={256}
              innerRef={register({
                required: fieldsValidations.required,
                ...fieldsValidations.communText
              })}
              disabled={disableFields}
            />
          </FormGroup>
        </Col>
      </Row>

      
      <Row form>
        <Col md="4">
          <FormGroup>
            <FormLabel for="number">Número</FormLabel>
            <ValidatedInput
              type="text"
              name="number"
              id="number"
              errors={errors}
              invalid={!!errors.number}
              placeholder="Ex.: 592"
              maxLength={256}
              innerRef={register({
                required: fieldsValidations.required
              })}
              disabled={disableFields}
            />
          </FormGroup>
        </Col>
        <Col md="8">
          <FormGroup>
            <FormLabel for="streetAddress2">Complemento</FormLabel>
            <ValidatedInput
              type="text"
              name="streetAddress2"
              id="streetAddress2"
              errors={errors}
              placeholder="Ex.: Sala 249"
              maxLength={256}
              innerRef={register({
                ...fieldsValidations.communText
              })}
              disabled={disableFields}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md="4">
          <FormGroup>
            <FormLabel for="province">Estado</FormLabel>
            <Select
              control={control}
              name="state"
              isClearable
              blurInputOnSelect
              errors={errors}
              placeholder="Selecione o estado"
              onChange={findCityByState}
              options={loadStates()}
              maxLength={256}
              rules={{
                required: fieldsValidations.required,
                ...fieldsValidations.communText
              }}
              disabled={disableFields}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <FormLabel for="city">Cidade</FormLabel>
            <Select
              control={control}
              name="city"
              blurInputOnSelect
              errors={errors}
              placeholder="Selecione a cidade"
              options={loadCities()}
              maxLength={256}
              rules={{
                required: fieldsValidations.required,
                ...fieldsValidations.communText
              }}
              disabled={disableFields}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <FormLabel for="neighborhood">Bairro</FormLabel>
            <ValidatedInput
              type="text"
              name="neighborhood"
              id="neighborhood"
              errors={errors}
              placeholder="Informe o bairro"
              invalid={!!errors.neighborhood}
              maxLength={256}
              innerRef={register({
                required: fieldsValidations.required,
                ...fieldsValidations.communText
              })}
              disabled={disableFields}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md="6">
          <FormGroup>
            <FormLabel for="landline">Telefone fixo</FormLabel>
            <MaskedInput
              control={control}
              name="landline"
              placeholder="Ex.: (11) 3224-3212"
              errors={errors}
              maxLength={16}
              rules={{
                required: fieldsValidations.required,
                ...fieldsValidations.phone
              }}
              options={{
                phone: true,
                phoneRegionCode: 'br',
                prefix: '+55'
              }}
              disabled={disableFields}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <FormLabel for="mobile">Telefone celular</FormLabel>
            <MaskedInput
              control={control}
              name="mobile"
              placeholder="Ex.: (11) 99999-9999"
              errors={errors}
              maxLength={17}
              rules={{
                required: fieldsValidations.required,
                ...fieldsValidations.phone
              }}
              options={{
                phone: true,
                phoneRegionCode: 'br',
                prefix: '+55'
              }}
              disabled={disableFields}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

AddressForm.propTypes = {
  control: object.isRequired,
  register: func.isRequired,
  errors: object.isRequired,
};
