export const invoiceHistory = [
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
    subRows: [
      {
        qty: -5,
        description: 'Veículos',
        value: 'R$ 58,00',
      },
      {
        qty: -5,
        description: 'Veículos',
        value: 'R$ 58,00',
      },
    ],
  },
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
  },
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
  },
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
  },
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
  },
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
  },
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
  },
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
  },
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
  },
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
  },
  {
    email: 'test@test.com',
    ip: '127.0.0.1',
    changeContext: 'Adição de Veículos',
    id: '1234',
  },
];

export const invoices = [
  {
    expirationDate: '20 de Agosto de 2020',
    status: 'Atrasado 5 dias',
    value: 'R$ 1.692,00',
    id: '1234',
    overdue: true,
  },
  {
    expirationDate: '20 de Agosto de 2020',
    status: 'Pendente',
    value: 'R$ 1.692,00',
    id: '1234',
    pending: true,
  },
  {
    expirationDate: '20 de Agosto de 2020',
    status: 'Aguardando Confirmação',
    value: 'R$ 1.692,00',
    id: '1234',
    awaitingConfirmation: true,
  },
  {
    expirationDate: '20 de Agosto de 2020',
    status: 'Pago em atraso',
    value: 'R$ 1.692,00',
    id: '1234',
    paidLate: true,
  },
  {
    expirationDate: '20 de Agosto de 2020',
    status: 'Pago em dia',
    value: 'R$ 1.692,00',
    id: '1234',
  },
  {
    expirationDate: '20 de Agosto de 2020',
    status: 'Pago em dia',
    value: 'R$ 1.692,00',
    id: '1234',
  },
  {
    expirationDate: '20 de Agosto de 2020',
    status: 'Pago em dia',
    value: 'R$ 1.692,00',
    id: '1234',
  },
];
