import React, { useState } from 'react';
import { string, node } from 'prop-types';

import PackageCrumbledPaper from 'assets/images/package-crumbled-paper.png';
import PackageBubbleWrap from 'assets/images/package-bubble-wrap.png';
import {
  ActionPortal,
  Alert,
  Button,
  ButtonsHolder,
  ButtonWithIcon,
  Icon,
  Modal,
  Paragraph,
  Step,
  StepNumber,
  Text,
  TextBold,
} from 'components';

import { ThemePropType } from 'assets/styles/theme';

import { StepPropTypes } from '../../../../constants/propTypes';

import { ChecklistItem } from './style';

const CancellationChecklistItem = ({ theme, number, children }) => (
  <ChecklistItem
    style={{ alignItems: 'flex-start' }}
    theme={theme}
  >
    <StepNumber
      style={{ flexShrink: 0 }}
      theme={theme}
      active
    >
      {number}
    </StepNumber>
    <Text
      theme={theme}
      fontSize="14px"
    >
      {children}
    </Text>
  </ChecklistItem>
);

export default function Finish(props) {
  const { theme, onSubmit, onCancel } = props;
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);

  return (
    <Step title="Sua solicitação de redução foi recebida">
      <TextBold margin="40px 0 24px">Quase lá, siga os próximos passos:</TextBold>
      <ol display={{ display: 'flex' }}>
        <CancellationChecklistItem
          theme={theme}
          number="1"
        >
          Encontre uma caixa proporcional a quantidade de rastreadores a serem enviados;
        </CancellationChecklistItem>

        <CancellationChecklistItem
          theme={theme}
          number="2"
        >
          Embale tudo com plástico bolha ou com papéis, para garantir que os rastreadores cheguem
          intactos, evitando multas conforme item 2.2. do contrato.
          {' '}
          <TextBold
            display="inline"
            onClick={() => setShowMoreInfoModal(true)}
            style={{ cursor: 'pointer' }}
          >
            Saiba mais.
          </TextBold>
        </CancellationChecklistItem>
      </ol>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextBold margin="10px auto">Com plástico bolha</TextBold>
        <img
          src={PackageBubbleWrap}
          alt="Embalagem com plástico bolha"
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextBold margin="16px auto 10px">Com papéis amassados</TextBold>
        <img
          src={PackageCrumbledPaper}
          alt="Embalagem com plástico bolha"
        />
      </div>

      <Alert
        warning
        theme={theme}
      >
        <Icon
          icon="exclamationTriangle"
          width="18px"
          height="18px"
          color={theme.colors.status.warning}
          style={{ marginRight: '8px' }}
        />
        <Paragraph>
          Envie em até
          {' '}
          <TextBold display="inline">15 dias corridos</TextBold>
          , para retirarmos a
          cobrança desses rastreadores da sua fatura e do sistema.
        </Paragraph>
      </Alert>

      <Modal
        centered
        isOpen={showMoreInfoModal}
        setOpen={setShowMoreInfoModal}
        style={{ maxWidth: '550px' }}
        header={<TextBold fontSize="18px">Item 2.2 do Contrato</TextBold>}
        headerProps={{
          close: (
            <button
              type="button"
              className="close"
              style={{ opacity: 1, outline: 'none', color: theme.colors.status.danger }}
              onClick={() => setShowMoreInfoModal(false)}
            >
              &times;
            </button>
          ),
        }}
      >
        <Paragraph
          fontSize="14px"
          margin="0 auto 16px"
          color={theme.colors.text.paragraph}
        >
          *2.2. Na existência de quaisquer danos materiais, defeito no equipamento locado decorrente
          de mau uso ou má conservação ou não devolução de algum item do Kit locado pela CONTRATADA,
          a CONTRATANTE pagará a quantia de, R$ 400,00 (quatrocentos reais) do MÓDULO, R$ 70,00
          (setenta reais) da ANTENA GPS, R$ 30,00 (trinta reais) da ANTENA GSM, R$ 20,00 (vinte
          reais) da CHAVE IBUTTON e R$ 140,00 (cento e quarenta reais) do LEITOR IBUTTON .
        </Paragraph>
      </Modal>

      <ActionPortal>
        <ButtonsHolder justifyContent="space-between">
          <ButtonWithIcon
            icon="arrowLeft"
            iconPosition="left"
            border="none"
            customBackgroundColor="transparent"
            customIconColor={theme.colors.text.label}
            customTextColor={theme.colors.text.label}
            title="Passo Anterior"
            onClick={() => onCancel()}
          />
          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
            }}
          >
            <ButtonWithIcon
              icon="print"
              iconPosition="left"
              border={`1px solid ${theme.colors.primary}`}
              customBackgroundColor="transparent"
              customIconColor={theme.colors.primary}
              customTextColor={theme.colors.primary}
              title="Imprimir Orientação"
              onClick={() => window.print()}
              style={{ marginRight: '24px' }}
            />
            <Button
              textConfig={{
                color: 'white',
                fontSize: '13px',
                textAlign: 'center',
              }}
              backgroundColor={theme.colors.primary}
              width="auto"
              padding="10px"
              title="Concluir Redução"
              onClick={() => onSubmit()}
            />
          </div>
        </ButtonsHolder>
      </ActionPortal>
    </Step>
  );
}

Finish.propTypes = StepPropTypes;
CancellationChecklistItem.propTypes = {
  theme: ThemePropType.isRequired,
  number: string.isRequired,
  children: node.isRequired,
};
