import { any, func } from 'prop-types';

import { ThemePropType } from 'assets/styles/theme';

export const StepPropTypes = {
  theme: ThemePropType,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  initialValue: any,
};

export const FormPropTypes = {
  theme: ThemePropType,
  onSubmit: func.isRequired,
  initialValues: any,
};
