import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { changeStep } from 'store/modules';
import { withWizard } from 'containers';
import { StepContentWrapper } from 'components';

import Finish from './finish';

export default function InvoiceWizard({
  navigateBack: parentNavigateBack,
  navigateForward: parentNavigateForward,
  currentStep: parentCurrentStep,
  ...props
}) {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { invoiceInfo } = useSelector((state) => state.subscription);
  const { contract } = invoiceInfo || {};

  const onCancel = () => {
    parentNavigateBack();
  };

  const onCompleted = (values) => {
    parentNavigateForward(values);
  };

  const onValuesChanged = ({ values, currentStep }) => {
    if (values) {
      const { childStep, formValues } = values;
      dispatch(
        changeStep({
          step: 'reductionInfo',
          currentStep,
          parentCurrentStep,
          [childStep]: formValues,
        }),
      );
    }
  };

  function onSubmit({ history }) {
    return () => history.push('/member');
  }

  const steps = [
    {
      content: withRouter((wizardProps) => (
        <Finish
          {...wizardProps}
          initialValues={contract}
          onCancel={() => onCancel(wizardProps)}
          onSubmit={onSubmit({ ...wizardProps, childStep: 'finish' })}
        />
      )),
    },
  ];

  return withWizard({
    steps,
    theme,
    onValuesChanged,
    onCompleted,
    ...props,
  })(({ children }) => <StepContentWrapper>{children}</StepContentWrapper>);
}
