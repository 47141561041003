import { opacify } from 'polished';

import { theme } from 'assets/styles/theme';

const bsBorderGrey = theme.colors.input.select.border;
const bsBorderRed = theme.colors.status.danger;
const bsBoxShadowRed = `0 0 0 0.2rem ${opacify(0.25, theme.colors.status.danger)}`;
const bsBoxShadowBlue = `0 0 0 0.2rem ${opacify(0.25, theme.colors.input.default.boxShadowColor)}`;
const bsBackgroundOption = theme.colors.input.select.option.default.background;
const bsBackgroundOptionSelected = theme.colors.input.select.option.selected.background;
const bsControlTextColor = theme.colors.input.select.controlTextBackground;
const bsControlPlaceholderColor = theme.colors.text.lighter;

function getBoxShadowStyle(menuIsOpen, hasErrors) {
  if (menuIsOpen && hasErrors) return bsBoxShadowRed;
  if (menuIsOpen) return bsBoxShadowBlue;
  return 'none';
}

function getOptionBackgroundColor(isSelected, isFocused) {
  if (isSelected && isFocused) return bsBackgroundOption;
  if (isSelected) return bsBackgroundOptionSelected;
  return 'white';
}

export const getSelectStyle = (hasErrors) => ({
  control: (base, state) => ({
    ...base,
    backgroundColor: 'white',
    color: bsControlTextColor,
    flexWrap: 'nowrap',
    minHeight: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: hasErrors ? bsBorderRed : bsBorderGrey,
    borderTopLeftRadius: '.25rem',
    borderBottomLeftRadius: '.25rem',
    boxShadow: getBoxShadowStyle(state.selectProps.menuIsOpen, hasErrors),
    borderRadius: '.25rem',
    maxHeight: 'calc(1.5em + .75rem + 2px)',
    ':hover': {
      borderColor: hasErrors ? bsBorderRed : bsBorderGrey,
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '.4rem',
    backgroundClip: 'padding-box',
    border: 'none',
    maxHeight: 'calc(1.5em + .75rem + 2px)',
    overflow: 'hidden',
  }),
  input: (base) => ({
    ...base,
    color: bsControlTextColor,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
  }),
  singleValue: (base) => ({
    ...base,
    color: bsControlTextColor,
  }),
  placeholder: (_, state) => ({
    display: state.selectProps.menuIsOpen ? 'none' : 'inline-flex',
    color: bsControlPlaceholderColor,
    fontSize: '1rem',
    fontWeight: '400',
    alignSelf: 'baseline',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: bsControlTextColor,
    ':hover': {
      color: bsControlTextColor,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '8px',
    cursor: 'pointer',
    color: bsControlTextColor,
    ':hover': {
      color: bsControlTextColor,
    },
  }),
  option: (base, state) => ({
    ...base,
    color: bsControlTextColor,
    backgroundColor: getOptionBackgroundColor(state.isSelected, state.isFocused),
  }),
});
