import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import React, { useContext, useState } from 'react';

import { changeStep } from 'store/modules';
import { StepContentWrapper } from 'components';
import { withWizard } from 'containers';

import App from './app';
import Reasons from './reasons';
import ReplacingSystem from './replacingSystem';
import System from './system';
import Features from './features';
import Feedback from './feedback';
import WouldHireAgain from './wouldHireAgain';
import Confirmation from './confirmation';
import Finish from './finish';
import Completed from './completed';

export default function CancellationWizard() {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [completed, setCompleted] = useState(false);
  const { cancellation } = useSelector((state) => state.subscription);
  const {
    app,
    replacingSystem,
    reasons,
    system,
    confirmation,
    feedback,
    wouldHireAgain,
    features,
    finish,
  } = cancellation || {};

  const navigateBackOnCancel = ({ navigateBack }) => () => {
    navigateBack();
  };

  const onCompleted = (values) => {
    // eslint-disable-next-line no-console
    console.log('completed', values);
    setCompleted(true);
  };

  const onValuesChanged = ({ values, currentStep }) => {
    if (values) {
      const { childStep, formValues } = values;
      dispatch(
        changeStep({
          step: 'cancellation',
          currentStep,
          parentCurrentStep: 0,
          [childStep]: formValues,
        }),
      );
    }
  };

  function onSubmit({ navigateForward, childStep }) {
    return (values) => navigateForward({ childStep, formValues: values });
  }

  function onComplete({ navigateForward, childStep }) {
    return (values) => {
      navigateForward({ childStep, formValues: values });
      setCompleted(true);
    };
  }

  const steps = [
    {
      content: (props) => (
        <App
          {...props}
          initialValues={app}
          onSubmit={onSubmit({ ...props, childStep: 'app' })}
        />
      ),
    },
    {
      content: (props) => (
        <Reasons
          {...props}
          initialValues={reasons}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'reasons' })}
        />
      ),
    },
    {
      content: (props) => (
        <ReplacingSystem
          {...props}
          initialValues={replacingSystem}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'replacingSystem' })}
        />
      ),
    },
    {
      content: (props) => (
        <System
          {...props}
          initialValues={system}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'system' })}
        />
      ),
    },
    {
      content: (props) => (
        <Features
          {...props}
          initialValues={features}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'features' })}
        />
      ),
    },
    {
      content: (props) => (
        <WouldHireAgain
          {...props}
          initialValues={wouldHireAgain}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'wouldHireAgain' })}
        />
      ),
    },
    {
      content: (props) => (
        <Feedback
          {...props}
          initialValues={feedback}
          onCancel={navigateBackOnCancel(props)}
          onSubmit={onSubmit({ ...props, childStep: 'feedback' })}
        />
      ),
    },
    {
      content: (props) => (
        <Confirmation
          {...props}
          initialValues={confirmation}
          onSubmit={onSubmit({ ...props, childStep: 'confirmation' })}
        />
      ),
    },
    {
      content: (props) => (
        <Finish
          {...props}
          initialValues={finish}
          onSubmit={onComplete({ ...props, childStep: 'finish' })}
        />
      ),
    },
  ];

  return withWizard({
    steps,
    theme,
    onValuesChanged,
    onCompleted,
  })(({ children }) => (
    <StepContentWrapper>
      {completed && <Completed theme={theme} />}
      {!completed && children}
    </StepContentWrapper>
  ));
}
