import { useForm } from 'react-hook-form';
import React from 'react';
import {
  ActionPortal,
  Col,
  DefaultActionButtons,
  Form,
  FormGroup,
  FormLabel,
  Input,
  Row,
  Step,
  MaskedInput,
  Text
} from 'components';

import { inputsValidations } from 'validations/inputsFieldsValidations';

import { StepPropTypes } from '../../constants/propTypes';

export default function Responsible(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { responsible, gettingStarted },
  } = props;

  const {
    control, errors, formState, handleSubmit, register, watch,
  } = useForm({
    mode: 'all',
    defaultValues: responsible,
  });

  const fieldsValidations = inputsValidations.BR;

  const { isValid } = formState;

  const cnpjLength = 14;
  const cpfLength = 11;

  const submitForm = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <Step
      {...props}
      title="Quem vai assinar"
    >
      <Form onKeyPress={e => e.key === 'Enter' && submitForm(e)}>

        {gettingStarted && gettingStarted.document.trim().length === cnpjLength && (<Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="company_name">Razão social</FormLabel>
              <Input
                type="text"
                name="company_name"
                id="company_name"
                placeholder="Razão social da empresa contratante"
                invalid={!!errors.company_name}
                maxLength={256}
                innerRef={register({
                  required: fieldsValidations.required,
                  ...fieldsValidations.communText
                })}
              />
              <Text
                fontSize={12}
                color={'#E65845'}
                style={{ marginTop: '8px' }}
              >
                {errors.company_name?.message}
              </Text>
            </FormGroup>
          </Col>
        </Row>)}
        {gettingStarted && gettingStarted.document.trim().length === cnpjLength && (<Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="name">CNPJ da contratante</FormLabel>
              <MaskedInput
                control={control}
                name="companyDocument"
                defaultValue={gettingStarted.document}
                format="cnpj"
                errors={errors}
                placeholder="Ex.: 99.521.351/0001-05"
                rules={{
                  required: fieldsValidations.required,
                  validate: () => fieldsValidations.companyDocument(watch('companyDocument'))
                }}
              />
            </FormGroup>
          </Col>
        </Row>)}
        <Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="responsible">Responsável legal</FormLabel>
              <Input
                type="text"
                name="responsible"
                id="responsible"
                placeholder="Nome de quem vai assinar o contrato"
                invalid={!!errors.responsible}
                innerRef={register({
                  required: fieldsValidations.required,
                  ...fieldsValidations.peopleName
                })}
              />
              <Text
                fontSize={12}
                color={'#E65845'}
                style={{ marginTop: '8px' }}
              >
                {errors.responsible?.message}
              </Text>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="name">CPF do responsável legal</FormLabel>
              <MaskedInput
                control={control}
                name="responsibleDocument"
                defaultValue={gettingStarted && gettingStarted.document.trim().length === cpfLength ? gettingStarted.document : ''}
                format="cpf"
                errors={errors}
                placeholder="Ex.: 468.123.152-23"
                rules={{
                  required: fieldsValidations.required,
                  validate: () => fieldsValidations.peopleDocument(watch('responsibleDocument'))
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </Form>
    </Step>
  );
}

Responsible.propTypes = StepPropTypes;
