import React from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionPortal,
  Checkbox,
  DefaultActionButtons,
  Divider,
  FormGroup,
  FormLabel,
  Link,
  MaskedInput,
  Paragraph,
  Step,
  Switch,
  Text,
  TextBold,
} from 'components';

import { StepPropTypes } from '../../constants/propTypes';

export default function Blocker(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { blocker, vehicleManager, plugAndPlay } = {},
  } = props;
  const {
    control, errors, formState, handleSubmit, register, watch,
  } = useForm({
    mode: 'all',
    defaultValues: blocker,
  });

  const { isValid } = formState;
  const useBlocker = watch('useBlocker');

  const submitForm = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <Step
      title="Bloqueio"
      subtitle="Algum veículo precisa ter bloqueio de combustível?"
    >
    {vehicleManager && plugAndPlay && vehicleManager.lightweightVehiclesCount - (plugAndPlay.vehicleCount || 0) + vehicleManager.heavyVehiclesCount + vehicleManager.machineCount < 1 &&(<Paragraph
      fontSize="12px"
      lineHeight="20px"
      color={'#E65845'}
    >
      Os veículos ou modelo de rastreador selecionado não suportam Bloqueio de Combustível. 
      &nbsp;
      <a href="https://help.contelerastreador.com.br/pt-br/article/bloqueio-do-veiculo-1yqw1sd/" target='_blank' rel="noopener noreferrer">
        Saiba mais.
      </a>
    </Paragraph>)}
      <form onKeyPress={e => e.key === 'Enter' && submitForm(e)}>
        <div style={{ margin: '24px 0', display: 'flex' }}>
          <Switch
            inline
            id="useBlocker"
            name="useBlocker"
            label="Quero bloqueio"
            innerRef={register()}
            disabled={vehicleManager && plugAndPlay && (vehicleManager.lightweightVehiclesCount - (plugAndPlay.vehicleCount || 0) + vehicleManager.heavyVehiclesCount + vehicleManager.machineCount < 1)}
          />
          <Paragraph
            fontSize="12px"
            lineHeight="20px"
            color={'#666666'}
          >
            (Acréscimo de R$3/mês por veículo)
          </Paragraph>
        </div>
        {useBlocker && (
          <>
            <FormGroup>
              <FormLabel style={{marginBottom: 0}} for="vehicleCount">
                Quantos dos seus <TextBold display={'inline'}> {vehicleManager.lightweightVehiclesCount - (plugAndPlay.vehicleCount || 0) + vehicleManager.heavyVehiclesCount + vehicleManager.machineCount} </TextBold> Veículos* precisarão de bloqueio?
              </FormLabel>
              <Paragraph
                fontSize="12px"
                lineHeight="20px"
                color={'#666666'}
                style={{marginBottom: 16}}
              >
                *Não comercializamos bloqueios para Motos. 
              </Paragraph>
              <MaskedInput
                control={control}
                name="vehicleCount"
                defaultValue=""
                format="numeric"
                errors={errors}
                placeholder="Digite a quantidade de veículos"
                maxLength="3"
                rules={{
                  validate: {
                    maxNumber: (value) => value > 0 && value <= vehicleManager.lightweightVehiclesCount - (plugAndPlay.vehicleCount || 0) + vehicleManager.heavyVehiclesCount + vehicleManager.machineCount,
                  },
                }}
              />
            </FormGroup>
            <Divider margin="24px 0" />
            <Text
              fontSize="18px"
              fontWeight="700"
              lineHeight="26px"
              margin="0 0 14px 0"
            >
              Alerta Importante: Saiba os Riscos do Bloqueio Veicular
            </Text>
            <Paragraph
              fontSize="14px"
              lineHeight="22px"
              margin="0 0 24px 0"
            >
              Optar pelo bloqueio veicular vem com riscos que é crucial você entender: <br />

              A instalação requer cortes nos fios, o que provavelmente causará a perda da garantia do veículo.<br />

              O bloqueio ou desbloqueio indevidos do veículo são de sua total responsabilidade e podem causar danos ao Cliente ou a terceiros.<br />

              Reforçamos que prejuízos à sua empresa podem ocorrer se essas questões não forem bem geridas. <br />
              
              A Contele só recomenda esta opção para quem estiver plenamente ciente e aceitar esses riscos.<br />

              <Text
                fontSize="14px"
                fontWeight="700"
                lineHeight="26px"
                margin="0 0 14px 0"
              >
                Não assumimos responsabilidade por consequências adversas
              </Text>
            </Paragraph>

            <Checkbox
              type="checkbox"
              id="optInBlocker"
              name="optInBlocker"
              label="Sim. Estou ciente."
              inline
              innerRef={register({required: true})}
              rules={{required: true}}
            />
            <Text
              fontSize="18px"
              fontWeight="700"
              lineHeight="26px"
              margin="24px 0 14px 0"
            >
              Suporte Técnico
            </Text>
            <Paragraph
              fontSize="14px"
              lineHeight="22px"
              margin="0 0 24px 0"
            >
              Nosso horário de atendimento é de seg. a sex. das 08:30 às 18h. Fora desse horário, você
              poderá utilizar a nossa Central de Ajuda Online 24h, que pode ser acessada pelo
              aplicativo e pelo sistema:
              {' '}
              <Link href="https://help.contelerastreador.com.br/">help.contelerastreador.com.br</Link>
            </Paragraph>

            <Checkbox
              type="checkbox"
              id="optInSupport"
              name="optInSupport"
              label="Sim. Estou ciente."
              inline
              innerRef={register({required: true})}
              rules={{required: true}}
            />
          </>
        )}
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </form>
    </Step>
  );
}

Blocker.propTypes = StepPropTypes;
