import React, { createElement, useEffect, useState } from 'react';

export const withWizard = ({
  initialValues = null,
  disabled = false,
  completed = false,
  initialStep = 0,
  steps = [],
  onStepChanged = () => {},
  onValuesChanged = () => {},
  onCompleted = () => {},
  ...props
}) => (WrapperComponent) => {
  const [state, setState] = useState({
    currentStep: initialStep,
    values: initialValues,
    isDisabled: disabled,
    isCompleted: completed,
  });

  const {
    isCompleted, isDisabled, currentStep, values,
  } = state;

  useEffect(() => {
    onStepChanged({ currentStep });
  }, [currentStep]);

  useEffect(() => {
    onValuesChanged(state);
  }, [state]);

  const navigateForward = async (stepValues = {}) => {
    if (currentStep === steps.length - 1) {
      if (!isCompleted) {
        setState((prevState) => ({
          ...prevState,
          values: {
            ...stepValues,
          },
          isCompleted: true,
        }));
      }
      return onCompleted(values);
    }
    setState((prevState) => ({
      ...prevState,
      currentStep: currentStep + 1,
      values: {
        ...values,
        ...stepValues,
      },
    }));
  };

  const navigateBack = () => {
    if (currentStep === 0) return;

    setState((prevState) => ({
      ...prevState,
      currentStep: currentStep - 1,
    }));
  };

  const goToStep = (stepIndex) => {
    if (stepIndex < 0 || stepIndex > steps.length - 1) return;
    setState((prevState) => ({
      ...prevState,
      currentStep: stepIndex,
    }));
  };

  const enable = (enabled) => {
    setState((prevState) => ({
      ...prevState,
      isDisabled: !enabled,
    }));
  };

  const reset = () => {
    setState((prevState) => ({
      ...prevState,
      values: initialValues,
    }));
  };

  const step = steps[currentStep || 0];
  const { content, ...stepProps } = step;
  return (
    <>
      {createElement(
        WrapperComponent,
        {
          currentStep,
          values,
          disabled: isDisabled,
          completed: isCompleted,
          navigateForward,
          navigateBack,
          reset,
          goToStep,
          enable,
          ...props,
          ...stepProps,
        },
        content
          && createElement(content, {
            currentStep,
            values,
            disabled: isDisabled,
            completed: isCompleted,
            navigateForward,
            navigateBack,
            reset,
            goToStep,
            enable,
            ...props,
            ...stepProps,
          }),
      )}
      <div id="actions-portal" />
    </>
  );
};
