import React from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormLabel,
  Input,
  MaskedInput,
  Row,
} from 'components';

import { FormPropTypes } from '../../constants/propTypes';

export default function ReferralForm({ theme, onSubmit }) {
  const {
    control, register, errors, formState, handleSubmit,
  } = useForm({
    mode: 'all',
  });

  const { isValid } = formState;

  return (
    <Form>
      <Container>
        <Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="referralCompany">
                Empresa indicada
                <span style={{ marginLeft: '3px', color: 'red' }}>*</span>
              </FormLabel>
              <Input
                type="text"
                name="referralCompany"
                id="referralCompany"
                invalid={!!errors.referralCompany}
                innerRef={register({ required: true })}
                placeholder="Digite o nome da empresa"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="referralName">
                Nome do indicado
                <span style={{ marginLeft: '3px', color: 'red' }}>*</span>
              </FormLabel>
              <Input
                type="text"
                name="referralName"
                id="referralName"
                invalid={!!errors.referralName}
                innerRef={register({ required: true })}
                placeholder="Digite o nome do indicado"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="referralPhone">
                Empresa indicada
                <span style={{ marginLeft: '3px', color: 'red' }}>*</span>
              </FormLabel>
              <MaskedInput
                control={control}
                name="referralPhone"
                defaultValue=""
                rules={{
                  required: true,
                }}
                format="phone"
                errors={errors}
                placeholder="Ex.: (11) 98123-5432"
                options={{
                  phone: true,
                  phoneRegionCode: 'br',
                  prefix: '+55'
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="12">
            <FormGroup>
              <FormLabel for="referralEmail">
                E-mail do indicado
                <span style={{ marginLeft: '3px', color: 'red' }}>*</span>
              </FormLabel>
              <Input
                type="text"
                name="referralEmail"
                id="referralEmail"
                invalid={!!errors.referralEmail}
                innerRef={register({ required: true })}
                placeholder="Digite o nome da empresa"
              />
            </FormGroup>
          </Col>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="button"
            customBackgroundColor={theme.colors.primary}
            customTextColor={theme.colors.text.white}
            title="Indicar agora"
            disabled={!isValid}
            onClick={(e) => {
              e.stopPropagation();
              handleSubmit(onSubmit)();
            }}
            style={{ alignSelf: 'flex-end' }}
          />
        </div>
      </Container>
    </Form>
  );
}

ReferralForm.propTypes = FormPropTypes;
